import { FlyoutContent, FlyoutPosition, WithFlyout } from "@amzn/stencil-react-components/flyout";
import { Button, ButtonVariant, ButtonSize } from "@amzn/stencil-react-components/button";
import React from "react";
import { IconMenu } from '@amzn/stencil-react-components/icons';
import { Col, Hr } from '@amzn/stencil-react-components/layout';
import { StencilProvider } from "@amzn/stencil-react-components/context";
import defaultTheme from "@amzn/stencil-react-theme-default";
import { PageRouterLink } from "./PageRouterLink";
import {parentVendorFlag} from "../../Utils/Util";

const buttonStyle = {
    borderRadius: 0,
    margin: 0,
    border: 0,
    paddingLeft: 0,
    align: "left",
    fontWeight: 400,
    color: "#232F3E"
};

const textStyle = {
    font: "Amazon Ember",
    fontWeight: 700,
    fontSize: "1rem",
    textDecoration: "none",
    color: "#232F3E",
    lineHeight: "1.5rem"
};

export default function HeaderFlyout(props) {

    const flyoutContent = (flyoutprops, close) => {

        return (
            <StencilProvider
                theme={{
                    ...defaultTheme,
                    color: {
                        ...defaultTheme.color,
                        primary70: '#232F3E'
                    }
                }}>
                <FlyoutContent
                    maxWidth={'100vw'}
                    style={{ height: '100% !important', padding: "0" }}
                    titleText={`Hi ${flyoutprops?.userdetails?.id ? flyoutprops.userdetails.name : ''}`}
                    onCloseButtonClick={close.close}>
                    <Col justifyContent="space-between" width="94vw">
                        <Col lignItems="flex-start" justifyContent="flex-start" width={"100%"} gridGap="S400" margin={{ top: "0.5rem", bottom: "2rem" }}>
                            {parentVendorFlag() &&
                                <PageRouterLink style={textStyle} key="vendors" to="/vendors"
                                                onClick={() => {close.close()}}>Vendors</PageRouterLink>}
                            <PageRouterLink style={textStyle} key="vendorKams"
                                            to="/vendorKams" onClick={() => { close.close() }}>Kams</PageRouterLink>
                            <PageRouterLink style={textStyle} key="requests"
                                to="/requests" onClick={() => { close.close() }}>Requests</PageRouterLink>
                        </Col>
                        <Col gridGap="S200" alignItems="flex-start" style={{ padding: 0, margin: 0 }}>
                            <Hr color="neutral40" style={{ marginLeft: "-1rem", width: "106%", marginBottom: "1rem" }} />
                            <Button size={ButtonSize.Default} variant={ButtonVariant.Tertiary} style={buttonStyle} onClick={props.logout}>Log out</Button>
                        </Col>
                    </Col>
                </FlyoutContent>
            </StencilProvider>
        );
    }

    return (
        <WithFlyout renderFlyout={(close) => flyoutContent(props, close)} flyoutPosition={FlyoutPosition.Leading} style={{ display: "flex", flex: 1 }}>
            {
                ({ open }) => (<>
                    <Button variant={ButtonVariant.Tertiary} dataTestId="sideMenuButton" onClick={open} icon={<IconMenu color="white" />} />
                </>
                )}
        </WithFlyout>
    );
}