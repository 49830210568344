export function validatePattern(pattern, value) {
    return pattern.test(value);
}

export function validatePatterns(patterns,value){
    let isValid = true;
    if(patterns && patterns.length) {
        isValid = value;
        patterns.forEach(pattern => {
            isValid = isValid && validatePattern(new RegExp(pattern),value);
        });
    }
    return isValid;
}