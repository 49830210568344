import { orgAxios } from '../api/axios'


export const OrganizationServices = {
    getTotalOrganizations,
    getTotalKamRequests,
    getOrganizationById,
    getKamRequestById,
    addOrganization,
    editOrganization,
    getSiteByVendor,
    removeOrganization,
    getRequisitionsByVendor,
    updateKamRequestById,
    getAllAuditLogs,
    getKamsByParentVendor,
    getConfigForVendor
}

function getTotalOrganizations(url, body) {
    return orgAxios.post('/orgs/filter', body)
}

function getOrganizationById(organizationId, UserId) {
    return orgAxios.get(`/orgs/${organizationId}`)
}

function addOrganization(body = {}) {
    return orgAxios.post('/orgs', body)
}

function editOrganization(body = {}) {
    return orgAxios.put(`/orgs/`, body);
}

function removeOrganization(body = {}, UserId) {
    return orgAxios.put(`/orgs/delete`, body);
}

function getSiteByVendor(id) {
    return orgAxios.get('/orgs/sitesbyvendor/' + id)
}

function getRequisitionsByVendor(body) {
    return orgAxios.post('orgs/requisitionsByVendor', body)
}

// function myOrganizations(userId) {
//     return Axios.delete(`/orgs/${organizationId}`)
// }

function getTotalKamRequests(body){
    return orgAxios.post('/orgs/getAllKamRequests',body);
}

async function updateKamRequestById(body) {
    return await orgAxios.put('/orgs/editVendorKamRequestById', body);
}

function getKamRequestById(body) {
    return orgAxios.post(`/orgs/getKamRequest`,body);
}

function getAllAuditLogs(body){
    return orgAxios.post('/orgs/getAuditLogs',body);
}

function getKamsByParentVendor(body){
    return orgAxios.post('/orgs/getKamsByParentVendor',body);
}

function getConfigForVendor(){
    return orgAxios.post('/orgs/getConfigforCreateVendor',{});
}