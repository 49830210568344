import React from "react";
import { useNavigate, useParams } from "react-router";
import constants, {COUNTRY} from "../../../Utils/Constants";
import { useEffect } from "react";
import { OrganizationServices } from "../../../Services/organizations";
import { withAlertSnackBar } from "../../../HOComponents/AlertSnackBarHOC";
import AuditLogCards from "../../common/AuditTrail/AuditLogCards";
import { Col, Container, Flex, Hr, Row, View } from "@amzn/stencil-react-components/layout";
import { Label, Text } from "@amzn/stencil-react-components/text";
import { IconArrowLeft, IconCheck, IconCross } from "@amzn/stencil-react-components/icons";
import { Link, LinkIconPosition } from "@amzn/stencil-react-components/link";
import { useBreakpoints } from '@amzn/stencil-react-components/responsive';
import { ModalContent, WithModal } from "@amzn/stencil-react-components/modal";
import { Button, ButtonSize, ButtonVariant } from "@amzn/stencil-react-components/button";
import { InputFooter, Select, TextArea } from "@amzn/stencil-react-components/form";
import { Card } from "@amzn/stencil-react-components/card";
import { TabSwitcher, useTabs, TabBar, TabPanel } from "@amzn/stencil-react-components/tabs";
import {withCountryCode} from "../../../Utils/Util";

const propsPaddingMap = {
    s: { padding: { top: "2rem", left: "1rem", right: "1rem", bottom: "2rem" } },
    m: { padding: { top: "2rem", left: "1.5rem", right: "1.5rem", bottom: "2rem" } },
    l: { padding: { top: "2rem", left: "3rem", right: "3rem", bottom: "2rem" } },
    xl: { padding: { top: "2rem", left: "4rem", right: "4rem", bottom: "2rem" } }
}

export function AddressTextField(props){
    return (<>
            <Text fontSize="T200" fontWeight="bold" wordBreak="break-word" color="neutral90">{props.text}</Text>
            <Text color="neutral30" fontWeight="bold">&#124;</Text>
        </>);
}

export function DefaultKamRequestAddressCard(props){
    return (<>
        <AddressTextField text = {'State: '+props.formData.OrganizationState} />
        <AddressTextField text = {'District: '+props.formData.OrganizationDistrict} />
        <AddressTextField text = {'City: '+props.formData.OrganizationCity} />
        <AddressTextField text = {'Street: '+props.formData.OrganizationStreet} />
        <AddressTextField text = {'PostalCode: '+props.formData.OrganizationPostalcode} />
        <AddressTextField text = {'Phone: '+withCountryCode(props.formData.OrganizationCountry,props.formData.OrganizationPhone)} />
    </>);
}

export function SAKamRequestAddressCard(props){
    return (<>
        <AddressTextField text = {'Province/Governorate: '+props.formData.OrganizationState} />
        <AddressTextField text = {'Area/District: '+props.formData.OrganizationDistrict} />
        <AddressTextField text = {'City: '+props.formData.OrganizationCity} />
        <AddressTextField text = {'Address: '+props.formData.OrganizationStreet} />
        <AddressTextField text = {'PostalCode: '+props.formData.OrganizationPostalcode} />
        <AddressTextField text = {'Phone: '+withCountryCode(props.formData.OrganizationCountry,props.formData.OrganizationPhone)} />
    </>);
}

export function AEKamRequestAddressCard(props){
    return (<>
        <AddressTextField text = {'Area/District: '+props.formData.OrganizationDistrict} />
        <AddressTextField text = {'City: '+props.formData.OrganizationCity} />
        <AddressTextField text = {'Address: '+props.formData.OrganizationStreet} />
        <AddressTextField text = {'Phone: '+withCountryCode(props.formData.OrganizationCountry,props.formData.OrganizationPhone)} />
    </>);
}

export function KamRequestAddressCard(props){
    if(props.formData.OrganizationCountry === COUNTRY.Saudi_Arabia || props.formData.OrganizationCountry === COUNTRY.Egypt)
        return <SAKamRequestAddressCard {...props} />
    else if(props.formData.OrganizationCountry === COUNTRY.United_Arab_Emirates)
        return <AEKamRequestAddressCard {...props} />
    return <DefaultKamRequestAddressCard {...props} />
}

function ViewVendorKamRequest(props) {

    const navigate = useNavigate();
    const [formData, setFormData] = React.useState({});
    const comments = React.useRef([]);
    const params = useParams();

    const { props: paddingProps, matches } = useBreakpoints({ propsMap: propsPaddingMap });
    const [reasons, setReasons] = React.useState(constants.ARRAYS.REASON);
    const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
    const tabs = constants.TABS;
    const { tabBarProps, tabSwitcherProps } = useTabs({ tabs });

    useEffect(() => {
        if (params.kamRequestId) {
            prepopulate();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params]);

    const prepopulate = async () => {
        let body = {
            vendorKamId: params.vendorKamId,
            kamRequestId: params.kamRequestId
        };
        await OrganizationServices.getKamRequestById(body)
            .then((res) => {
                let data = (res && res.data) || {};
                let formDataL = { ...formData };
                formDataL.OrganizationKamRequestId = params.kamRequestId;
                formDataL.OrganizationVendorId = data.vendorId ? data.vendorId : "";
                formDataL.OrganizationVendorKamId = data.vendorKamId ? data.vendorKamId : "";
                formDataL.OrganizationVendorKamName = data.vendorKamName ? data.vendorKamName : "";
                formDataL.OrganizationVendorName = data.vendorName ? data.vendorName : "";
                formDataL.OrganizationStreet = data.street ? data.street : "";
                formDataL.OrganizationCity = data.city ? data.city : "";
                formDataL.OrganizationState = data.state ? data.state : "";
                formDataL.OrganizationDistrict = data.district ? data.district : "";
                formDataL.OrganizationCountry = data.country ? data.country : "";
                formDataL.OrganizationPostalcode = data.postalCode ? data.postalCode : "";
                formDataL.OrganizationPhone = data.phone ? data.phone : "";
                formDataL.OrganizationUsername = data.username ? data.username : "";
                formDataL.OrganizationCorrespondenceEmail = data.correspondenceEmail ? data.correspondenceEmail : "";
                formDataL.OrganizationType = data.type ? data.type : "";
                formDataL.OrganizationRequester = data.requester ? data.requester : "";
                formDataL.OrganizationRequestType = data.requestType ? data.requestType : "";
                formDataL.OrganizationRequestStatus = data.requestStatus ? data.requestStatus : "";
                formDataL.OrganizationRequestedDate = data.requestedDate ? data.requestedDate : "";
                formDataL.OrganizationSitesList = data.sites ? data.sites : null;
                formDataL.OrganizationSites = data.sites && data.sites.length > 0 ? data.sites.map((o) => o.split(",")).join(", ") : "-";
                comments.current = (data.comments ? data.comments : []);
                formDataL.OrganizationComments = data.comments;
                setFormData(formDataL);
            })
            .catch((error) => { });
    };

    const requestBody = {
        vendorId: formData.OrganizationVendorId,
        kamRequestId: params.kamRequestId,
        vendorKamId: formData.OrganizationVendorKamId,
        vendorKamName: formData.OrganizationVendorKamName,
        vendorName: formData.OrganizationVendorName,
        street: formData.OrganizationStreet,
        city: formData.OrganizationCity,
        state: formData.OrganizationState,
        district: formData.OrganizationDistrict,
        country: formData.OrganizationCountry,
        postalCode: formData.OrganizationPostalcode,
        phone: formData.OrganizationPhone,
        username: formData.OrganizationUsername,
        correspondenceEmail: formData.OrganizationCorrespondenceEmail,
        type: formData.OrganizationType,
        requester: formData.OrganizationRequester,
        requestType: formData.OrganizationRequestType,
        requestStatus: formData.OrganizationRequestStatus,
        requestedDate: formData.OrganizationRequestedDate,
        comments: comments.current,
        sites: formData.OrganizationSitesList

    };
    const updateKamRequest = async () => {
        let body = {
            ...requestBody,
            requestStatus: constants.STATUS[2]
        };
        return await OrganizationServices.updateKamRequestById(body)
    }

    const approveKamRequest = async (modelProps) => {
        let createKamBody = {
            vendorId: formData.OrganizationVendorId,
            vendorKamName: formData.OrganizationVendorKamName,
            vendorName: formData.OrganizationVendorName,
            street: formData.OrganizationStreet,
            city: formData.OrganizationCity,
            state: formData.OrganizationState,
            district: formData.OrganizationDistrict,
            country: formData.OrganizationCountry,
            postalCode: formData.OrganizationPostalcode,
            phone: formData.OrganizationPhone,
            username: formData.OrganizationUsername,
            correspondenceEmail: formData.OrganizationCorrespondenceEmail,
            type: formData.OrganizationType,
            sites: formData.OrganizationSitesList,
            contractLocation: "NA",
            contractValidity: "2022-12-12",
            clrcCapacity: 0,
            clrcLocation: "NA",
            clrcValidity: "2022-12-12",
        }
        await OrganizationServices.addOrganization(createKamBody)
            .then((createKamResponse) => {
                props.snackbarShowMessage(`${constants.CRUD_SUCCESS_ALERTS.CREATED}`);
                updateKamRequest().then(response => {
                    window.setTimeout(() => {
                        navigate(`/${constants.ORGANIZATIONS.ROUTE.VIEW}/${createKamResponse.data.vendorKamId}`);
                    }, 1000);
                });
            })
            .catch((response, error) => {
                props.snackbarShowMessage(
                    `${constants.ERROR.CREATE_ERROR} ${response.data.message}`,
                    "error",
                    "5000",
                    response.data.message
                );
            });
    };

    const sentenceCase = (str = "") => {
        str = str.toLowerCase();
        let converted = str.charAt(0).toUpperCase() + str.slice(1);
        return converted;
    }

    const kamDeclineReasonChangeHandler = (value) => {
        setIsFormSubmitted(false);
        let reasonL = [...reasons];
        reasonL.map((reason) => {
            reason.is_selected = value.name && reason.name === value.name;
            return reason;
        });
        setReasons(reasonL);
    };

    const validateForm = () => {
        return reasons.find((reason) => reason.is_selected) &&
            (!(reasons.find((reason) => reason.is_selected).name === "Other") || !(formData.comment === "" || formData.comment === undefined))
    }

    const updateComments = () => {
        let commentsL = comments.current;
        var today = new Date(),
            date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        let selectedReason = reasons.find((reason) => reason.is_selected);
        let reason = selectedReason.name === "Other" ? formData.comment.target.value : selectedReason.name;
        commentsL.push({
            timeStamp: 'WFS#' + date,
            comment: reason
        });
        comments.current = commentsL;
    };

    const handleApply = async (modelProps) => {
        setIsFormSubmitted(true);
        if (validateForm()) {
            updateComments();
            let body = {
                vendorId: formData.OrganizationVendorId,
                kamRequestId: formData.OrganizationKamRequestId,
                vendorKamId: formData.OrganizationVendorKamId,
                vendorKamName: formData.OrganizationVendorKamName,
                vendorName: formData.OrganizationVendorName,
                street: formData.OrganizationStreet,
                city: formData.OrganizationCity,
                state: formData.OrganizationState,
                district: formData.OrganizationDistrict,
                country: formData.OrganizationCountry,
                postalCode: formData.OrganizationPostalcode,
                phone: formData.OrganizationPhone,
                username: formData.OrganizationUsername,
                correspondenceEmail: formData.OrganizationCorrespondenceEmail,
                type: formData.OrganizationType,
                requester: formData.OrganizationRequester,
                requestType: formData.OrganizationRequestType,
                requestedDate: formData.OrganizationRequestedDate,
                requestStatus: constants.STATUS[1],
                comments: comments.current,
                sites: formData.OrganizationSitesList
            }
            await OrganizationServices.updateKamRequestById(body).then(res=>{
                if (res.data.status === "Success") {
                    modelProps.snackbarShowMessage(`${constants.CRUD_SUCCESS_ALERTS.DECLINE}`);
                    setIsFormSubmitted(false);
                    reasons.forEach((reason) => reason.is_selected = false);
                    modelProps.close();
                    window.setTimeout(() => {
                        navigate(`/${constants.VENDORKAMREQUESTS.ROUTE.MAIN}`);
                    }, 1000);
                } else {
                    modelProps.snackbarShowMessage(
                        `${constants.ERROR.DELETION_ERROR} ${constants.ERROR.DELETE_ERROR}`,
                        "error",
                        "5000",
                        constants.ERROR.DELETE_ERROR
                    );
                }
            }).catch(err=>{
                modelProps.snackbarShowMessage(
                    `${constants.ERROR.DELETION_ERROR} ${err && err.data.message}`,
                    "error",
                    "5000",
                    constants.ERROR.DELETE_ERROR
                );
            })

        }
    }

    const renderOption = option => (
        <Row gridGap="S200" alignItems="center">
            {option.name}
        </Row>
    );

    const handleText = (value) => {
        formData.comment = value;
    }

    const renderModal = ({ close }) => (
        <ModalContent
            titleText="Decline Request"
            buttons={[
                <Button onClick={() => { handleApply({ close: close, ...props }); }} variant={ButtonVariant.Secondary} id="btnHandleDelete" dataTestId="btnHandleDelete">
                    Decline
                </Button>,
                <Button onClick={close} variant={ButtonVariant.Primary} id="btnHandleClose" dataTestId="btnHandleClose">
                    Cancel
                </Button>
            ]}>
            <Container style={{ minHeight: '250px' }}>
                <Row>
                    <Text>Are you sure you want to Decline this KAM Request?</Text>
                </Row>
                <Row>
                    <Col gridGap="S200" width={"100%"}>
                        <Label htmlFor="selector-1" id="fruit-selector-label-1">
                            Select Reason:
                        </Label>
                        <Select
                            labelId="selector-label-1"
                            id="reason"
                            options={reasons}
                            onChange={kamDeclineReasonChangeHandler}
                            valueAccessor={option => option.name}
                            renderOption={renderOption}
                        />
                    </Col>
                </Row>
                <Row>
                    {isFormSubmitted &&
                        !reasons.find((reason) => reason.is_selected) && (
                            <Col width={"100%"}>
                                <InputFooter error>Reason is required..</InputFooter>
                            </Col>
                        )}
                </Row>
                <Row>
                    {reasons.find((reason) => reason.is_selected) &&
                        reasons.find((reason) => reason.is_selected).name === "Other" && (
                            <Col gridGap="S200" style={{ width: '100%' }}>
                                <Label htmlFor="some-textarea-id">
                                    Comment:
                                </Label>
                                <TextArea
                                    id="some-textarea-id"
                                    resize="none"
                                    placeholder="e.g. Head Count too High."
                                    onChange={handleText}
                                    height={'100%'}
                                />
                            </Col>
                        )}
                </Row>
                <Row>
                    {isFormSubmitted && (reasons.find((reason) => reason.is_selected) && reasons.find((reason) => reason.is_selected).name === "Other") &&
                        !formData.comment && (
                            <Col width={"100%"}>
                                <InputFooter error>Comment is required..</InputFooter>
                            </Col>
                        )}
                </Row>
            </Container>
        </ModalContent>
    );

    const renderAcceptModal = ({ close }) => (
        <ModalContent
            titleText="Accept Request"
            buttons={[
                <Button id="btnHandleAccept" onClick={() => { approveKamRequest({ close: close }); }} variant={ButtonVariant.Secondary}>
                    Accept
                </Button>,
                <Button onClick={close} variant={ButtonVariant.Primary}>
                    Cancel
                </Button>
            ]}>
            <Text>Are you sure you want to approve this KAM request a new vendor KAM will be created?</Text>
        </ModalContent>
    );

    return (
        <View>
            <Col gridGap="S300" {...paddingProps} >

                <Row display="flex">
                    <Link style={{ cursor: "pointer" }} icon={<IconArrowLeft />} iconPosition={LinkIconPosition.Leading} onClick={() => (navigate(`/${constants.VENDORKAMREQUESTS.ROUTE.MAIN}`))} fontWeight="normal" textDecoration="none">Back to request list</Link>
                </Row>

                <Row display="flex" justifyContent="space-between" flexWrap="wrap">

                    <Col flexWrap="wrap" gridGap="S200">

                        <Row flexWrap="wrap" gridGap="S200" alignItems="flex-end">
                            <Text fontSize="T500">{formData.OrganizationVendorKamName + ','}</Text>
                            <Text fontSize="T500">{formData.OrganizationType}</Text>
                            <Text fontSize="T300" style={{ marginBottom: "0.2rem" }}>Status: {sentenceCase(formData.OrganizationRequestStatus)}</Text>
                        </Row>

                        <Row flexWrap="wrap" gridGap="S200" alignItems="flex-end">
                            <Text fontSize="T200">Vendor KAM Id: {formData.OrganizationVendorKamId}</Text>
                            <Text color="neutral40">&#124;</Text>
                            <Text fontSize="T200">Vendor Id: {formData.OrganizationVendorId}</Text>
                            <Text color="neutral40">&#124;</Text>
                            <Text fontSize="T200">Vendor Name: {formData.OrganizationVendorName}</Text>
                        </Row>
                        <Row flexWrap="wrap" gridGap="S200" alignItems="flex-end">
                            <Text fontSize="T200">Vendor KAM Name: {formData.OrganizationVendorKamName}</Text>
                            <Text color="neutral40">&#124;</Text>
                            <Text fontSize="T200">Requester : {formData.OrganizationRequester}</Text>
                            <Text color="neutral40">&#124;</Text>
                            <Text fontSize="T200">Request Type: {formData.OrganizationRequestType}</Text>

                        </Row>
                    </Col>
                    {matches.xl || matches.l
                        ?
                        <Col>
                            <Row gridGap="S200" flexWrap="wrap" style={{ marginTop: "0.8rem" }}>
                                {formData.OrganizationRequestStatus === constants.STATUS[0] && <View flex={0.5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <WithModal renderModal={(close) => renderModal(close)} shouldCloseOnClickOutside={false}>
                                        {({ open }) => (<>
                                            <Button id="btnEditRequest" dataTestId="btnEditRequest" style={{ marginRight: '10px' }} icon={<IconCross />} size={ButtonSize.Small} variant={ButtonVariant.Secondary}
                                                onClick={open} >{constants.BUTTON.DECLINE}</Button>
                                            <span hidden id="sroDialog">has pop-up dialog</span>
                                        </>)}
                                    </WithModal>
                                    <WithModal renderModal={(close) => renderAcceptModal(close)} shouldCloseOnClickOutside={false}>
                                        {({ open }) => (<>
                                            <Button id="btnEditRequest" dataTestId="btnEditRequest" icon={<IconCheck />} size={ButtonSize.Small} variant={ButtonVariant.Primary}
                                                onClick={open} >{constants.BUTTON.ACCEPT}</Button>
                                            <span hidden id="sroDialog">has pop-up dialog</span>
                                        </>)}
                                    </WithModal>

                                </View>}
                            </Row>
                        </Col>
                        : <></>
                    }
                </Row>
                {matches.m || matches.s
                    ?
                    <Col>
                        <Row gridGap="S200" flexWrap="wrap" style={{ marginTop: "0.8rem" }}>
                            {formData.OrganizationRequestStatus === constants.STATUS[0] && <View flex={0.5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <WithModal renderModal={(close) => renderModal(close)} shouldCloseOnClickOutside={false}>
                                    {({ open }) => (<>
                                        <Button id="btnEditRequest" dataTestId="btnEditRequest" style={{ marginRight: '10px' }} icon={<IconCross />} size={ButtonSize.Small} variant={ButtonVariant.Secondary}
                                            onClick={open} >{constants.BUTTON.DECLINE}</Button>
                                        <span hidden id="sroDialog">has pop-up dialog</span>
                                    </>)}
                                </WithModal>

                                <WithModal renderModal={(close) => renderAcceptModal(close)} shouldCloseOnClickOutside={false}>
                                    {({ open }) => (<>
                                        <Button id="btnEditRequest" dataTestId="btnEditRequest" icon={<IconCheck />} size={ButtonSize.Small} variant={ButtonVariant.Primary}
                                            onClick={open} >{constants.BUTTON.ACCEPT}</Button>
                                        <span hidden id="sroDialog">has pop-up dialog</span>
                                    </>)}
                                </WithModal>

                            </View>}
                        </Row>
                    </Col>
                    : <></>
                }
            </Col>
            <Row flexWrap="wrap" justifyContent="space-between">
                <Col width="100%" >
                    <View style={(matches.l || matches.xl) ? { margin: '0 0 0 4rem' } : { margin: '0 0 0 1rem' }}><TabBar {...tabBarProps} display="flex" justifyContent="space-between" width="100%" /></View>
                    <Hr color="neutral40" style={{ marginTop: "-1px" }} />
                    <TabSwitcher {...tabSwitcherProps} >
                        <TabPanel value="Details">
                            <Col flexWrap="wrap" margin={{ top: "2rem", left: "1rem", right: "1rem" }} alignItems="center">
                                <Row flexWrap="wrap" justifyContent="space-between">
                                    <Col flexWrap="wrap" border="1px solid #B9C0C8" margin={{ top: '1rem' }} style={{ width: matches.s ? '100%' : '100%' }}>
                                        <Card backgroundColor="primary05" border="0 !important">
                                            <Col gridGap="S100" width='100%'>
                                                <Text fontSize="T200" color="neutral90">{constants.FORM_DETAILS.LOCATION}</Text>
                                                <Flex flexDirection={matches.s ? "column" : "row"} alignItems={'baseline'} justifyContent={matches.s ? "space-between" : matches.m ? "space-between" : "none"} gridGap={(matches.s || matches.m) ? "S200" : "S100"} >
                                                    <View flex={1} >
                                                        <Flex flexWrap="wrap" gridGap={(matches.s || matches.m) ? "S100" : "S200"}>
                                                            <Text fontSize="T200" fontWeight="bold" color="neutral90">Username: {formData.OrganizationUsername}</Text>
                                                            <Text color="neutral30" fontWeight="bold">&#124;</Text>
                                                            <Text fontSize="T200" fontWeight="bold" color="neutral90">Email: {formData.OrganizationCorrespondenceEmail}</Text>
                                                            <Text color="neutral30" fontWeight="bold">&#124;</Text>
                                                            <Text fontSize="T200" fontWeight="bold" wordBreak="break-word" color="neutral90">Country: {formData.OrganizationCountry}</Text>
                                                            <Text color="neutral30" fontWeight="bold">&#124;</Text>
                                                            <KamRequestAddressCard formData = {formData}/>
                                                            <Text fontSize="T200" fontWeight="bold" wordBreak="break-word" color="neutral90">Vendor Type: {formData.OrganizationType}</Text>
                                                        </Flex>
                                                    </View>
                                                </Flex>
                                            </Col>
                                        </Card>
                                        <Card border="0 !important">
                                            <Col gridGap="S100" width='100%'>
                                                <Text fontSize="T200" color="neutral90">{constants.FORM_DETAILS.FOR_REFERENCE}</Text>
                                                <Flex flexDirection={matches.s ? "column" : "row"} alignItems={'baseline'} justifyContent={matches.s ? "space-between" : matches.m ? "space-between" : "none"} gridGap={(matches.s || matches.m) ? "S200" : "S100"} >
                                                    <View flex={1} >
                                                        <Flex flexWrap="wrap" gridGap={(matches.s || matches.m) ? "S100" : "S200"}>
                                                            <Text fontSize="T200" fontWeight="bold" color="neutral90">Site(s): {formData.OrganizationSites}</Text>
                                                            <Text color="neutral30" fontWeight="bold">&#124;</Text>
                                                            <Text fontSize="T200" fontWeight="bold" color="neutral90">Type: {formData.OrganizationType}</Text>
                                                            <Text color="neutral30" fontWeight="bold">&#124;</Text>
                                                            <Text fontSize="T200" fontWeight="bold" wordBreak="break-word" color="neutral90">Request date: {formData.OrganizationRequestedDate}</Text>
                                                            <Text color="neutral30" fontWeight="bold">&#124;</Text>
                                                            <Text fontSize="T200" fontWeight="bold" color="neutral90">Comments: {formData.comments}</Text>
                                                        </Flex>
                                                    </View>
                                                </Flex>
                                            </Col>
                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </TabPanel>
                        <TabPanel value="Audit logs">
                            <Col flexWrap="wrap" margin={{ top: "2rem", left: "1rem", right: "1rem" }} alignItems="center">
                                <AuditLogCards config={{ vendorKamId: params.vendorKamId, kamRequestId: params.kamRequestId, vendorId: localStorage.getItem('vendorId'), entityType: "KAM_Request",country: formData.OrganizationCountry }}
                                    textForFirstLog={constants.VENDORKAMREQUESTS.TEXT_FOR_FIRST_AUDIT_LOG} />
                            </Col>
                        </TabPanel>
                    </TabSwitcher>
                </Col>
            </Row>
        </View>
    )

    // return (

    //         {showRejectConfirmation && (
    //             <KamReqeustDeclineDialog
    //                 handleCancel={() => {setShowRejectConfirmation(false);}}
    //                 formData={formData}
    //                 snackbarShowMessage={props.snackbarShowMessage}
    //             />
    //         )}
    //         {showApproveConfirmation && (
    //             <FormActionAlertDialog
    //                 Message="Are you sure you want to approve this KAM request a new vendor KAM will be created?"
    //                 handleYes={approveKamRequest}
    //                 handleNo={() => {
    //                     setShowApproveConfirmation(false);
    //                 }}
    //             />
    //         )}
    //     </div>
    // );
}

export default withAlertSnackBar(ViewVendorKamRequest);
