/* eslint-disable react-hooks/exhaustive-deps */
import React, {  useEffect, useState } from 'react'
import { MasterServices } from "../../Services/master";
import { Col, Container, Row, View } from '@amzn/stencil-react-components/layout';
import constants from '../../Utils/Constants';
import { Text } from '@amzn/stencil-react-components/dist/submodules/text';
import { SideNavItem, SideNavItems } from "@amzn/stencil-react-components/side-nav";
import { Checkbox, InputWrapper, LabelPosition } from "@amzn/stencil-react-components/form";
// import { Select, SelectLayout } from "@amzn/stencil-react-components/form";
import { SearchField } from '@amzn/stencil-react-components/search';
import { Chip } from "@amzn/stencil-react-components/chip";

const expandableFilterItems = (item, onChangeCallback) => {
  const checkChild = (e) => {
    e.target?.querySelector("label")?.click();
  }

  return (
    <SideNavItem key={"sideNavItem" + item.id} dataTestId={"sideNavItem" + item.id} display="flex" onClick={(e) => checkChild(e)}>
      <InputWrapper
        id={"navItem" + item.id}
        labelText={item.name}
        labelPosition={LabelPosition.Trailing}>
        {inputProps => <Checkbox checked={item.is_selected} onChange={e => onChangeCallback(item)} {...inputProps} />}
      </InputWrapper>
    </SideNavItem>
  );
}

function OrganizationsFilterDialog(props) {
  const [types, setTypes] = React.useState(JSON.parse(JSON.stringify(constants.FILTERS.ORG_TYPES)));
  const [constractStatus, setConstractStatus] = React.useState(JSON.parse(JSON.stringify(constants.FILTERS.ORG_CONTRACT_STATUS)));
  const [countries, setCountries] = React.useState(constants.FILTERS.ORG_COUNTRIES);
  const [sites, setSites] = useState([]);
  const [querySites, setQuerySites] = useState([]);
  const [query, setQuery] = useState('');

  useEffect(() => {
    async function fetchData() {
      
      await MasterServices.getAllSites().then((response) => {
        let data = (response && response.data) || [];
        setQuerySites(data.map((site) => site.siteId));
        let sitesL = data.map((site) => ({
          id: site.siteId,
          is_selected: false,
          name: site.siteId ? site.siteId : "",
        }));
        setSites(sitesL);
      }).catch((err)=>{
        console.log(err);
      });
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(()=>{
    updateFilters();
  },[JSON.stringify(types), JSON.stringify(constractStatus), JSON.stringify(sites),JSON.stringify(countries)]);

  const resetFilters = () => {
    let sitesL = sites.map((site)=>{
      site.is_selected = false;
      return site;
    });
    setSites(sitesL);
    setCountries(JSON.parse(JSON.stringify(constants.FILTERS.ORG_COUNTRIES)));
    setTypes(JSON.parse(JSON.stringify(constants.FILTERS.ORG_TYPES)));
    setConstractStatus(JSON.parse(JSON.stringify(constants.FILTERS.ORG_CONTRACT_STATUS)));
    props.onChange({ filtersApplied: true, filters: { resetAppliedFilter: false, types: [], sites: [], contractStatus: [],countries: [] } })
  }

  const updateFilters = () => {
    const typesL = types.filter((item) => item.is_selected).map((item) => item.name);
    const constractStatusL = constractStatus.filter((item) => item.is_selected).map((item) => item.name);
    const sitesL = sites.filter(site => site.is_selected).map((site)=>site.id);
    const countryList = countries.filter(country => country.is_selected).map((country)=>country.id);
    props.onChange({ filtersApplied: true, filters: { resetAppliedFilter: false, types: typesL, sites: sitesL, contractStatus: constractStatusL,countries: countryList}});
  }

  const organizationTypeChangeHandler = (value) => {
    let typesL = JSON.parse(JSON.stringify(types));
    typesL.forEach((lob) => lob.is_selected = (lob.name === (value && value.name) ? !lob.is_selected : lob.is_selected));
    setTypes(typesL);
    // updateFilters();
  };

  const organizationContractChangeHandler = (value) => {
    let typesL = JSON.parse(JSON.stringify(constractStatus));
    typesL.forEach((lob) => lob.is_selected = (lob.name === (value && value.name) ? !lob.is_selected : lob.is_selected));
    setConstractStatus(typesL);
    // updateFilters();
  };

  const organizationCountryChangeHandler = (value) => {
    let countryList = JSON.parse(JSON.stringify(countries));
    countryList.forEach((country) => country.is_selected = (country.name === (value && value.name) ? !country.is_selected : country.is_selected));
    setCountries(countryList);
  };

  // const organizationSiteChangeHandler = (event, value) => {
  //   let selectedIds = value && value.length > 0 ? value.map((val) => val.id) : []
  //   let sitesL = JSON.parse(JSON.stringify(sites];
  //   sitesL.forEach((site) => {
  //     site.is_selected = selectedIds && selectedIds.length > 0 && selectedIds.includes(site.id)
  //   });
  //   setSites(sitesL);
  // }

  const handleClear = () => {
    resetFilters();
    // let externalFilters = {}
    // externalFilters.type = '';
    // externalFilters.sites = '';
    // props.handleApply(externalFilters);
  };

  const searchSiteResults = !!query
    ? querySites.filter(site => site.toLowerCase().includes(query.toLowerCase()))
    : querySites;
/*istanbul ignore next line */
  const searchSiteResultClickHandler = (selectedSite) => {
    let sitesL = JSON.parse(JSON.stringify(sites));
    sitesL.forEach((site) => {
      site.is_selected = site.is_selected || (selectedSite && site.id === selectedSite)
    })
    setSites(sitesL)
    setQuery('')
  }

  const chipRows = () => {
    let sitesL = sites.filter(site => site.is_selected)
    return (
      <SideNavItem>
        <Row gridGap="S100" flexWrap="wrap">
          {sitesL.map(site => createChip(site, chipDismissHandler))}
        </Row>
      </SideNavItem>
    )
  }
  /*istanbul ignore next line */
  const chipDismissHandler = (dismissedChip) => {
    let siteL = JSON.parse(JSON.stringify(sites));
    siteL.forEach(site => {
      site.is_selected = (dismissedChip && dismissedChip.id === site.id) ? false : site.is_selected
    })
    setSites(siteL)
  }

  const createChip = (site, chipDismissHandler) => {
    return (
      <Chip size="small" onDismiss={() => { chipDismissHandler(site) }} key={"Chip" + site.id}>
        {site.id}
      </Chip>
    );
  }

  return (
    <Container height="100%" style={{ padding: "0", margin: "0" }}>
      {props.mode === constants.DEVICE.DESKTOP &&
        <Col style={{ boxShadow: "inset 0px -1px 0px #8B96A3" }}>
          <Row justifyContent="space-between" alignItems='baseline'>
            <View padding={'1em'}><Text dataTestId="textFilter" fontSize="T300" fontWeight={'bold'}>Filter</Text></View>
            <View padding={'1em'}><Text id="textReset" color='primary70' fontSize="T200" onClick={handleClear} style={{ cursor: 'pointer' }}>Reset</Text></View>
          </Row>
        </Col>}
      <Col>
        <View style={{ boxShadow: "inset 0px -1px 0px #8B96A3" }} className={'orgFiltersSidenav'}>
          <SideNavItems id="types" isExpanded={true} label="Types" dataTestId='typesSideNav'>
            {types.map((item) => expandableFilterItems(item, organizationTypeChangeHandler))}
          </SideNavItems>
        </View>
        <View style={{ boxShadow: "inset 0px -1px 0px #8B96A3" }} className={'orgFiltersSidenav'}>
          <SideNavItems id="types" label="Contract Status" dataTestId='constractSidenav'>
            {constractStatus.map((item) => expandableFilterItems(item, organizationContractChangeHandler))}
          </SideNavItems>
        </View>
        <View style={{ boxShadow: "inset 0px -1px 0px #8B96A3" }} className={'orgFiltersSidenav'}>
          <SideNavItems id="types" label="Country" isExpanded={true} dataTestId='countrySidenav'>
            {countries.map((item) => expandableFilterItems(item, organizationCountryChangeHandler))}
          </SideNavItems>
        </View>
        <View style={{ boxShadow: "inset 0px -1px 0px #8B96A3" }} className={'orgFiltersSidenav'}>
          <SideNavItems label="Vendor site" dataTestId='reqFiltersSidenav' style={{ height: 'auto' }}>
            <SideNavItem id="vendorSiteSearchBox">
              <InputWrapper id="Vendor-Site-Search-box">
                {inputProps => (
                  <SearchField
                    query={query}
                    onChange={setQuery}
                    results={searchSiteResults}
                    onResultClick={searchSiteResultClickHandler}
                    placeholder="Search vendor site"
                    noResultsText="No Sites found"
                    dataTestId="siteSearch"
                  />)}
              </InputWrapper>
            </SideNavItem>
            {chipRows()}

          </SideNavItems>
        </View>
      </Col>
    </Container>
  )
}

export default OrganizationsFilterDialog
