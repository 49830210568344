import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import "date-fns";
import constants, {FILE_CONSTANTS, FORM_DATA_ATTRIBUTES, MAX_LIMITS} from "../../Utils/Constants";
import { withAlertSnackBar } from "../../HOComponents/AlertSnackBarHOC";
import {Col, Row, Spacer} from "@amzn/stencil-react-components/layout";
import { Label, Text } from "@amzn/stencil-react-components/text";
import { Input, InputFooter, Select } from "@amzn/stencil-react-components/form";
import { useBreakpoints } from "@amzn/stencil-react-components/responsive";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { Link } from "@amzn/stencil-react-components/link";
import {IconArrowLeft} from "@amzn/stencil-react-components/icons";
import {ParentVendorServices} from "../../Services/parentVendor";
import {useGetAllParentVendors} from "../../ReactQuery/hooks/useGetAllParentVendors";
import FullPageCenteredSpinner from "../common/FullPageCenteredSpinner";
import {validatePattern} from "../../Utils/Validator";
import {FileUpload} from "@amzn/stencil-react-components/file-upload";
import {FileIcon} from "../common/FileIcons";


const propsMap2 = {
    s: { width: '100%' },
    m: { width: '70%' },
    l: { width: '70%' },
    xl: { width: '60%' }
}

const propsMap1 = {
    s: { width: "100%" },
    m: { width: "48%" },
    l: { width: "48%" },
    xl: { width: "48%" }
}

function CreateParentVendor(props) {

    const { props: mainViewBreakPoints } = useBreakpoints({ propsMap: propsMap2 });
    const { props: inputFieldBreakPoints, matches } = useBreakpoints({ propsMap: propsMap1 })


    const navigate = useNavigate();
    const params = useParams();
    const types = React.useRef(constants.FILTERS.VENDOR_TYPES);
    const [formData, setFormData] = useState({vendorName:"",vendorId:"",vendorTypes:[],brandingFile:"",faviconFile:"",subdomain:""});
    const [errorMessages,setErrorMessages] = useState({vendorName:"",vendorId:"",subdomain:""});
    const [loading, setLoading ] = useState(false);
    const { data: queryVendors, isFetching: vendorLoading } = useGetAllParentVendors();
    const [showFileIcon, setShowFileIcon] = useState({branding:false,favicon:false});
    const [vendorData, setVendorData] = useState();

    useEffect(() => {
        if (params.vendorId) {
            prepopulate();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.vendorId]);

    const prepopulate = async () => {
        await ParentVendorServices.getParentVendor(params.vendorId)
            .then((response) => {
                let formDataL = {...formData};
                formDataL.vendorId = response.data.vendorId;
                formDataL.vendorName = response.data.vendorName;
                formDataL.vendorTypes = response.data.vendorTypes;
                formDataL.subdomain = response.data.subdomain;
                let showFileIconL = {...showFileIcon};
                if(response.data.brandingUrl!==""){
                    showFileIconL.branding=true;
                }
                if(response.data.faviconUrl!==""){
                    showFileIconL.favicon=true;
                }
                setShowFileIcon(showFileIconL);
                setVendorData(response.data);
                setFormData(formDataL);
            })
            .catch((error) => {
                props.snackbarShowMessage(`${constants.ERROR.PREPOPULATE_ERROR}`,
                    "error");
            })
    }

    function getFileUploadRequest(file,fileName,key){
        const fileBlob = new Blob([file],{ type: file.type });
        const formDataRequest = new FormData();
        formDataRequest.append(FILE_CONSTANTS.FILE, fileBlob, fileName);
        formDataRequest.append(FILE_CONSTANTS.ID_TOKEN, localStorage.getItem(FILE_CONSTANTS.ID_TOKEN));
        formDataRequest.append(FILE_CONSTANTS.ACCESS_TOKEN, localStorage.getItem(FILE_CONSTANTS.ACCESS_TOKEN));
        formDataRequest.append(FILE_CONSTANTS.CONTENT_TYPE, file.type);
        formDataRequest.append(FILE_CONSTANTS.FILE_NAME, key);
        return formDataRequest;
    }

    const formSubmit = async () => {
        if (formData.vendorName === "" || formData.vendorId === "" || formData.vendorTypes.length === 0 || formData.subdomain === "") {
            let errorMessagesL = {...errorMessages}
            if (formData.vendorName === "")
                errorMessagesL.vendorName = constants.ERROR.VENDOR_NAME_FIELD_EMPTY_ERROR;
            if (formData.vendorId === "")
                errorMessagesL.vendorId = constants.ERROR.VENDOR_ID_FIELD_EMPTY_ERROR;
            if (formData.vendorTypes.length === 0) {
                props.snackbarShowMessage(`${constants.ERROR.VENDOR_TYPE_FIELD_EMPTY_ERROR}`, "error", 3000, constants.ERROR.VENDOR_TYPE_FIELD_EMPTY_ERROR);
            }
            if (formData.subdomain === "")
                errorMessagesL.subdomain = constants.ERROR.SUBDOMAIN_EMPTY;
            setErrorMessages(errorMessagesL);
            return;
        }
        setLoading(true);
        let body = {
            vendorName: formData.vendorName.trim(),
            vendorTypes: formData.vendorTypes,
            vendorId: formData.vendorId.trim(),
            status: "ACTIVE",
            subdomain: formData.subdomain,
            brandingUrl: formData.vendorId + FILE_CONSTANTS.BRANDING,
            faviconUrl: (formData.faviconFile || showFileIcon.favicon) ? formData.vendorId + FILE_CONSTANTS.FAVICON : ""
        };

        try{
            if (formData.brandingFile) {
                const formDataRequest = getFileUploadRequest(formData.brandingFile,
                    formData.vendorId + FILE_CONSTANTS.BRANDING_FILENAME, formData.vendorId + FILE_CONSTANTS.BRANDING);
                await ParentVendorServices.uploadFile(formDataRequest)
            }

            if (formData.faviconFile) {
                const formDataRequest = getFileUploadRequest(formData.faviconFile,
                    formData.vendorId + FILE_CONSTANTS.FAVICON_FILENAME, formData.vendorId + FILE_CONSTANTS.FAVICON);
                await ParentVendorServices.uploadFile(formDataRequest)
            }

        }catch (e) {
            setLoading(false);
            props.snackbarShowMessage(`${constants.ERROR.S3_UPLOAD_ERROR}`, "error");
            return;
        }

        if (params.vendorId) {
            ParentVendorServices.updateParentVendor(body)
                .then((response) => {
                    setLoading(false);
                    props.snackbarShowMessage(
                        `${constants.CRUD_SUCCESS_ALERTS.UPDATED}`
                    );
                    window.setTimeout(() => {
                        navigate(
                            `/${constants.PARENTVENDORS.ROUTE.VIEW}/${params.vendorId}`
                        );
                    }, 1000);
                })
                .catch((response, error) => {
                    setLoading(false);
                    props.snackbarShowMessage(
                        `${constants.ERROR.UPDATE_ERROR} ${response.message}`,
                        "error",
                        5000,
                        response.message
                    );
                });
        } else {
            ParentVendorServices.createParentVendor(body)
                .then((response) => {
                    setLoading(false);
                    if (props.data && props.data.message) {
                        props.snackbarShowMessage(
                            `${constants.ERROR.CREATE_ERROR} ${response.message}`,
                            "error",
                            5000,
                            response.message
                        );
                    } else {
                        props.snackbarShowMessage(
                            `${constants.CRUD_SUCCESS_ALERTS.CREATED}`
                        );
                        window.setTimeout(() => {
                            navigate(
                                `/${constants.PARENTVENDORS.ROUTE.VIEW}/${formData.vendorId}`
                            );
                        }, 1000);
                    }
                })
                .catch((response) => {
                    setLoading(false);
                    props.snackbarShowMessage(
                        `${constants.ERROR.CREATE_ERROR} ${response.message}`,
                        "error",
                        5000,
                        response.message
                    );
                });
        }
    }


    const cancelVendor = () => {
        if (params.vendorId) {
            navigate(`/${constants.PARENTVENDORS.ROUTE.VIEW}/${params.vendorId}`);
        } else {
            navigate(`/${constants.PARENTVENDORS.ROUTE.MAIN}`);
        }
    };

    function updateFormData(key,value){
        let formDataL = {...formData};
        formDataL[key] = value;
        setFormData(formDataL);
    }

    function handleVendorName(event) {
        let vendorNameList = queryVendors.data.parentVendors.map(item=>item.vendorName.toUpperCase());
        let vendorName = event.target.value.toUpperCase();
        let errorMessagesL = {...errorMessages};
        const isVendorNamePresent = vendorNameList.includes(vendorName.trim());
        const isDifferentNameForVendor = params.vendorId && vendorData.vendorName !== vendorName.trim();
        if(isVendorNamePresent && !isDifferentNameForVendor){
            errorMessagesL.vendorName = constants.ERROR.VENDOR_NAME_EXISTS;
        }
        else if(vendorName.length > MAX_LIMITS.PARENT_VENDOR.NAME || !validatePattern(new RegExp(constants.PATTERN.ALPHA_NUMERIC_DOT_PATTERN), vendorName) || vendorName.trim().length===0) {
            errorMessagesL.vendorName = constants.ERROR.VENDOR_NAME_INVALID;
        }
        else{
            errorMessagesL.vendorName = ""
        }
        updateFormData(FORM_DATA_ATTRIBUTES.VENDOR_NAME,event.target.value);
        setErrorMessages(errorMessagesL);
    }

    function handleVendorId(event) {
        let vendorIdL = event.target.value.toUpperCase();
        let vendorIdList = queryVendors.data.parentVendors.map(item=>item.vendorId.toUpperCase());
        let errorMessagesL = {...errorMessages};
        if(vendorIdList.includes(vendorIdL.trim()))
            errorMessagesL.vendorId = constants.ERROR.VENDOR_EXISTS;
        else if(vendorIdL.length > MAX_LIMITS.PARENT_VENDOR.ID || !validatePattern(new RegExp(constants.PATTERN.ALPHA_NUMERIC_DOT_PATTERN), event.target.value) || event.target.value.trim().length===0)
            errorMessagesL.vendorId = constants.ERROR.VENDOR_ID_INVALID;
        else
            errorMessagesL.vendorId="";
        setErrorMessages(errorMessagesL);
        updateFormData(FORM_DATA_ATTRIBUTES.VENDOR_ID,vendorIdL);
    }

    function handleSubdomain(event) {
        let subdomain = event.target.value.toLowerCase();
        let subdomainsList = queryVendors.data.parentVendors.map((item) =>  item.subdomain ?  item.subdomain.toLowerCase() : "");
        let errorMessagesList = {...errorMessages};
        const isSubdomainPresent = subdomainsList.includes(subdomain.trim());
        const isDifferentSubdomainForVendor = params.vendorId && vendorData.subdomain !== subdomain.trim();
        if (isSubdomainPresent && !isDifferentSubdomainForVendor) {
            errorMessagesList.subdomain = constants.ERROR.SUBDOMAIN_EXISTS;
        } else {
            errorMessagesList.subdomain = "";
        }
        setErrorMessages(errorMessagesList);
        updateFormData(FORM_DATA_ATTRIBUTES.SUBDOMAIN,event.target.value.toLowerCase());
    }

    const brandingHandler = ({ files, setFiles, setFailedFiles, setRequests }) => {
        updateFormData(FORM_DATA_ATTRIBUTES.BRANDING,files[0]);
        updateShowFileIcon(FILE_CONSTANTS.ATTRIBUTES.BRANDING,false);
    }
    const faviconHandler = ({ files, setFiles, setFailedFiles, setRequests }) => {
        updateFormData(FORM_DATA_ATTRIBUTES.FAVICON,files[0]);
        updateShowFileIcon(FILE_CONSTANTS.ATTRIBUTES.FAVICON,false);
    }
    function getIsDisabled() {
        if(!formData.brandingFile && !showFileIcon.branding)
            return true;

        return formData.vendorName === "" || formData.vendorTypes.length === 0 || formData.vendorId === "" || !formData.subdomain
            || errorMessages.vendorName !== "" || errorMessages.vendorId !== "" || errorMessages.subdomain !== "";
    }

    function updateShowFileIcon(key, value){
        let showFileIconL = {...showFileIcon};
        showFileIconL[key] = value;
        setShowFileIcon(showFileIconL);
    }

    if(loading)
        return <FullPageCenteredSpinner />;

    return (
        <Col alignItems='center' style={{ padding: "1rem" }} gridGap='S300'>
            <Row {...mainViewBreakPoints}>
                <Link style={{ color: 'rgba(23, 104, 201, 1)' }} onClick={() => (navigate(`/${constants.PARENTVENDORS.ROUTE.MAIN}`))}>
                    <Row style={{ paddingTop: "2rem", paddingBottom: "1rem" }}>
                        <IconArrowLeft size="Large" /><Spacer width="0.3rem" /><Text fontSize={"1.25rem"}>Back to Vendor list</Text>
                    </Row>
                </Link>
            </Row>
            <Row {...mainViewBreakPoints}><Text fontSize="T500" fontWeight='bold'>{(params.vendorId) ? "Edit Vendor" : "Create New Vendor"}</Text></Row>
            {vendorLoading ? <FullPageCenteredSpinner /> :
            <Col gridGap='S500' {...mainViewBreakPoints}>
                <Col gridGap='S400'>
                        <Col gridGap='S100' {...inputFieldBreakPoints}>
                            <Label id='vendor-name'>Vendor Name* : </Label>
                            <Col>
                                <Input id='parentVendorName' dataTestId="parentVendorName" type='text' placeholder='Vendor Name' name="ParentVendorName" value={formData.vendorName} onChange={handleVendorName} />
                                {errorMessages.vendorName && <InputFooter error>{errorMessages.vendorName}</InputFooter>}
                            </Col>
                        </Col>
                        <Col gridGap='S100' {...inputFieldBreakPoints}>
                            <Label id='vendorId'>Vendor Id* : </Label>
                            <Col>
                                {params.vendorId ? <Input id='vendorId' disabled={true} type='text' placeholder='Vendor Id'
                                        name="ParentVendorId" value={formData.vendorId} onChange={handleVendorId}/> :
                                    <Input id='parentVendorId' dataTestId="parentVendorId" type='text' placeholder='Vendor Id'
                                           name="ParentVendorId" value={formData.vendorId} onChange={handleVendorId}/>
                                }
                                {errorMessages.vendorId &&
                                    <InputFooter error>{errorMessages.vendorId}</InputFooter>
                                }
                            </Col>
                        </Col>
                    <Col gridGap='S100' {...inputFieldBreakPoints}>
                        <Label id='vendor-type'>Vendor Type* : </Label>
                        <Col>
                            <Select
                                options={types.current.map(item => item.name)}
                                onChange={(value)=>{updateFormData(FORM_DATA_ATTRIBUTES.VENDOR_TYPES,value)}}
                                value={formData.vendorTypes}
                                isMulti={true}
                                id="type-selector"
                            />
                        </Col>
                    </Col>
                    <Col gridGap='S100' {...inputFieldBreakPoints}>
                        <Label id='subdomainLabel'>Subdomain* : </Label>
                        <Col>
                            <Input id='subdomain' disabled={params.vendorId} type='text' placeholder='Enter Subdomain'
                                                      name="subdomain" value={formData.subdomain} onChange={handleSubdomain}/>
                            {errorMessages.subdomain &&
                                <InputFooter error>{errorMessages.subdomain}</InputFooter>
                            }
                        </Col>
                    </Col>
                    <Col gridGap='S100' {...inputFieldBreakPoints}>
                        <Label id='brandingLabel'>Brand Image * : </Label>
                        <Col gridGap={'S200'}>
                            <FileUpload
                                id="brandingUpload"
                                accept="image/*"
                                isMulti={false} uploadHandler={brandingHandler}
                                onFileAttached={() => null}
                                dragAndDropText="or drag and drop files"
                            />
                            {showFileIcon.branding &&
                                <FileIcon fileName={"branding"} deleteFile={()=>{
                                    updateShowFileIcon(FILE_CONSTANTS.ATTRIBUTES.BRANDING,false);
                                    updateFormData(FORM_DATA_ATTRIBUTES.BRANDING,null);
                                }}></FileIcon>
                            }
                        </Col>
                    </Col>
                    <Col gridGap='S100' {...inputFieldBreakPoints}>
                        <Label id='faviconLabel'>Favicon (optional) : </Label>
                        <Col>
                            <FileUpload
                                id="faviconUpload"
                                accept="image/*"
                                isMulti={false} uploadHandler={faviconHandler}
                                onFileAttached={() => null}
                                dragAndDropText="or drag and drop files"
                            />
                            {showFileIcon.favicon &&
                                <FileIcon fileName={"favicon"} deleteFile={()=>{
                                    updateShowFileIcon(FILE_CONSTANTS.ATTRIBUTES.FAVICON,false);
                                    updateFormData(FORM_DATA_ATTRIBUTES.FAVICON,null);
                                }}></FileIcon>
                            }
                        </Col>
                    </Col>

                </Col>
                <Col>
                    {matches.s ?
                        <Col width='100%' gridGap='S200'>
                            <Button id="submitButton" dataTestId="submitButton" disabled={getIsDisabled()} style={{ width: '100%' }} variant={ButtonVariant.Primary} onClick={formSubmit}>{params.vendorId ? 'Update' : 'Create'}</Button>
                            <Button id="cancelButton" dataTestId="cancelButton" style={{ width: '100%' }} onClick={cancelVendor}>Cancel</Button>
                        </Col>
                        :
                        <Row gridGap='S200' width='50%'>
                            <Button id="CancelButton" dataTestId="CancelButton" onClick={cancelVendor}>Cancel</Button>
                            <Button id="SubmitButton" dataTestId="SubmitButton" disabled={getIsDisabled()} variant={ButtonVariant.Primary} onClick={formSubmit}>{params.vendorId ? 'Update' : 'Create'}</Button>
                        </Row>
                    }
                </Col>
            </Col>}
        </Col>
    )


}

export default withAlertSnackBar(CreateParentVendor);
