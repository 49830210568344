import constants from "./Constants";

export function getValidity(expiry) {
    const currentDate = new Date();
    const currentTime = currentDate.getTime();
    const expiryDate = new Date(expiry);
    const expiryTime = expiryDate.getTime();
    return Math.max(0, Math.ceil((expiryTime - currentTime - (330 * 60 * 1000)) / (1000 * 3600 * 24)));
}

export const disablePastDate = (minDate) => {
    minDate.setDate(minDate.getDate() + 1);
    let month = minDate.getMonth() + 1;
    let day = minDate.getDate();
    const year = minDate.getFullYear();
    if(month < 10)
        month = '0' + month.toString();
    if(day < 10)
        day = '0' + day.toString();

    return year + '-' + month + '-' + day;
}

export function changeDateFormatToIST(inputDate) {
        if (inputDate) {
            let date = inputDate.toString();
            date = date.split('.')[0];
            const oldFormatDate = new Date(date);
            var dateIST = new Date(oldFormatDate);
            //date shifting for IST timezone (+5 hours and 30 minutes)
            dateIST.setHours(dateIST.getHours() + 5);
            dateIST.setMinutes(dateIST.getMinutes() + 30);
            const day = dateIST.getDate();
            const month = dateIST.getMonth();
            const year = dateIST.getFullYear();
            const months = constants.MONTHS
            const newFormatDate = `${day} ${months[month]} ${year}`
            const hour = dateIST.getHours() < 10 ? "0" + dateIST.getHours() : dateIST.getHours();
            const minutes = dateIST.getMinutes() < 10 ? "0" + dateIST.getMinutes() : dateIST.getMinutes();
            const time = hour + ":" + minutes
            const dateTime = newFormatDate + ", " + time + " IST";
            const ddmmyyyy = (day < 10 ? "0" + day : day) + "/" + (month < 10 ? "0" + (month + 1) : (month + 1)) + "/" + year;
            if (date) {
                return {
                    "newFormatDate": newFormatDate,
                    "month": months[month],
                    "year": year,
                    "time": time,
                    "dateTime": dateTime,
                    "ddmmyyyy": ddmmyyyy
                }
            }

            return '';
        }
}