import React, { useEffect, useState } from "react";
import constants from "../../../Utils/Constants";
import { useGetAllAuditTrailLogs } from "../../../ReactQuery/hooks/useGetAllAuditTrailLogs";
import { withAlertSnackBar } from "../../../HOComponents/AlertSnackBarHOC";
import { searchAuditLog } from "../../../Utils/AuditTrailLogsUtils";
import { Col, Container, Flex, Hr, Row } from "@amzn/stencil-react-components/layout";
import { SearchField } from "@amzn/stencil-react-components/search";
import { Spinner } from "@amzn/stencil-react-components/spinner";
import { Text } from "@amzn/stencil-react-components/text";

const getAuditCard = (props, auditLog, index) => {
    return (
        <Col key={index}>
            <Row justifyContent="baseline" width="100%">
                <Col width="100%">
                    <Row alignItems="baseline" gridGap="S100" flexWrap="wrap">
                        <Text fontSize="T200" color="#232F3E" style={{ display: 'flex' }}>
                            <span style={{ color: "#1768C9", marginRight: '10px' }}>{auditLog.modifiedBy ? auditLog.modifiedBy : props.config.vendorKamId ? props.config.vendorKamId : ""}</span>
                            <>{auditLog.actionType === constants.AUDIT_TRAIL.EVENT_TYPE_INSERT ? "created" : "edited"} this {auditLog.entityType ? auditLog.entityType : " issue "} on
                                <Text color="#56616C" fontSize="small" style={{ marginLeft: '10px', display: 'flex', alignItems: 'flex-end' }}><em>{auditLog.modifiedDate}</em>.</Text>
                            </>
                            </Text>
                    </Row>
                    {auditLog.actionType !== constants.AUDIT_TRAIL.EVENT_TYPE_INSERT &&
                        <Row margin={"0"}>
                            <Container direction="column" style={{ width: "100%" }}>
                                <ul style={{ lineHeight: "150%", marginTop: "0.35rem" }}>
                                    {auditLog.actionType !== constants.AUDIT_TRAIL.EVENT_TYPE_INSERT && auditLog.attributesChangedList && auditLog.attributesChangedList.map((attributeChanged, index) => {
                                        return (<li key={"sideNavItem" + index} display="flex">
                                            <Text fontSize="T200">
                                                {attributeChanged.key} <span style={{ fontSize: "0.75em", color: "#808080", padding: "0 0.1rem", textTransform: "lowercase" }}>modified to </span><Text fontSize="T200" display="inline" fontWeight="bold">{attributeChanged.newValue}</Text>
                                            </Text>
                                        </li>)
                                    })}
                                </ul>
                            </Container>
                        </Row>
                    }
                </Col>
            </Row>
            {auditLog.actionType !== constants.AUDIT_TRAIL.EVENT_TYPE_INSERT && <Hr color="neutral40" />}
        </Col>
    )
}

function AuditLogCards(props) {
    const [rowData, setRowData] = React.useState([]);
    const [globalRows, setGlobalRows] = React.useState([]);
    const [searchConfig, setSearchConfig] = useState('');
    const { data: queryLogs, isFetching: logsLoading, error: logError } = useGetAllAuditTrailLogs(props.config);

    useEffect(() => {
        if (logError) {
            props.snackbarShowMessage(
                `${constants.ERROR.FETCHING_ERROR} ${logError.data.message}`,
                "error",
                "4000",
            );
            setRowData([]);
            setGlobalRows([]);
        } else {
            let data = (queryLogs && queryLogs.data && queryLogs.data.auditTrail) || [];
            if (data && data.length !== 0)
                prepopulate(data);
        }
        // eslint-disable-next-line
    }, [queryLogs, logError]);

    useEffect(() => {
        if (globalRows && globalRows.length) {
            operateOnRows()
        }
        // eslint-disable-next-line
    }, [searchConfig])

    const prepopulate = (auditLogs) => {
        let rowsData = []
        if (auditLogs) {
            auditLogs.forEach(auditLog => {
                let attributesList = [];
                auditLog.attributesChanged.forEach(attribute => {
                    attributesList.push({
                        key: attribute.key,
                        newValue: attribute.newValue,
                        oldValue: attribute.oldValue
                    })
                })
                rowsData.push({
                    modifiedBy: auditLog.modifiedBy,
                    modifiedDate: auditLog.modifiedDate,
                    actionType: auditLog.actionType,
                    entityType: auditLog.entityType ? constants.AUDIT_TRAIL.ENTITY_TYPE[auditLog.entityType] : "",
                    attributesChangedList: attributesList
                })
            })
        }
        setRowData(rowsData);
        setGlobalRows(rowsData);
    }
    /* istanbul ignore next line */
    const operateOnRows = () => {
        let rowsData = [...globalRows];
        rowsData = searchAuditLog(rowsData, searchConfig);
        setRowData(rowsData);
    }

    return (
        <Col justifyContent="center" padding={{ top: "1.5rem" }} {...props} style={{ width: '80%' }}>
            <Row width="100%" margin={{ bottom: "1.5rem" }}>
                <SearchField
                    width="100%"
                    onChange={setSearchConfig}
                    query={searchConfig}
                    dataTestId="AuditLogSearch"
                    shouldShowListOnNoResults={false}
                    placeholder={constants.AUDIT_TRAIL.SEARCH_PLACEHOLDER} />
            </Row>

            {logsLoading ? <Flex style={{ height: "35vh", margin: "auto" }} flexDirection='row'> <Spinner /></Flex> :
                <Col gridGap="S400" width="100%">
                    {rowData.map((auditLog, index) => {
                        return getAuditCard(props, auditLog, index)
                    })}
                </Col>
            }
        </Col>
    );
}

export default withAlertSnackBar(AuditLogCards);