import {Col, Row} from "@amzn/stencil-react-components/layout";
import {Label} from "@amzn/stencil-react-components/text";
import {Input, InputFooter} from "@amzn/stencil-react-components/form";
import {
    COUNTRY_PHONE_CODE_MAPPING, CREATE_ORGANIZATION_PLACEHOLDERS, PHONE_NUMBER_LENGTH_MAPPING
} from "../../../../Utils/Constants";
import {validatePatterns} from "../../../../Utils/Validator";
import React from "react";
import {handleEmpty} from "../../../../Utils/Util";
import constants from "../../../../Utils/Constants";

export function ProvinceTab(props){
    return (
        <Col gridGap='S100' {...props.inputFieldBreakPoints}>
            <Label id='stateLabel'>Province/Governorate : *</Label>
            <Col>
                <Input id="state" placeholder={CREATE_ORGANIZATION_PLACEHOLDERS[props.formData.country]['state']} dataTestId="state"
                       name="state" value={handleEmpty(props.formData.state)} onChange={props.handleText}/>
                {props.isFormSubmitted && !props.formData.state &&
                    <InputFooter error>Province/Governorate is missing</InputFooter>
                }
                {props.isFormSubmitted && props.formData.state && !validatePatterns(constants.PATTERN.STATE_PATTERNS[props.formData.country],props.formData.state) &&
                    <InputFooter error>Invalid Province/Governorate format</InputFooter>
                }
            </Col>
        </Col>
    );
}

export function DistrictTab(props){
    return (
        <Col gridGap='S100' {...props.inputFieldBreakPoints}>
            <Label id='districtLabel'>Area/District : *</Label>
            <Col>
                <Input id="district" placeholder={CREATE_ORGANIZATION_PLACEHOLDERS[props.formData.country]['district']} dataTestId="district"
                       name="district" value={handleEmpty(props.formData.district)} onChange={props.handleText}/>
                {props.isFormSubmitted && !props.formData.district &&
                    <InputFooter error>Area/District is missing</InputFooter>
                }
                {props.isFormSubmitted && props.formData.district && !validatePatterns(constants.PATTERN.DISTRICT_PATTERNS[props.formData.country], props.formData.district) &&
                    <InputFooter error>Invalid Area/District format</InputFooter>
                }
            </Col>
        </Col>
    );
}

export function CityTab(props){
    return (
        <Col gridGap='S100' {...props.inputFieldBreakPoints}>
            <Label id='OrganizationCityLabel'>City : *</Label>
            <Col>
                <Input id="OrganizationCity" placeholder={CREATE_ORGANIZATION_PLACEHOLDERS[props.formData.country]['OrganizationCity']}
                       dataTestId="OrganizationCity" name="OrganizationCity" value={handleEmpty(props.formData.OrganizationCity)} onChange={props.handleText}/>
                {props.isFormSubmitted && !props.formData.OrganizationCity &&
                    <InputFooter error>City is missing</InputFooter>
                }
                {props.isFormSubmitted && props.formData.OrganizationCity && !validatePatterns(constants.PATTERN.ADDRESS_PATTERNS[props.formData.country],props.formData.OrganizationCity) &&
                    <InputFooter error>Invalid city format</InputFooter>
                }
            </Col>
        </Col>
    );
}

export function AddressTab(props){
    return (
        <Col gridGap='S100' {...props.inputFieldBreakPoints}>
            <Label id='OrganizationStreetLabel'>Address : *</Label>
            <Col>
                <Input id="OrganizationStreet" dataTestId="OrganizationStreet"
                       placeholder={CREATE_ORGANIZATION_PLACEHOLDERS[props.formData.country]['OrganizationStreet']}
                       name="OrganizationStreet" value={handleEmpty(props.formData.OrganizationStreet)} onChange={props.handleText}/>
                {props.isFormSubmitted && !props.formData.OrganizationStreet &&
                    <InputFooter error>Address is missing</InputFooter>
                }
                {props.isFormSubmitted && props.formData.OrganizationStreet && !validatePatterns(constants.PATTERN.ADDRESS_PATTERNS[props.formData.country], props.formData.OrganizationStreet) &&
                    <InputFooter error>Invalid Address format</InputFooter>
                }
            </Col>
        </Col>
    );
}

export function PhoneTab(props){
    return (
        <Col gridGap='S100' {...props.inputFieldBreakPoints}>
            <Label id='OrganizationPhoneLabel'>Phone: *</Label>
            <Col>
                <Row gridGap={"S300"}>
                    <Input value = {COUNTRY_PHONE_CODE_MAPPING[props.formData.country]} readOnly={true} width ={"5rem"} />
                    <Input id="OrganizationPhone" placeholder={CREATE_ORGANIZATION_PLACEHOLDERS[props.formData.country]['OrganizationPhone']}
                           maxLength={PHONE_NUMBER_LENGTH_MAPPING[props.formData.country]}
                           dataTestId="OrganizationPhone" name="OrganizationPhone" value={handleEmpty(props.formData.OrganizationPhone)}
                           width={"100%"} onChange={props.handleNumberOnlyInput} />
                </Row>
                {props.isFormSubmitted && !props.formData.OrganizationPhone &&
                    <InputFooter error>Phone number is missing</InputFooter>
                }
                {props.isFormSubmitted && props.formData.OrganizationPhone && !validatePatterns(constants.PATTERN.PHONE_NUMBERS[props.formData.country],props.formData.OrganizationPhone) &&
                    <InputFooter error>Invalid Phone number format</InputFooter>
                }
            </Col>
        </Col>
    );
}
export function PostalCodeTab(props){
    return (
        <Col gridGap='S100' {...props.inputFieldBreakPoints}>
            <Label id='OrganizationPostalcodeLabel'>Postal Code : *</Label>
            <Col>
                <Input  id="pin-code-input" maxLength={5}
                        placeholder={CREATE_ORGANIZATION_PLACEHOLDERS[props.formData.country]['OrganizationPostalcode']}
                        dataTestId="OrganizationPostalcode" name="OrganizationPostalcode"
                        value={handleEmpty(props.formData.OrganizationPostalcode)}
                        onChange={props.handleNumberOnlyInput}/>
                {props.isFormSubmitted && !props.formData.OrganizationPostalcode &&
                    <InputFooter error>Postal Code is missing</InputFooter>
                }
                {props.isFormSubmitted && props.formData.OrganizationPostalcode && !validatePatterns(constants.PATTERN.POSTAL_CODES[props.formData.country],props.formData.OrganizationPostalcode) &&
                    <InputFooter error>Invalid Postal Code format</InputFooter>
                }
            </Col>
        </Col>
    );
}

export function MENAAddressTab(props) {
    return(
        <Col gridGap={"S300"}>
            <Row justifyContent={'space-between'} flexWrap={'wrap'} gridGap={'S300'}>
                <ProvinceTab {...props} />
                <DistrictTab {...props} />
            </Row>
            <Row justifyContent='space-between' flexWrap='wrap' gridGap='S300'>
                <CityTab {...props} />
                <AddressTab {...props} />
            </Row>
            <Row justifyContent='space-between' flexWrap='wrap' gridGap='S300'>
                <PostalCodeTab {...props} />
                <PhoneTab {...props} />
            </Row>
        </Col>
    );
}

export function UAEAddressTab(props){
    return (
        <Col gridGap={"S300"}>
            <Row justifyContent={'space-between'} flexWrap={'wrap'} gridGap={'S300'}>
                <DistrictTab {...props} />
                <CityTab {...props} />
            </Row>
            <Row justifyContent='space-between' flexWrap='wrap' gridGap='S300'>
                <AddressTab {...props} />
                <PhoneTab {...props} />
            </Row>
        </Col>
    );
}