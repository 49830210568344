import React, {useEffect} from 'react';
import { Col, Container, Row, View } from '@amzn/stencil-react-components/layout';
import constants from '../../Utils/Constants';
import { Text } from '@amzn/stencil-react-components/text';
import { SideNavItem, SideNavItems } from '@amzn/stencil-react-components/side-nav';
import { Checkbox, InputWrapper, LabelPosition } from '@amzn/stencil-react-components/form';

const expandableFilterItems = (item, onChangeCallback) => {
    const checkChild = (e) => {
        e.target?.querySelector("label")?.click();
    }

    return (
        <SideNavItem key={"sideNavItem" + item.id} display="flex" onClick={(e) => checkChild(e)}>
            <InputWrapper
                id={"navItem" + item.id}
                labelText={item.name}
                labelPosition={LabelPosition.Trailing}>
                {inputProps => <Checkbox checked={item.is_selected} onChange={e => onChangeCallback(item)} {...inputProps} />}
            </InputWrapper>
        </SideNavItem>
    );
}

function VendorKamFilterDialog(props) {
    const [types, setTypes] = React.useState([{ "id": "Manpower", "name": "Manpower", is_selected: false }, { "id": "BGV", "name": "BGV", is_selected: false }, { "id": "Biometric", "name": "Biometric", is_selected: false }]);
    const [countries, setCountries] = React.useState(constants.FILTERS.ORG_COUNTRIES);

    const updateFilters = () => {
        const typesL = types.filter((item) => item.is_selected).map((item) => item.name);
        const countryList = countries.filter((item) => item.is_selected).map((item) => item.name);
        props.onChange({ filtersApplied: true, filters: { resetAppliedFilter: false, types: typesL,countries: countryList } });
    }

    useEffect(()=>{
        updateFilters();
        // eslint-disable-next-line
    },[types,countries]);

    const organizationTypeChangeHandler = (value) => {
        let typesL = [...types];
        typesL.forEach((lob) => lob.is_selected = (lob.name === (value && value.name) ? !lob.is_selected : lob.is_selected));
        setTypes(typesL);
    };

    const countryChangeHandler = (value) => {
        let countryNameList = JSON.parse(JSON.stringify(countries));
        countryNameList.forEach((country) => country.is_selected = (country.name === (value && value.name) ? !country.is_selected : country.is_selected));
        setCountries(countryNameList);
    };

    const handleClear = () => {
        let externalFilters = {}
        externalFilters.type = '';
        externalFilters.sites = '';
        // props.handleApply(externalFilters);
        setTypes(JSON.parse(JSON.stringify(constants.FILTERS.ORG_TYPES)));
        setCountries(JSON.parse(JSON.stringify(constants.FILTERS.ORG_COUNTRIES)));
        props.onChange({ filtersApplied: true, filters: { resetAppliedFilter: false, types: [], sites: [], contractStatus: [],countries: [] } })
    };

    return (
        <Container height="100%" style={{ padding: "0", margin: "0" }}>
            {props.mode === constants.DEVICE.DESKTOP &&
                <Col style={{ boxShadow: "inset 0px -1px 0px #8B96A3" }}>
                    <Row justifyContent="space-between" alignItems='baseline'>
                        <View padding={'1em'}><Text dataTestId="textFilter" fontSize="T300" fontWeight={'bold'}>Filter</Text></View>
                        <View padding={'1em'}><Text id="textReset" color='primary70' fontSize="T200" onClick={handleClear} style={{ cursor: 'pointer' }}>Reset</Text></View>
                    </Row>
                </Col>}
            <Col>
                <View style={{ boxShadow: "inset 0px -1px 0px #8B96A3" }} className={'orgFiltersSidenav'}>
                    <SideNavItems id="types" isExpanded={true} label="Types" dataTestId='reqFiltersSidenav'>
                        {types.map((item) => expandableFilterItems(item, organizationTypeChangeHandler))}
                    </SideNavItems>
                </View>
                <View style={{ boxShadow: "inset 0px -1px 0px #8B96A3" }} className={'orgFiltersSidenav'}>
                    <SideNavItems id="country-filter" isExpanded={true} label="Country" dataTestId='kamRequestCountryFilter'>
                        {countries.map((item) => expandableFilterItems(item, countryChangeHandler))}
                    </SideNavItems>
                </View>
            </Col>
        </Container>
    )
}

export default VendorKamFilterDialog
