import React from 'react'
import VendorKamRequestGrid from './VendorKamRequestGrid';
import constants from "../../Utils/Constants";
import VendorKamFilterDialog from "./VendorKamFilterDialog";

import { Col, Container, Row, Spacer, View } from "@amzn/stencil-react-components/layout";
import { useBreakpoints } from '@amzn/stencil-react-components/responsive';
import { Text } from '@amzn/stencil-react-components/text';
import { FlyoutContent, FlyoutPosition, WithFlyout } from '@amzn/stencil-react-components/flyout';
import { Button, ButtonIconPosition, ButtonSize, ButtonVariant } from '@amzn/stencil-react-components/button';
import { IconFilter, IconSize } from '@amzn/stencil-react-components/icons';
import { InputWrapper } from '@amzn/stencil-react-components/form';
import { SearchField } from '@amzn/stencil-react-components/search';

function VendorKamRequests() {

    const [paginationConfig, setPaginationConfig] = React.useState({ pageNo: 1, pageSize: 25, externalFilters: { type: '', sites: '' } });
    const [searchConfig, setSearchConfig] = React.useState({ searchValue: '' });
    const [filtersConfig, setFiltersConfig] = React.useState({ filtersApplied: false, filters: { resetAppliedFilter: false, types: [], sites: [], contractStatus: [],countries: [] } });

    const { matches: deviceResolution } = useBreakpoints();

    const handleSearchValueChange = (value) => {
        let searchConfigL = { ...searchConfig };
        searchConfigL.searchValue = value;
        let paginationConfigL = { ...paginationConfig };
        paginationConfigL.searchValue = value;
        paginationConfigL.searchColumn = searchConfig.searchColumn ? 'name' : '';
        paginationConfigL.searchByID = searchConfig.searchByID;
        // if(e.keyCode === 13){
        //     e.preventDefault();
        // }else{
        setSearchConfig(searchConfigL);
        setPaginationConfig(paginationConfigL);
        // }
    }

    const handleSearchClick = () => {
        let paginationConfigL = { ...paginationConfig };
        paginationConfigL.pageNo = 1;
        paginationConfigL.searchValue = searchConfig.searchValue;
        paginationConfigL.searchByID = searchConfig.searchByID;
        paginationConfigL.searchColumn = searchConfig.searchColumn ? 'name' : '';
        setPaginationConfig(paginationConfigL);
    }

    const updateFilters = (filters) => {
        setFiltersConfig(filters);
        setPaginationConfig({ pageNo: 1, pageSize: 25, externalFilters: { type: '', sites: '' } });
    }

    const requestFilter = (mode) => {
        return <VendorKamFilterDialog
            mode={mode}
            onChange={updateFilters}
        />
    }

    const filtersMobile = ({ close }) => (
        <FlyoutContent
            titleText="Filter"
            maxWidth={'100vw'}
            style={{ height: '100% !important' }}
            onCloseButtonClick={() => close()}
            buttons={[
                <Button
                    variant={ButtonVariant.Secondary}
                    size={ButtonSize.Small}
                    onClick={() => { close() }}
                >
                    Reset
                </Button>,
                <Button
                    variant={ButtonVariant.Primary}
                    size={ButtonSize.Small}
                    onClick={() => { close() }}
                >
                    Apply
                </Button>
            ]}
            id="filtersModal"
        >
            <Col>
                <Spacer width={'93vw'}></Spacer>
                {requestFilter(constants.DEVICE.MOBILE)}
            </Col>
        </FlyoutContent>
    );

    const requestSearch = (mode) => {
        return (
            <InputWrapper dataTestId="txtSearchValue" id="Vendor-Site-box" labelText=''>
                {inputProps => (
                    <SearchField
                        placeholder="Search"
                        width={"100%"}
                        shouldShowListOnNoResults={false}
                        query={searchConfig.searchValue ? searchConfig.searchValue : ""}
                        onChange={handleSearchValueChange}
                        onKeyPress={event => {
                            if (event.key === 'Enter') {
                                handleSearchClick();
                            }
                        }}
                        {...inputProps}
                    />)
                }
            </InputWrapper>
        )
    }

    return (
        <Container height="100%" isFullWidth={true}>
            <Row>
                {(deviceResolution.l || deviceResolution.xl) &&
                    <View width="18%" style={{ position: 'fixed', left: 0, overflowY: 'auto', height: 'calc(100vh - 56px)', borderRight: '1px solid rgb(86,97,108)' }}>
                        {requestFilter(constants.DEVICE.DESKTOP)}
                    </View>
                }
                <View width={(deviceResolution.l || deviceResolution.xl) ? '82%' : '100%'} style={(deviceResolution.l || deviceResolution.xl) ? { marginLeft: '18%' } : {}}>
                    <Col gridGap={`S300`} className={(deviceResolution.m || deviceResolution.s) ? `` : ''} padding={'1em'}>
                        <Row justifyContent="space-between">
                            <View>
                                <Text fontSize="T500" fontWeight="bold">{constants.VENDORKAMREQUESTS.TITLE}</Text>
                            </View>
                            <Row gridGap="S300">
                                {(deviceResolution.m || deviceResolution.s) &&
                                    <>
                                        <WithFlyout renderFlyout={filtersMobile} isScrollable={false} flyoutPosition={FlyoutPosition.Leading} aria-labelledby="custom-flyout-title">
                                            {({ open }) => (
                                                <>
                                                    <Button
                                                        aria-describedby="sroDialog"
                                                        onClick={open}
                                                        variant={ButtonVariant.Tertiary}
                                                        size={ButtonSize.Small}
                                                        icon={<IconFilter aria-hidden={true} color="primary70" size={IconSize.Small} />}
                                                        iconPosition={ButtonIconPosition.Trailing}
                                                        id="btnOpenMobileFilters"
                                                        dataTestId="btnOpenMobileFilters"
                                                    >
                                                    </Button>
                                                    {/* <Button icon={<IconPlus />}
                                size={ButtonSize.Small}
                                variant={ButtonVariant.Primary}
                                onClick={createOrganization}
                                id="create-request-button-mobile">Request</Button> */}

                                                </>
                                            )}
                                        </WithFlyout>
                                    </>
                                }
                                {(deviceResolution.l || deviceResolution.xl) &&
                                    <>{requestSearch(constants.DEVICE.DESKTOP)}</>
                                }
                            </Row>
                        </Row>
                        {(deviceResolution.m || deviceResolution.s) &&
                            <View>{requestSearch(constants.DEVICE.MOBILE)}</View>
                        }
                    </Col>
                    <Col>
                        <VendorKamRequestGrid View='page' config={paginationConfig} filtersConfig={filtersConfig} searchConfig={searchConfig} />
                    </Col>
                </View>
            </Row>
        </Container>
    );
}

export default VendorKamRequests
