import React, { useEffect } from "react";
// import { OrganizationServices } from "../../Services/organizations";
import { useNavigate } from "react-router-dom";
import constants from "../../Utils/Constants";
import { getValidity } from "../../Utils/DateUtil";
import { customSortVendorKAM } from "../../Utils/customSort";
// import UnauthorizedAccessDialog from "../PageNotFound/UnauthorizedAccessDialog";
import { Col, View } from "@amzn/stencil-react-components/layout";
import { Table } from '@amzn/stencil-react-components/table';
import { Pagination } from '@amzn/stencil-react-components/pagination';
import { Label } from '@amzn/stencil-react-components/text';
import { Select } from '@amzn/stencil-react-components/form';
import { useSort, SortDirection } from '@amzn/stencil-react-components/table';
import { useGetVendorFilters } from "../../ReactQuery/hooks/useGetVendorFilters";
import { defaultCardRenderer } from '@amzn/stencil-react-components/table';
import {getCardColor} from "../../Utils/Util";

function OrganizationsGrid(props, ref) {
  const [rowData, setRowData] = React.useState([]);
  const [globalRows, setGlobalRows] = React.useState([]);
  const [paginationConfig, setPaginationConfig] = React.useState(props.config);
  // const [showUnauthorizedDialog, setShowUnauthorizedDialog] = React.useState(false);

  const { data: queryVendors, isFetching: vendorLoading } = useGetVendorFilters(`/orgs/filter`, '');

  const navigate = useNavigate();

  useEffect(() => {
    if (!vendorLoading && queryVendors) {
      getData();
    } else {
      // if (!vendorLoading && errorVendors) setShowUnauthorizedDialog(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryVendors, vendorLoading]);

  useEffect(() => {
    operateOnRows(props.filtersConfig.filters, props.searchConfig.searchValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.searchConfig.searchValue])

  useEffect(() => {
    operateOnRows(props.filtersConfig.filters, props.searchConfig.searchValue)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(props.filtersConfig)]);

  const tableColumns = [
    {
      // cellComponent: (prop) => <Text>{prop.data.toUpperCase()}</Text>,
      header: 'Name',
      sortId: 'name',
      accessor: 'name'
    },
    {
      // cellComponent: (prop) => <Text>{prop.data.toUpperCase()}</Text>,
      accessor: 'type',
      header: 'Type',
      sortId: 'type'
    },
    {
      // cellComponent: (prop) => <Text>{prop.data.toUpperCase()}</Text>,
      accessor: 'contractStatus',
      header: 'Contract Status',
      sortId: 'contractStatus'
    },
    {
      accessor: "contractValidity",
      header: "Validity (days to expire)",
      sortId: "contractValidity"
    },
    {
      // cellComponent: (prop) => <Text>{prop.data.toUpperCase()}</Text>,
      accessor: "clrcStatus",
      sortId: "clrcStatus",
      header: "CLRC Status"
    },
    {
      accessor: "clrcValidity",
      sortId: "clrcValidity",
      header: "Validity (days to expire)"
    },
    {
      accessor: "phone",
      sortId: "phone",
      header: "Phone"
    },
    {
      // cellComponent: (prop) => <Text>{prop.data.toUpperCase()}</Text>,
      accessor: "email",
      sortId: "email",
      header: "Email"
    }
  ];

  const { activeSortId, sortDirection, onSort } = useSort({
    defaultSortDirection: SortDirection.Ascending,
    defaultActiveSortId: 'name'
  });

  const getData = async () => {
    let rows = queryVendors && queryVendors.data && queryVendors.data.vendors;
    let rowsData = [];
    if (rows) {
      rows.forEach((row) => {
        rowsData.push({
          name: row.vendorKamName,
          type: row.type,
          contractStatus: getValidity(row.contractValidity) > 0 ? "Active" : "Expired",
          contractValidity: getValidity(row.contractValidity),
          clrcStatus: getValidity(row.clrcValidity) > 0 ? "Active" : "Expired",
          clrcValidity: getValidity(row.clrcValidity),
          phone: row.phone,
          email: row.correspondenceEmail,
          id: row.vendorKamId,
          sites: row.sites,
          status: constants.ORGANIZATIONS.VENDOR_STATUS[row.vendorStatus],
          statusPreferenceCode: constants.STATUS_PREFERENCE_CODES[row.vendorStatus],
          country: row.country
        });
      });
    }
    setRowData(customSortVendorKAM(rowsData));
    setGlobalRows(rowsData);

    // setRowData([]);
    // setGlobalRows([]);
  };

  const operateSearch = (vendors, searchValue) => {
    if (searchValue) {
      if (searchValue.length > 0) {
        return vendors.filter(arr => arr.name.toLowerCase().includes(searchValue.toLowerCase()) || arr.email.toLowerCase().includes(searchValue.toLowerCase()));
      }
    }
    return vendors
  }

  const operateOnRows = (filters, searchValue) => {
    let rowsData = [...customSortVendorKAM(globalRows)];
    if (filters?.types?.length) {
      rowsData = rowsData.filter((item) => filters.types.includes(item.type))
    }
    if (filters?.contractStatus?.length) {
      rowsData = rowsData.filter((item) => filters.contractStatus.includes(item.contractStatus));
    }
    if (filters?.countries?.length) {
      rowsData = rowsData.filter((item) => filters.countries.includes(item.country));
    }
    if (filters?.sites?.length) {
      rowsData = rowsData.filter((item) => {
        let includeValues = filters.sites.filter((site) => item.sites.includes(site));
        if (includeValues.length) return true;
        return false;
      })
    }
    rowsData = operateSearch(rowsData, searchValue);
    handlePage(1);
    setRowData(rowsData)
  }

  const handlePage = (page) => {
    let paginationConfigL = { ...paginationConfig };
    paginationConfigL.pageNo = page;
    setPaginationConfig(paginationConfigL);
  };

  const handlePageSize = (pageSize) => {
    let paginationConfigL = { ...paginationConfig };
    paginationConfigL.pageNo = 1;
    paginationConfigL.pageSize = pageSize;
    setPaginationConfig(paginationConfigL);
  };

  const getRowAttributes = ({ data, index }) => ({
    onClick: ({ data, index }) => {
      navigate(`/${constants.ORGANIZATIONS.ROUTE.VIEW}/${data.id}`); },
    css:{
      backgroundColor:getCardColor(data.status)
    },
    "data-testid": 'item' + index
  });

  const compare = (a, b) => {
    // This requires the sort IDs to be the same as the property
    // names in the data object. If they aren't, some additional
    // logic would be required to extract the data for the column.
    const dataA = a[activeSortId];
    const dataB = b[activeSortId];

    return dataA < dataB ? -1 : 1;
  };

  const sortedData =
    sortDirection === SortDirection.Descending
      ? rowData.sort(compare).reverse()
      : rowData.sort(compare);

  const renderCardForRow = defaultCardRenderer({ columns: tableColumns });

  return (
    <Col gridGap="S200" style={{ paddingLeft: '1em', paddingRight: '1em' }}>
      <Table
        aria-labelledby="table-label"
        columns={tableColumns}
        data={sortedData.slice((paginationConfig.pageNo - 1) * paginationConfig.pageSize, paginationConfig.pageNo * paginationConfig.pageSize)}
        onSort={onSort}
        activeSortId={activeSortId}
        sortDirection={sortDirection}
        renderCardForRow={renderCardForRow}
        maxHeight={450}
        hasStickyHeader={true}
        getRowAttributes={getRowAttributes}
      />
      <Col gridGap="S200" margin={{ top: 'S200' }}>
        <View style={{ display: 'flex' }}>
          <View flex={3}>
            <Pagination
              aria-labelledby="table-pagination"
              numberOfPages={Math.ceil(rowData.length / paginationConfig.pageSize)}
              onPageSelect={page => {
                handlePage(page);
              }}
              selectedPage={paginationConfig.pageNo}
            />
          </View>
          <View flex={1} display="flex" alignItems='center'
            justifyContent="center">
            <View flex={2} style={{ textAlign: 'right', paddingRight: '10px' }}>
              <Label htmlFor="rows-per-page" id="rows-per-page-label">
                Rows per page
              </Label>
            </View>
            <View style={{ paddingRight: '10px' }}>
              <Select
                options={[10, 25, 50, 75, 100]}
                id="rows-per-page"
                dataTestId="rows-per-page"
                labelId="rows-per-page-label"
                onChange={handlePageSize}
                value={paginationConfig.pageSize}
              />
            </View>
          </View>
        </View>
      </Col>
      {/* {showUnauthorizedDialog && (
        <UnauthorizedAccessDialog
          handleCancel={() => {
            setShowUnauthorizedDialog(false);
          }}
          snackbarShowMessage={props.snackbarShowMessage}
        />
      )} */}
    </Col>
  )
}

export default OrganizationsGrid;
