import {useQuery} from "react-query";
import Constants from "../../Utils/Constants";
import { OrganizationServices } from "../../Services/organizations";

export const useGetAllKamRequest= (url, body) =>{
    return useQuery([Constants.REACT_QUERY.QUERY_KEY.GET_ALL_KAM_REQUESTS],()=> OrganizationServices.getTotalKamRequests(url, body),{
        staleTime:1000,
        enabled:body!==null,
        refetchOnMount:true
    })
}

export const useGetKamsByParentVendor = (body) => {
    return useQuery([Constants.REACT_QUERY.QUERY_KEY.GET_KAMS_BY_PARENT_VENDOR,body],()=>OrganizationServices.getKamsByParentVendor(body),{
        staleTime:1000*60*2,
        enabled:body!==null,
        refetchOnMount:true
    });
}