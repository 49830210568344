export function customSortVendorKAM(inputObjectArray){
    inputObjectArray.sort((objectFirst,objectSecond) => {
        if(objectFirst.statusPreferenceCode > objectSecond.statusPreferenceCode)
            return 1;
        else return -1;
    });
    return inputObjectArray;
}

export function customSortKAMRequest(inputObjectArray){
    inputObjectArray.sort((objectFirst,objectSecond) => {
        if(objectFirst.statusPreferenceCode > objectSecond.statusPreferenceCode)
            return 1;
        else return -1;
    });
    return inputObjectArray;
}