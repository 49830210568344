import {useQuery} from "react-query";
import Constants from "../../Utils/Constants";
import {OrganizationServices} from "../../Services/organizations";

export const useGetAllAuditTrailLogs= (body) =>{
    return useQuery([Constants.REACT_QUERY.QUERY_KEY.GET_ALL_AUDIT_LOGS, body],()=> OrganizationServices.getAllAuditLogs( body),{
        enabled:body!==null,
        refetchOnWindowFocus:false,
        refetchOnMount:true
    })
}