import {
    AP_STATE_DISTRICT_MAPPING,
    AP_STATES,
    AP_VENDOR_ID,
    COUNTRY,
    COUNTRY_PHONE_CODE_MAPPING,
    FEATURE_FLAGS,
    VENDOR_STATUS
} from "./Constants";

export function getSelectedItems(array) {
    return array.filter(item => item.is_selected);
}

export const sentenceCase = (str = "") => {
    str = str.toLowerCase();
    let converted = str.charAt(0).toUpperCase() + str.slice(1);
    return converted;
}

export function getDistrictMapping(){
    return Object.fromEntries(Object.entries(AP_STATE_DISTRICT_MAPPING).map(([state, districts]) => [state, districts.map((district) => ({
        id: district,
        is_selected: false,
        name: district
    }))]));
}

export function getStates(){
    return AP_STATES.map((state) => ({id: state, is_selected: false, name: state}));
}

export function parentVendorFlag(){
    if(JSON.parse(localStorage.getItem(FEATURE_FLAGS.PARENT_VENDORS)))
        return isAdminUser();
    return false;
}

export function isAdminUser(){
    return JSON.parse(localStorage.getItem('isAdmin'));
}

export function getVendorNames(){
    let vendorNameL = [];
    Object.entries(AP_VENDOR_ID).forEach((vendor)=> {
        vendorNameL.push({
            id: vendor[0],
            is_selected:false,
            name: vendor[0],
            vendorId: vendor[1]
        });
    });
    vendorNameL.sort((a,b) => ( (a.name > b.name) ? 1 : (a.name < b.name) ? -1 : 0));
    return vendorNameL;
}

export function getCardColor(status){
    if([VENDOR_STATUS.ACTIVE,VENDOR_STATUS.OPEN,VENDOR_STATUS.ACCEPTED].includes(status))
        return "#ffffff";
    return "rgba(232, 234, 238, 1)";
}

export function parentVendorFeatureFlag(){
    return JSON.parse(localStorage.getItem(FEATURE_FLAGS.PARENT_VENDORS));
}

export function handleEmpty(value){
    return value ? value : "";
}

export function getPlaceholder(placeholderMap, country,fieldName) {
    return placeholderMap[country ? country : COUNTRY.India][fieldName];
}

export function withCountryCode(country,phonenumber){
    return [COUNTRY_PHONE_CODE_MAPPING[country],phonenumber].join(" ");
}

export function isMenaCountry(country){
    return [COUNTRY.United_Arab_Emirates,COUNTRY.Egypt,COUNTRY.Saudi_Arabia].includes(country);
}