import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { filterVendors, searchVendors, sortVendor } from "../../Utils/GridOperations";
import constants from "../../Utils/Constants";
import { customSortKAMRequest } from "../../Utils/customSort";
import { Col, View } from "@amzn/stencil-react-components/layout";
import { defaultCardRenderer, SortDirection, Table, useSort } from "@amzn/stencil-react-components/table";
import { Pagination } from "@amzn/stencil-react-components/pagination";
import { Label } from "@amzn/stencil-react-components/text";
import { Select } from "@amzn/stencil-react-components/form";
import { useGetAllKamRequest } from "../../ReactQuery/hooks/useGetAllKamRequest";
import {getCardColor} from "../../Utils/Util";

function VendorKamRequestGrid(props, ref) {
    const [rowData, setRowData] = React.useState([]);
    const [globalRows, setGlobalRows] = React.useState([]);
    const [paginationConfig, setPaginationConfig] = React.useState(props.config);
    const { data: queryVendors, error: errorVendors, isFetching: vendorLoading } = useGetAllKamRequest(`/orgs/getAllKamRequests`, '');

    const navigate = useNavigate();

    const tableHeader = [
        {
            header: 'Vendor ID',
            sortId: 'vendorId',
            accessor: 'vendorId'
        },
        {
            header: 'Vendor KAM Name',
            sortId: 'vendorKamName',
            accessor: 'vendorKamName'
        },
        {
            header: 'Requester',
            sortId: 'requester',
            accessor: 'requester'
        },
        {
            header: 'Request Type',
            sortId: 'requestType',
            accessor: 'requestType'
        },
        {
            header: 'Requested Date',
            sortId: 'requestedDate',
            accessor: 'requestedDate'
        },
        {
            header: 'Request Status',
            sortId: 'requestStatus',
            accessor: 'requestStatus'
        }
    ];

    useEffect(() => {

        if (queryVendors && !errorVendors && !vendorLoading) {
            
            let rows = queryVendors && queryVendors.data && queryVendors.data.vendorKamRequests;
            let rowsData = [];
            if (rows) {
                rows.forEach((row) => {
                    rowsData.push({
                        kamRequestId: row.kamRequestId,
                        vendorKamId: row.vendorKamId,
                        vendorId: row.vendorId,
                        vendorName: row.vendorName,
                        vendorKamName: row.vendorKamName,
                        name: row.vendorKamName,
                        type: row.type,
                        requester: row.requester,
                        requestType: row.requestType,
                        requestStatus: row.requestStatus,
                        requestedDate: row.requestedDate,
                        // contractStatus: getValidity(row.contractValidity) > 0 ? "Active" : "Expired",
                        // contractValidity: getValidity(row.contractValidity),
                        // clrcStatus: getValidity(row.clrcValidity) > 0 ? "Active" : "Expired",
                        // clrcValidity: getValidity(row.clrcValidity),
                        phone: row.phone,
                        username: row.username,
                        id: row.kamRequestId,
                        status: row.requestStatus,
                        statusPreferenceCode: constants.STATUS_PREFERENCE_CODES[row.requestStatus],
                        country: row.country
                        //sites : row.sites
                    });
                });
            }
            setRowData(customSortKAMRequest(rowsData));
            setGlobalRows(rowsData);
        } else if (errorVendors) {
            setRowData([]);
            setGlobalRows([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryVendors]);


    // useEffect(() => {

    //     if (paginationConfig && paginationConfig.pageSize) {
    //         operateOnRows();
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [paginationConfig, globalRows]);

    useEffect(() => {
        const handleSearchClick = (config) => {
            let paginationConfigL = { ...paginationConfig };
            paginationConfigL.pageNo = 1;
            paginationConfigL.searchValue = config.searchValue;
            paginationConfigL.searchByID = config.searchByID;
            paginationConfigL.searchColumn = config.searchValue ? "vendorKamName" : "";
            setPaginationConfig(paginationConfigL);
        };

        if (
            (props.config.searchValue && props.config.searchValue !== "") ||
            props.config.searchValue === ""
        ) {
            handleSearchClick(props.config);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.config.searchValue, props.config.searchByID]);

    useEffect(()=>{
        if(props.searchConfig.searchValue || props.searchConfig.searchValue === ""){
            let paginationConfigL = { ...paginationConfig };
            paginationConfigL.pageNo = 1;
            paginationConfigL.searchValue = props.searchConfig.searchValue;
            paginationConfigL.searchByID = props.config.searchByID;
            paginationConfigL.searchColumn = props.searchConfig.searchValue ? "vendorKamName" : "";
            setPaginationConfig(paginationConfigL);
            operateOnRows();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[JSON.stringify(props.searchConfig)])

    useEffect(() => {
        handlePage(1);
        operateOnRows()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(props.filtersConfig)]);

    const operateOnRows = () => {
        let rowsData = [...globalRows];
        let sitesConfig = paginationConfig.externalFilters ? paginationConfig.externalFilters.sites : "";
        sitesConfig = sitesConfig ? sitesConfig : "";
        let sites = [];
        if (sitesConfig) {
            sites = sitesConfig.split(",")
        }
        if(props.searchConfig.searchValue === ""){
            rowsData = [...globalRows];
        } else {
            rowsData = searchVendors(rowsData, props.searchConfig.searchValue)
        }
        let type = paginationConfig.externalFilters ? paginationConfig.externalFilters.type : "";
        type = type ? type : "";
        if (props.filtersConfig?.filters?.types?.length) {
            rowsData = rowsData.filter((item) => props.filtersConfig.filters.types.includes(item.type))
        }
        if (props.filtersConfig?.filters?.countries?.length) {
            rowsData = rowsData.filter((item) => props.filtersConfig.filters.countries.includes(item.country))
        }
        rowsData = sortVendor(rowsData, paginationConfig.sortColumn, paginationConfig.sortOrder);
        rowsData = filterVendors(rowsData, type, sites)
        // rowsData = paginate(rowsData, paginationConfig.pageSize, paginationConfig.pageNo)
        setRowData(rowsData);
    }

    const handlePage = (page) => {
        let paginationConfigL = { ...paginationConfig };
        paginationConfigL.pageNo = page;
        setPaginationConfig(paginationConfigL);
    };

    const handlePageSize = (pageSize) => {
        let paginationConfigL = { ...paginationConfig };
        paginationConfigL.pageNo = 1;
        paginationConfigL.pageSize = pageSize;
        setPaginationConfig(paginationConfigL);
    };

    const getRowAttributes = ({ data }) => ({
        onClick: ({ data, index }) => { navigate(`/${constants.VENDORKAMREQUESTS.ROUTE.VIEW}/${data.vendorKamId}/${data.id}`); },
        css:{
            backgroundColor:getCardColor(data.status)
        },
    });

    const compare = (a, b) => {
        // This requires the sort IDs to be the same as the property
        // names in the data object. If they aren't, some additional
        // logic would be required to extract the data for the column.
        const dataA = a[activeSortId];
        const dataB = b[activeSortId];

        return dataA < dataB ? -1 : 1;
    };

    const { activeSortId, sortDirection, onSort } = useSort({
        defaultSortDirection: SortDirection.Ascending,
        defaultActiveSortId: 'vendorId'
    });

    const sortedData =
        sortDirection === SortDirection.Descending
            ? rowData.sort(compare).reverse()
            : rowData.sort(compare);

    const renderCardForRow = defaultCardRenderer({ columns: tableHeader });

    return (
        <Col gridGap="S200" style={{ paddingLeft: '1em', paddingRight: '1em' }}>
            <Table
                aria-labelledby="table-label"
                columns={tableHeader}
                data={sortedData.slice((paginationConfig.pageNo - 1) * paginationConfig.pageSize, paginationConfig.pageNo * paginationConfig.pageSize)}
                onSort={onSort}
                activeSortId={activeSortId}
                sortDirection={sortDirection}
                renderCardForRow={renderCardForRow}
                maxHeight={550}
                hasStickyHeader={true}
                getRowAttributes={getRowAttributes}
            />
            <Col gridGap="S200" margin={{ top: 'S200' }}>
                <View style={{ display: 'flex' }}>
                    <View flex={3}>
                        <Pagination
                            aria-labelledby="table-pagination"
                            numberOfPages={Math.ceil(sortedData.length / paginationConfig.pageSize)}
                            onPageSelect={page => {
                                handlePage(page);
                            }}
                            selectedPage={paginationConfig.pageNo}
                        />
                    </View>
                    <View flex={1} display="flex" alignItems='center'
                        justifyContent="center">
                        <View flex={2} style={{ textAlign: 'right', paddingRight: '10px' }}>
                            <Label htmlFor="rows-per-page" id="rows-per-page-label">
                                Rows per page
                            </Label>
                        </View>
                        <View style={{ paddingRight: '10px' }}>
                            <Select
                                options={[10, 25, 50, 75, 100]}
                                id="rows-per-page"
                                labelId="rows-per-page-label"
                                onChange={handlePageSize}
                                value={paginationConfig.pageSize}
                            />
                        </View>
                    </View>
                </View>
            </Col>
            {/* {showUnauthorizedDialog && (
            <UnauthorizedAccessDialog
              handleCancel={() => {
                setShowUnauthorizedDialog(false);
              }}
              snackbarShowMessage={props.snackbarShowMessage}
            />
          )} */}
        </Col>
    )
}

export default VendorKamRequestGrid;