import {Row, View} from "@amzn/stencil-react-components/layout";
import {IconBin, IconDocument} from "@amzn/stencil-react-components/icons";
import {Text} from "@amzn/stencil-react-components/text";
import {Button, ButtonVariant} from "@amzn/stencil-react-components/button";
import React from "react";

export function FileIcon(props){
    return (
        <View style={{ width: '100%', display: 'flex', height: '3.5rem', alignItems: 'center' , backgroundColor : '#f1f4f7', padding : "0.75rem"}} >
            <View style={{ width: '18%' }}>  <IconDocument color = {"neutral70"}/></View>
            <View style={{ width: '70%' }} justifyContent={"flex-start"}>
                <Text fontSize='T300' fontWeight="regular" color="neutral90">{props.fileName}</Text>
            </View>
            <View style={{ width: '5%', cursor: 'pointer' }} data-testid='removeBtn'>
                <Button onClick={()=>{props.deleteFile()}} icon={<IconBin color = {"red70"}/>} variant={ButtonVariant.Tertiary} />
            </View>
        </View>
    );
}

export function FilePreviewIcon(props){
    return (
        <Row width={"100%"} >
            <Row style={{ alignItems: 'center' , backgroundColor : '#f1f4f7', padding : "0.75rem"}}
                 height={"7rem"} width={"15%"} gridGap={"S100"} justifyContent={"flex-start"}>
                <img id={"imagePreview"} style={{ objectFit: "contain" }}
                     src={props.src} alt={"Not Found"} height={props.height ? props.height : "100%"}
                     width={"100%"} />
            </Row>
            <Row width={"30%"} justifyContent={"flex-start"} gridGap={"S200"} alignItems={"center"} style={{backgroundColor : '#f1f4f7'}}>
                <IconDocument color = {"neutral70"}/>
                <Text fontSize='T300' fontWeight="regular" color="neutral90">{props.fileName}</Text>
            </Row>
        </Row>
    );
}