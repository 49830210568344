import React, { useEffect, useState } from 'react';
import { Col, Flex, Hr, Row, View } from '@amzn/stencil-react-components/layout';
import { useParams } from 'react-router-dom';
import { OrganizationServices } from '../../../Services/organizations';
import constants, {COUNTRY} from '../../../Utils/Constants';
import { getValidity } from "../../../Utils/DateUtil";
import { useBreakpoints } from '@amzn/stencil-react-components/responsive';
import { IconArrowLeft, IconBin, IconPencil } from '@amzn/stencil-react-components/icons';
import { Link, LinkIconPosition } from '@amzn/stencil-react-components/link';
import { useNavigate } from 'react-router-dom';
import { Text } from '@amzn/stencil-react-components/text';
import { Button, ButtonSize, ButtonVariant } from '@amzn/stencil-react-components/button';
import { WithModal, ModalContent } from '@amzn/stencil-react-components/modal';
import { TabBar, TabPanel, TabSwitcher, useTabs } from "@amzn/stencil-react-components/tabs";
import AuditLogCards from '../../common/AuditTrail/AuditLogCards';
import { Card } from '@amzn/stencil-react-components/card';
import {withAlertSnackBar} from "../../../HOComponents/AlertSnackBarHOC";
import {useQueryClient} from "react-query";
import Constants from "../../../Utils/Constants";
import {isMenaCountry, withCountryCode} from "../../../Utils/Util";

const propsPaddingMap = {
    s: { padding: { top: "2rem", left: "1rem", right: "1rem", bottom: "2rem" } },
    m: { padding: { top: "2rem", left: "1.5rem", right: "1.5rem", bottom: "2rem" } },
    l: { padding: { top: "2rem", left: "3rem", right: "3rem", bottom: "2rem" } },
    xl: { padding: { top: "2rem", left: "4rem", right: "4rem", bottom: "2rem" } }
}

const isComma = (field) => {
    return field || field !== 'N/A' ? ", " : "";
}

export function DefaultVendorKamAddressField(props){
    return (
        <Text fontSize="T200">Address: {props.formData.OrganizationStreet +
            isComma(props.formData.OrganizationStreet) +
            props.formData.OrganizationCity +
            isComma(props.formData.OrganizationCity) +
            props.formData.OrganizationDistrict +
            isComma(props.formData.OrganizationDistrict) +
            props.formData.OrganizationState +
            isComma(props.formData.OrganizationState) +
            props.formData.OrganizationCountry +
            isComma(props.formData.OrganizationCountry) +
            (props.formData.OrganizationPostalcode)}</Text>
    );
}

export function UAEVendorKamAddressField(props){
    return (
        <Text fontSize="T200">Address: {props.formData.OrganizationStreet +
            isComma(props.formData.OrganizationStreet) +
            props.formData.OrganizationDistrict +
            isComma(props.formData.OrganizationDistrict) +
            props.formData.OrganizationCity +
            isComma(props.formData.OrganizationCity) +
            props.formData.OrganizationCountry}</Text>
    );
}

export function VendorKamAddressField(props){
    if(props.formData.OrganizationCountry === COUNTRY.United_Arab_Emirates)
        return <UAEVendorKamAddressField {...props} />
    return <DefaultVendorKamAddressField {...props} />
}

function ViewVendor(props) {
    const params = useParams();
    const navigate = useNavigate();
    const { props: paddingProps, matches } = useBreakpoints({ propsMap: propsPaddingMap });
    const tabs = constants.TABS;
    const { tabBarProps, tabSwitcherProps } = useTabs({ tabs });
    const queryClient = useQueryClient();


    const [formData, setFormData] = useState({ OrganizationStreet: "", OrganizationCity: "", OrganizationState: "", OrganizationCountry: "", OrganizationPostalcode: "" });

    useEffect(() => {
        if (params.id) {
            getOrgDetailsById(params.id)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.id]);

    const getOrgDetailsById = async (id) => {
        await OrganizationServices.getOrganizationById(id)
            .then((res) => {
                let data = (res && res.data) || {};
                let formDataL = { ...formData };
                formDataL.OrganizationVendorKamId = data.vendorKamId ? data.vendorKamId : "";
                formDataL.OrganizationVendorId = data.vendorId ? data.vendorId : "";
                formDataL.OrganizationVendorName = data.vendorName ? data.vendorName : "";
                formDataL.OrganizationVendorKamStatus = data.vendorStatus ? constants.ORGANIZATIONS.VENDOR_STATUS[data.vendorStatus] : "";
                formDataL.OrganizationKamName = data.vendorKamName ? data.vendorKamName : "";
                formDataL.OrganizationStreet = data.street ? data.street : "";
                formDataL.OrganizationCity = data.city ? data.city : "";
                formDataL.OrganizationState = data.state ? data.state : "";
                formDataL.OrganizationDistrict = data.district ? data.district : "";
                formDataL.OrganizationCountry = data.country ? data.country : "";
                formDataL.OrganizationPostalcode = data.postalCode
                    ? data.postalCode
                    : "";
                formDataL.OrganizationPhone = data.phone ? data.phone : "";
                formDataL.OrganizationUsername = data.username ? data.username : "";
                formDataL.OrganizationCorrespondenceEmail = data.correspondenceEmail ? data.correspondenceEmail : "";
                formDataL.OrganizationType = data.type ? data.type : "";
                formDataL.OrganizationClrcCapacity = data.clrcCapacity
                    ? data.clrcCapacity
                    : "";
                formDataL.OrganizationClrcLocation = data.clrcLocation
                    ? data.clrcLocation
                    : "";
                formDataL.OrganizationContractLocation = data.contractLocation
                    ? data.contractLocation
                    : "";
                formDataL.OrganizationCreatedDate = data.createdDate
                    ? data.createdDate
                    : "";
                formDataL.OrganizationContractStatus =
                    getValidity(data.contractValidity) > 0 ? "Active" : "Expired";
                formDataL.OrganizationContractValidity = getValidity(
                    data.contractValidity
                );
                formDataL.OrganizationClrcValidity = getValidity(data.clrcValidity);
                formDataL.OrganizationClrcStatus =
                    getValidity(data.clrcValidity) > 0 ? "Active" : "Expired";
                formDataL.OrganizationSites =
                    data.sites && data.sites.length > 0
                        ? data.sites.map((o) => o.split(",")).join(", ")
                        : "-";
                setFormData(formDataL);
            }).catch(err=>{
                console.log(err)
            })
    };

    const editOrganization = () => {
        navigate(`/${constants.ORGANIZATIONS.ROUTE.EDIT}/${params.id}`);
    };


    /* istanbul ignore next line */
    const removeOrganization = async () => {
        let body = {
            vendorKamId: params.id,
            createdDate: formData.OrganizationCreatedDate,
            username: formData.OrganizationUsername,
            country: formData.OrganizationCountry
        };
        await OrganizationServices.removeOrganization(body).then((res) => {
                props.snackbarShowMessage(`${constants.CRUD_SUCCESS_ALERTS.DELETED}`);
            queryClient.invalidateQueries([Constants.REACT_QUERY.QUERY_KEY.GET_VENDORS]);
            window.setTimeout(() => {
                    navigate(-1);
                }, 2000);

            })
            .catch((error) => {
                props.snackbarShowMessage(
                    `${constants.ERROR.DELETION_ERROR} ${constants.ERROR.DELETE_ERROR}`,
                    "error",
                    "5000",
                    constants.ERROR.DELETE_ERROR
                )
            });
    };

    const renderModal = ({ close }) => (
        <ModalContent
            titleText="Deactivate Request"
            buttons={[
                <Button key={0} onClick={() => { removeOrganization(); close() }} variant={ButtonVariant.Secondary} id="btnHandleDelete" dataTestId="btnHndleDelete">
                    Deactivate
                </Button>,
                <Button key={1} onClick={close} variant={ButtonVariant.Primary} id="btnHandleClose" dataTestId="btnHandleClose">
                    Cancel
                </Button>
            ]}>
            <Text>Deactivating the vendor KAM will remove the references against the vendor KAM. Are you sure you want to deactivate this?</Text>
        </ModalContent>
    );

    const sentenceCase = (str = "") => {
        str = str.toLowerCase();
        let converted = str.charAt(0).toUpperCase() + str.slice(1);
        return converted;
    }

    function ClrcDetails(){
        return <Card border="0 !important">
            <Col gridGap="S100" width='100%'>
                <Text fontSize="T200" color="neutral90">{constants.FORM_DETAILS.CLRC_DETAILS}</Text>
                <Flex flexDirection={matches.s ? "column" : "row"} alignItems={'baseline'} justifyContent={matches.s ? "space-between" : matches.m ? "space-between" : "none"} gridGap={(matches.s || matches.m) ? "S200" : "S100"} >
                    <View flex={1} >
                        <Flex flexDirection='row' flexWrap="wrap" gridGap={(matches.s || matches.m) ? "S100" : "S200"}>
                            <Text fontSize="T200" fontWeight="bold" color="neutral90">CLRC Status: {formData.OrganizationClrcStatus}</Text>
                            <Text color="neutral30" fontWeight="bold">&#124;</Text>
                            <Text fontSize="T200" fontWeight="bold" color="neutral90">Validity (days to expire): {formData.OrganizationClrcValidity}</Text>
                            <Text color="neutral30" fontWeight="bold">&#124;</Text>
                            <Text fontSize="T200" fontWeight="bold" wordBreak="break-word" color="neutral90">CLRC Capacity: {formData.OrganizationClrcCapacity}</Text>
                            <Text color="neutral30" fontWeight="bold">&#124;</Text>
                            <Text fontSize="T200" fontWeight="bold" color="neutral90">CLRC Location: {formData.OrganizationClrcLocation}</Text>
                        </Flex>
                    </View>
                </Flex>
            </Col>
        </Card>;
    }

    return (
        <View>
            <Col gridGap="S300" {...paddingProps} >

                <Row display="flex">
                    <Link style={{ cursor: "pointer" }} icon={<IconArrowLeft />} iconPosition={LinkIconPosition.Leading} onClick={() => (navigate(`/${constants.ORGANIZATIONS.ROUTE.MAIN}`))} fontWeight="normal" textDecoration="none">Back to Kams list</Link>
                </Row>

                <Row display="flex" justifyContent="space-between" flexWrap="wrap">

                    <Col flexWrap="wrap" gridGap="S200">

                        <Row flexWrap="wrap" gridGap="S200" alignItems="flex-end">
                            <Text fontSize="T500">{formData.OrganizationVendorName + ','}</Text>
                            <Text fontSize="T500">{formData.OrganizationType}</Text>
                            <Text fontSize="T300" style={{ marginBottom: "0.2rem" }}>Status: {sentenceCase(formData.OrganizationVendorKamStatus)}</Text>
                        </Row>

                        <Row flexWrap="wrap" gridGap="S200" alignItems="flex-end">
                            <Text fontSize="T200">Vendor Id: {formData.OrganizationVendorId}</Text>
                            <Text color="neutral40">&#124;</Text>
                            <Text fontSize="T200">Vendor KAM Name: {formData.OrganizationKamName}</Text>
                            <Text color="neutral40">&#124;</Text>
                            <Text fontSize="T200">Phone : {withCountryCode(formData.OrganizationCountry,formData.OrganizationPhone)}</Text>
                        </Row>
                        <Row flexWrap="wrap" gridGap="S200" alignItems="flex-end">
                            <Text fontSize="T200">Username: {formData.OrganizationUsername}</Text>
                            <Text color="neutral40">&#124;</Text>
                            <Text fontSize="T200">Email: {formData.OrganizationCorrespondenceEmail}</Text>
                            
                        </Row>
                        <Row flexWrap="wrap" gridGap="S200" alignItems="flex-end">
                            <VendorKamAddressField formData = {formData} />
                        </Row>
                        <Row flexWrap="wrap" gridGap="S200" alignItems="flex-end">
                            <Text fontSize="T200">Sites: {formData.OrganizationSites}</Text>
                        </Row>
                    </Col>
                    {matches.xl || matches.l
                        ?
                        <Col>
                            <Row gridGap="S200" flexWrap="wrap" style={{ marginTop: "0.8rem" }}>
                                {formData.OrganizationVendorKamStatus !== 'INACTIVE' && <View flex={0.5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <WithModal renderModal={(close) => renderModal(close)} shouldCloseOnClickOutside={false}>
                                        {({ open }) => (<>
                                            <Button id="btnEditRequest" dataTestId="btnDlt" icon={<IconBin />} size={ButtonSize.Small} variant={ButtonVariant.Secondary}
                                                onClick={open} >{constants.BUTTON.DELETE}</Button>
                                            <span hidden id="sroDialog">has pop-up dialog</span>
                                        </>)}
                                    </WithModal>

                                    <Button style={{ marginLeft: '10px' }} id="btnEditRequest" dataTestId="btnEditRequest" icon={<IconPencil />} size={ButtonSize.Small} variant={ButtonVariant.Primary}
                                        onClick={editOrganization} >{constants.BUTTON.EDIT}</Button>

                                </View>}
                            </Row>
                        </Col>
                        : <></>
                    }
                </Row>
                {matches.m || matches.s
                    ?
                    <Col>
                        <Row gridGap="S200" flexWrap="wrap" style={{ marginTop: "0.8rem" }}>
                            {formData.OrganizationVendorKamStatus !== 'INACTIVE' && <View flex={0.5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <WithModal renderModal={(close) => renderModal(close)} shouldCloseOnClickOutside={false}>
                                    {({ open }) => (<>
                                        <Button id="btnEditRequest" dataTestId="btnDlt" icon={<IconBin />} size={ButtonSize.Small} variant={ButtonVariant.Secondary}
                                            onClick={open} >{constants.BUTTON.DELETE}</Button>
                                        <span hidden id="sroDialog">has pop-up dialog</span>
                                    </>)}
                                </WithModal>

                                <Button style={{ marginLeft: '10px' }} id="btnEditRequest" dataTestId="btnEditRequest" icon={<IconPencil />} size={ButtonSize.Small} variant={ButtonVariant.Primary}
                                    onClick={editOrganization} >{constants.BUTTON.EDIT}</Button>

                            </View>}
                        </Row>
                    </Col>
                    : <></>
                }
            </Col>
            <Row flexWrap="wrap" justifyContent="space-between">
                <Col width="100%" >
                    <View style={(matches.l || matches.xl) ? { margin: '0 0 0 4rem' } : { margin: '0 0 0 1rem' }}><TabBar {...tabBarProps} display="flex" justifyContent="space-between" width="100%" /></View>
                    <Hr color="neutral40" style={{ marginTop: "-1px" }} />
                    <TabSwitcher {...tabSwitcherProps}  shouldUnmountHiddenPanels={true}>
                        <TabPanel value="Details">
                            <Col flexWrap="wrap" margin={{ top: "2rem", left: "1rem", right: "1rem" }} alignItems="center">
                                <Row flexWrap="wrap" justifyContent="space-between" style={{ width: '80%'}}>
                                    <Col flexWrap="wrap" border="1px solid #B9C0C8" margin={{ top: '1rem' }} style={{ width: matches.s ? '100%' : '100%' }}>
                                        <Card backgroundColor="primary05" border="0 !important" >
                                            <Col gridGap="S100" width='100%'>
                                                <Text fontSize="T200" color="neutral90">{constants.FORM_DETAILS.CONTRACT_DETAILS}</Text>
                                                <Flex flexDirection={matches.s ? "column" : "row"} alignItems={'baseline'} justifyContent={matches.s ? "space-between" : matches.m ? "space-between" : "none"} gridGap={(matches.s || matches.m) ? "S200" : "S100"} >
                                                    <View flex={1} >
                                                        <Flex flexDirection='row' flexWrap="wrap" gridGap={(matches.s || matches.m) ? "S100" : "S200"}>
                                                            <Text fontSize="T200" fontWeight="bold" color="neutral90">Contract status: {formData.OrganizationContractStatus}</Text>
                                                            <Text color="neutral30" fontWeight="bold">&#124;</Text>
                                                            <Text fontSize="T200" fontWeight="bold" color="neutral90">Contract Location: {formData.OrganizationContractLocation}</Text>
                                                            <Text color="neutral30" fontWeight="bold">&#124;</Text>
                                                            <Text fontSize="T200" fontWeight="bold" wordBreak="break-word" color="neutral90">Validity (days to expire): {formData.OrganizationContractValidity}</Text>
                                                        </Flex>
                                                    </View>
                                                </Flex>
                                            </Col>
                                        </Card>
                                        {(!formData.OrganizationCountry || !isMenaCountry(formData.OrganizationCountry)) && <ClrcDetails />}
                                    </Col>
                                </Row>
                            </Col>
                        </TabPanel>
                        <TabPanel value="Audit logs">
                            <Col flexWrap="wrap" margin={{ top: "2rem", left: "1rem", right: "1rem" }} alignItems="center">
                                <AuditLogCards config={{ vendorKamId: params.id, vendorId: localStorage.getItem('vendorId'), entityType: "Vendor_KAM",country : formData.OrganizationCountry }}
                                    textForFirstLog={constants.ORGANIZATIONS.TEXT_FOR_FIRST_AUDIT_LOG} />
                            </Col>
                        </TabPanel>
                    </TabSwitcher>
                </Col>
            </Row>
        </View>
    )
}

export default withAlertSnackBar(ViewVendor);