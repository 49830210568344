import React from 'react';
import { QueryClientProvider, QueryClient } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools"
import './variables.scss';
import './components/Header/Header.scss';
import './index.scss';
import './global.scss';
import constants from "./Utils/Constants";
import { StencilProvider } from '@amzn/stencil-react-components/context';
import defaultTheme from '@amzn/stencil-react-theme-default';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import App from './App';

const cathodeVersions = require('@amzn/cathode-versions-javascript');

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      cacheTime: 1000 * 60 * 30,
      retry: false
    },
  },
})

ReactDOM.render(
  <StencilProvider theme={defaultTheme}>
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <App />
        <ReactQueryDevtools initialIsOpen />
      </QueryClientProvider>
    </BrowserRouter >
  </StencilProvider>
  , document.getElementById('root')
);

let stage
stage = sessionStorage.getItem(constants.SPECTROMETER.STAGE);
//User analytics integration(Spectrometer)
const cathodeConfig = {
  siteName: constants.SPECTROMETER.SITE_NAME,
  appName: (stage !== null && stage !== undefined) ? constants.SPECTROMETER.APP_NAME + "-" + stage :
    constants.SPECTROMETER.APP_NAME
}

const scriptTags = cathodeVersions.getSpectrometerScriptTags(cathodeConfig, true)
document.write(scriptTags.boomerangLoaderScript)
document.write(scriptTags.listenerScripts)
document.write(scriptTags.cathodeScript)



// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
