import {Col} from "@amzn/stencil-react-components/layout";
import {Spinner} from "@amzn/stencil-react-components/spinner";
import {SpinnerSize} from "@amzn/stencil-react-components/dist/submodules/spinner/interfaces";


function FullPageCenteredSpinner( props) {
    return (
        <Col height="75vh" alignItems="center" justifyContent="center" textAlign="center" style={{zIndex:"999999"}}>
           <Spinner size={(props.size) ? props.size : SpinnerSize.Medium} showText loadingText={props.message} />
        </Col>
    )
}

export default FullPageCenteredSpinner