import {userDetailAxios} from "../api/axios";


export const ParentVendorServices = {
    createParentVendor,
    deleteParentVendor,
    updateParentVendor,
    getAllParentVendors,
    getParentVendor,
    uploadFile
}

function createParentVendor(body){
    return userDetailAxios.post('/parentVendor/create',body);
}

function deleteParentVendor(vendorId){
    return userDetailAxios.delete(`/parentVendor/delete/${vendorId}`);
}

function getAllParentVendors(){
    return userDetailAxios.get('/parentVendor/list');
}

function getParentVendor(vendorId){
    return userDetailAxios.get(`/parentVendor/${vendorId}`);
}

function updateParentVendor(body){
    return userDetailAxios.put('/parentVendor/update',body);
}
function uploadFile(body){
    return userDetailAxios.post('/parentVendor/uploadFile',body);
}
