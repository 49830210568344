import React from 'react';
import { NavLink } from 'react-router-dom';
import { PageHeaderLink } from '@amzn/stencil-react-components/page';

let activeStyle = {
    borderBottom: "4px solid white",
    cursor: 'pointer'
};

const BaseRouterLink = (props) => (
    <NavLink
        style={({ isActive }) => isActive ? activeStyle : { cursor: 'pointer' }}
        {...props}
    />
);

export const PageHeaderRouterLink = PageHeaderLink.withComponent(BaseRouterLink);