import React from 'react'
import { Col, Container, Row, View } from '@amzn/stencil-react-components/layout';
import constants from '../../Utils/Constants';
import { Text } from '@amzn/stencil-react-components/dist/submodules/text';
import { SideNavItem, SideNavItems } from "@amzn/stencil-react-components/side-nav";
import { Checkbox, InputWrapper, LabelPosition } from "@amzn/stencil-react-components/form";
import {withAlertSnackBar} from "../../HOComponents/AlertSnackBarHOC";

const expandableFilterItems = (item, onChangeCallback) => {
    const checkChild = (e) => {
        e.target?.querySelector("label")?.click();
    }

    return (
        <SideNavItem key={"sideNavItem" + item.id} dataTestId={"sideNavItem" + item.id} display="flex" onClick={(e) => checkChild(e)}>
            <InputWrapper
                id={"navItem" + item.id}
                labelText={item.name}
                labelPosition={LabelPosition.Trailing}>
                {inputProps => <Checkbox checked={item.is_selected} id={"checkBox" + item.id} onChange={e => onChangeCallback(item)} {...inputProps} />}
            </InputWrapper>
        </SideNavItem>
    );
}

function ParentVendorsFilterDialog(props) {
    const [types, setTypes] = React.useState(constants.FILTERS.VENDOR_TYPES);
    const [vendorStatus, setVendorStatus] = React.useState(constants.FILTERS.VENDOR_STATUS);

    const resetFilters = () => {
        let typesL = [...types];
        typesL.forEach(item=>item.is_selected=false);
        let statusL = [...vendorStatus];
        statusL.forEach(item=>item.is_selected=false);
        setTypes(typesL);
        setVendorStatus(statusL);
        props.onChange({ filtersApplied: true, filters: {resetAppliedFilter: false, types: [], sites: [], vendorStatus: []}})
    }

    const updateFilters = () => {
        const typesL = types.filter((item) => item.is_selected).map((item) => item.name);
        const vendorStatusL = vendorStatus.filter((item) => item.is_selected).map((item) => item.name);
        props.onChange({ filtersApplied: true, filters: { resetAppliedFilter: false, types: typesL, sites: [], vendorStatus: vendorStatusL } });
    }

    const vendorTypeChangeHandler = (value) => {
        let typesL = [...types];
        typesL.forEach((lob) => lob.is_selected = (lob.name === (value && value.name) ? !lob.is_selected : lob.is_selected));
        setTypes(typesL);
        updateFilters();
    };

    const vendorStatusChangeHandler = (value) => {
        let typesL = [...vendorStatus];
        typesL.forEach((lob) => lob.is_selected = (lob.name === (value && value.name) ? !lob.is_selected : lob.is_selected));
        setVendorStatus(typesL);
        updateFilters();
    };

    return (
        <Container height="100%" style={{ padding: "0", margin: "0" }}>
            {props.mode === constants.DEVICE.DESKTOP &&
                <Col style={{ boxShadow: "inset 0px -1px 0px #8B96A3" }}>
                    <Row justifyContent="space-between" alignItems='baseline'>
                        <View padding={'1em'}><Text dataTestId="textFilter" fontSize="T300" fontWeight={'bold'}>Filter</Text></View>
                        <View padding={'1em'}><Text id="textReset" color='primary70' fontSize="T200" onClick={resetFilters} style={{ cursor: 'pointer' }}>Reset</Text></View>
                    </Row>
                </Col>}
            <Col>
                <View style={{ boxShadow: "inset 0px -1px 0px #8B96A3" }} className={'orgFiltersSidenav'}>
                    <SideNavItems id="types" isExpanded={true} label="Types" dataTestId='typesSideNav'>
                        {types.map((item) => expandableFilterItems(item, vendorTypeChangeHandler))}
                    </SideNavItems>
                </View>
                <View style={{ boxShadow: "inset 0px -1px 0px #8B96A3" }} className={'orgFiltersSidenav'}>
                    <SideNavItems id="types" label="Vendor Status" dataTestId='statusSideNav'>
                        {vendorStatus.map((item) => expandableFilterItems(item, vendorStatusChangeHandler))}
                    </SideNavItems>
                </View>
            </Col>
        </Container>
    )
}

export default withAlertSnackBar(ParentVendorsFilterDialog)
