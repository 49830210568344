import React, { useState, useEffect } from 'react'
import { UserServices } from "../../Services/users"
import {
  PageHeader,
  PageHeaderList
} from '@amzn/stencil-react-components/page';
import { useBreakpoints } from "@amzn/stencil-react-components/responsive";
import { Col, Flex, Spacer, View } from '@amzn/stencil-react-components/dist/submodules/layout';
import HeaderFlyout from './HeaderFlyout';
import { Text } from '@amzn/stencil-react-components/dist/submodules/text';
import { PageHeaderRouterLink } from './PageHeaderRouterLink';
import { useLocation } from 'react-router-dom';
import { Avatar, AvatarColorScheme, AvatarSize } from '@amzn/stencil-react-components/dist/submodules/avatar';
import UnauthorizedAccessDialog from '../../pages/PageNotFound/UnauthorizedAccessDialog';
import { parentVendorFlag} from "../../Utils/Util";
import { IconHome, IconSize } from '@amzn/stencil-react-components/icons';
import {HIRING_PORTAL_URL} from "../../Utils/Constants";


const Header = () => {
  const { matches: deviceResolution } = useBreakpoints();
  const location = useLocation();
  const [userdetails, setUserDetails] = useState({});
  const [showUnauthorizedDialog, setShowUnauthorizedDialog] = useState(false);

  function getPageHeaderItems(){
    let items=[];
    if(parentVendorFlag())
      items.push(<PageHeaderRouterLink key="vendors" to="/vendors" isSelected={location.pathname.includes('/vendors')}> Vendors </PageHeaderRouterLink>);
    items.push(<PageHeaderRouterLink key="KAMs" to="/vendorKams" isSelected={location.pathname.includes('/vendorkams')}> KAMs </PageHeaderRouterLink>);
    items.push(<PageHeaderRouterLink key="Requests" to="/requests" isSelected={location.pathname.includes('/requests')}> Requests </PageHeaderRouterLink>);
    return items;
  }

  const homePageRedirect = async () => {
    window.location.href = HIRING_PORTAL_URL[sessionStorage.getItem('stage')];
  }

  useEffect(() => {

    async function fetchData() {
      const sessionUser = sessionStorage.getItem('session_user');
      if (sessionUser) {
        setUserDetails(JSON.parse(sessionUser));
        console.log("Fetching Session User");
      } else {
        // TODO: Added to test in the local env
        // if(process.env.NODE_ENV === "development"){
        //   const userObject = { id: "haabalag", name: "haabalag" };
        //   sessionStorage.setItem("session_user", JSON.stringify(userObject));
        //   setUserDetails(userObject);
        // } else {
         await UserServices.getUser().then((data) => {
            const userObject = {id: data["data"]["login"], name: data["data"]["login"]};
            sessionStorage.setItem("session_user", JSON.stringify(userObject));
            setUserDetails(userObject);
          }).catch((err) => {
            setShowUnauthorizedDialog(true);
          })
        // }
      }

    }
    fetchData();
  }, []);

  return (
    <PageHeader isFixed={true} isFullWidth={true} >
      {deviceResolution.s ?
        <Flex flex={1} flexDirection='row' alignItems="center"><HeaderFlyout userdetails={userdetails} /><Col flex={2}><Text fontSize="T300" fontWeight="medium" textAlign="center" color="neutral0" style={{ marginLeft: "-2rem" }}>Vendor Management</Text></Col></Flex> :
        <nav
          aria-label="Site"
          style={{
            display: 'flex',
            flex: 1
          }}
        >
          <View color='neutral0' style={{ display: 'flex', width: '5%', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }} onClick={ homePageRedirect }>
            <IconHome size={IconSize.Medium}  />
          </View>
          <PageHeaderList style={{ height: '100%' }}
            items={getPageHeaderItems()}
          />
          <Spacer flex={1} />
          <Flex flexDirection='row' flex={0.1} alignItems="center"><Avatar size={AvatarSize.ExtraSmall} colorScheme={AvatarColorScheme.Inverted} username={userdetails && userdetails.id ? userdetails.name : ''}></Avatar></Flex>
        </nav>
      }
      {showUnauthorizedDialog && (
        <UnauthorizedAccessDialog />
      )}
    </PageHeader>
  )
}

export default Header
