import { Modal } from '@amzn/stencil-react-components/modal';
import { ModalContent } from '@amzn/stencil-react-components/modal';
import React from "react";
import {
    MessageBanner,
    MessageBannerLink
} from '@amzn/stencil-react-components/message-banner';

function UnauthorizedAccessDialog() {
    const open = true;
    return (
        <Modal isOpen={open} close={()=>{}}>
            <ModalContent titleText="Unauthorized Access" buttons={[]}>
                <MessageBanner
                    type="error"
                >
                    You are not authorized to access data. Please request access to the following team group { }
                    <MessageBannerLink href="https://permissions.amazon.com/a/team/WFS-INDIA-Vendor-mgmt">
                        https://permissions.amazon.com/a/team/WFS-INDIA-Vendor-mgmt
                    </MessageBannerLink>
                </MessageBanner>
            </ModalContent>
        </Modal>
    )
}

export default UnauthorizedAccessDialog