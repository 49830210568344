import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import "date-fns";
import constants, {
  AP_VENDOR_ID, CLRC_PLACEHOLDERS,
  COUNTRY, COUNTRY_CODE_MAPPING,
  CREATE_ORGANIZATION_PLACEHOLDERS,
  VENDOR_STATUS
} from "../../../Utils/Constants";
import { OrganizationServices } from "../../../Services/organizations";
import { MasterServices } from "../../../Services/master";
import { withAlertSnackBar } from "../../../HOComponents/AlertSnackBarHOC";
import {
  validatePattern,validatePatterns
} from "../../../Utils/Validator";
import {
  getPlaceholder,
  getVendorNames, isMenaCountry,
  parentVendorFeatureFlag,
} from "../../../Utils/Util";
import { Col, Hr, Row, Spacer } from "@amzn/stencil-react-components/layout";
import { Label, Text } from "@amzn/stencil-react-components/text";
import { Input, InputFooter, Select } from "@amzn/stencil-react-components/form";
import { useBreakpoints } from "@amzn/stencil-react-components/responsive";
import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { Link } from "@amzn/stencil-react-components/link";
import { IconArrowLeft, IconInformation, IconSize } from "@amzn/stencil-react-components/icons";
import { PopupDatePicker } from '@amzn/stencil-react-components/date-picker';
import {
  TooltipAlignment,
  withTooltip
} from '@amzn/stencil-react-components/tooltip';
import {ParentVendorServices} from "../../../Services/parentVendor";
import FullPageCenteredSpinner from "../../common/FullPageCenteredSpinner";
import Constants from "../../../Utils/Constants";
import {useQueryClient} from "react-query";
import {IndiaAddressTab} from "./Components/CreateOrganization_IN";
import {MENAAddressTab, UAEAddressTab} from "./Components/CreateOrganization_MENA";

const IconWithTooltip = withTooltip()(IconInformation);

const propsMap2 = {
  s: { width: '100%' },
  m: { width: '70%' },
  l: { width: '70%' },
  xl: { width: '60%' }
}

const propsMap1 = {
  s: { width: "100%" },
  m: { width: "48%" },
  l: { width: "48%" },
  xl: { width: "48%" }
}

const textStyle = {
  fontSize: "1rem",
  fontWeight: "400",
  lineHeight: "1.5rem"
};

function CreateOrganization(props) {

  const { props: mainViewBreakPoints } = useBreakpoints({ propsMap: propsMap2 });
  const { props: inputFieldBreakPoints, matches } = useBreakpoints({ propsMap: propsMap1 })


  const navigate = useNavigate();
  const params = useParams();
  const [formData, setFormData] = React.useState({});
  const [types, setTypes] = React.useState([
    { id: "Manpower", name: "Manpower", is_selected: false },
    { id: "BGV", name: "BGV", is_selected: false },
    { id: "Biometric", name: "Biometric", is_selected: false },
  ]);
  const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
  // const [dataSites, setDataSites] = useState("");
  const [sites, setSites] = useState([]);
  const [selectedSites, setSelectedSites] = useState([]);
  const [vendorName, setVendorName] = useState(getVendorNames());
  const [vendorId, setVendorId] = useState("");
  const [parentVendors,setParentVendors] = useState([]);
  const [isLoading,setIsLoading] = useState(false);
  // const [showUnauthorizedDialog, setShowUnauthorizedDialog] = React.useState(false);
  const queryClient = useQueryClient();

  useEffect(() => {
    async function getData() {
      getSites();
        /* istanbul ignore next line */
        if(parentVendorFeatureFlag() && !params.id) {
          setIsLoading(true);
          await ParentVendorServices.getAllParentVendors().then(response => {
            setIsLoading(false);
            let vendorNameL = response.data.parentVendors.filter((vendor)=>{return vendor.status===VENDOR_STATUS.ACTIVE}).map((parentVendor) =>
                ({
                  id: parentVendor.vendorName,
                  is_selected: false,
                  name: parentVendor.vendorName,
                  vendorId: parentVendor.vendorId
                }));
            vendorNameL.sort((a,b) => ( (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : (a.name.toLowerCase() < b.name.toLowerCase()) ? -1 : 0));
            setParentVendors(response.data.parentVendors);
            setVendorName(vendorNameL);
          })
        }else{
          let vendorNameL = [];
            Object.entries(AP_VENDOR_ID).forEach((vendor)=> {
              vendorNameL.push({
                id: vendor[0],
                is_selected:false,
                name: vendor[0],
                vendorId: vendor[1]
              });
            });
          setVendorName(vendorNameL);
        }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  useEffect(() => {
    async function loadData() {
        if (params.id && sites && sites.length) {
          prepopulate();
        }
    }
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, sites.length]);

  const getSites = async () => {
    await MasterServices.getAllSites().then((response) => {
      let data = (response && response.data) || [];
      let sitesL = data.map((site) => ({
        id: site.siteId,
        is_selected: false,
        name: site.siteId ? site.siteId : "",
        country: site.homeCountryGroup ? site.homeCountryGroup : COUNTRY_CODE_MAPPING.India
      }));
      setSites(sitesL);
    })
  }

  const prepopulate = async () => {
    await OrganizationServices.getOrganizationById(params.id)
      .then((response) => {
        let data = (response && response.data) || {};
        let formDataL = { ...formData };
        setVendorId(data.vendorId ? data.vendorId : "");
        formDataL.OrganizationVendorId = data.vendorId ? data.vendorId : "";
        formDataL.OrganizationVendorKamId = data.vendorKamId ? data.vendorKamId : "";
        formDataL.OrganizationVendorName = data.vendorName ? data.vendorName : "";
        formDataL.OrganizationVendorKamName = data.vendorKamName ? data.vendorKamName : "";
        formDataL.OrganizationStreet = data.street ? data.street : "";
        formDataL.OrganizationCity = data.city ? data.city : "";

        formDataL.OrganizationPostalcode = data.postalCode
          ? data.postalCode
          : "";
        formDataL.OrganizationPhone = data.phone ? data.phone : "";
        formDataL.OrganizationUsername = data.username ? data.username : "";
        formDataL.OrganizationCorrespondenceEmail = data.correspondenceEmail ? data.correspondenceEmail : "";
        formDataL.OrganizationClrcCapacity = data.clrcCapacity
          ? data.clrcCapacity
          : "";
        formDataL.OrganizationContractLocation = data.contractLocation
          ? data.contractLocation
          : "";
        formDataL.OrganizationClrcLocation = data.clrcLocation
          ? data.clrcLocation
          : "";
        formDataL.OrganizationCreatedDate = data.createdDate
          ? data.createdDate
          : "";
        formDataL.OrganizationClrcValidity = data.clrcValidity ? data.clrcValidity : "";
        formDataL.OrganizationContractValidity = data.contractValidity ? data.contractValidity : "";
        formDataL.state = data.state ? data.state : "";
        formDataL.country = data.country ? data.country : "";
        formDataL.district = data.district ? data.district : "";
        // setDataSites(data.sites.map((site) => site.split(",")[0]).join(","));
        setSelectedSites(data.sites);
        setSites(sites.map((site) => { site.is_selected = data.sites.includes(site.name); return site; }))
        setFormData(formDataL);
        if (data.type) {
          prepopulateOrganizationType(data.type);
        }
      })
      .catch((error) => {
        props.snackbarShowMessage(
          `${constants.ERROR.PREPOPULATE_ERROR}`,
          "error"
        );
      });
  };


  const validateForm = () => {
    let isFormValid = true;
    if (
        !formData.OrganizationVendorKamName ||
        !formData.OrganizationStreet ||
        !formData.OrganizationCity ||
        !formData.OrganizationPhone ||
        !formData.OrganizationUsername || !formData.OrganizationCorrespondenceEmail ||
        !types.find((type) => type.is_selected) ||
        !formData.district ||
        !formData.OrganizationContractLocation ||
        !formData.OrganizationContractValidity ||
        !formData.OrganizationClrcCapacity ||
        parseInt(formData.OrganizationClrcCapacity) < 1 ||
        !formData.OrganizationClrcLocation ||
        !formData.OrganizationClrcValidity ||
        !formData.country ||
        !validatePattern(new RegExp(constants.PATTERN.ALPHA_NUMERIC_DOT_PATTERN), formData.OrganizationVendorKamName) ||
        !validatePatterns(constants.PATTERN.POSTAL_CODES[formData.country],formData.OrganizationPostalcode) ||
        !validatePatterns(constants.PATTERN.PHONE_NUMBERS[formData.country],formData.OrganizationPhone) ||
        !validatePattern(new RegExp(constants.PATTERN.EMAIL_ADDRESS_PATTERN), formData.OrganizationUsername) ||
        !validatePattern(new RegExp(constants.PATTERN.EMAIL_ADDRESS_PATTERN), formData.OrganizationCorrespondenceEmail) ||
        !validatePatterns(constants.PATTERN.ADDRESS_PATTERNS[formData.country],formData.OrganizationContractLocation) ||
        !validatePatterns(constants.PATTERN.ADDRESS_PATTERNS[formData.country],formData.OrganizationClrcLocation) ||
        !validatePatterns(constants.PATTERN.STATE_PATTERNS[formData.country],formData.state) ||
        !validatePatterns(constants.PATTERN.DISTRICT_PATTERNS[formData.country],formData.district) ||
        !validatePatterns(constants.PATTERN.ADDRESS_PATTERNS[formData.country],formData.OrganizationCity) ||
        new Date(formData.OrganizationContractValidity) < new Date() ||
        new Date(formData.OrganizationClrcValidity) < new Date()
    ) {
      isFormValid = false;
    }
    if (
      !sites.filter(site => site.is_selected)
        .map((mapItem) => mapItem.name)
        .join(",")
    ) {
      isFormValid = false;
    }
    return isFormValid;
  };

  const formSubmit = async () => {
    setIsFormSubmitted(true);
    if (validateForm()) {
      let selectedSites = sites.filter((filterItem) => filterItem.is_selected).map((mapItem) => mapItem.name);
      let selectedVendorName;
      let selectedVendorId;
      if (!params.id) {
        selectedVendorName = vendorName.filter((item) => item.is_selected).map((item) => item.name)[0];
        selectedVendorId = vendorName.filter((item) => item.is_selected).map((item)=>item.vendorId)[0];
      }

      let body = {
        vendorId: params.id ? formData.OrganizationVendorId : selectedVendorId,
        vendorName: params.id ? formData.OrganizationVendorName : selectedVendorName,
        vendorKamName: formData.OrganizationVendorKamName ? formData.OrganizationVendorKamName : "",
        street: formData.OrganizationStreet ? formData.OrganizationStreet : "",
        city: formData.OrganizationCity ? formData.OrganizationCity : "",
        district: formData.district ? formData.district : "",
        state: formData.state ? formData.state : "N/A",
        country: formData.country
          ? formData.country
          : COUNTRY.India,
        postalCode: formData.OrganizationPostalcode
          ? formData.OrganizationPostalcode
          : "N/A",
        phone: formData.OrganizationPhone ? formData.OrganizationPhone : "",
        username: formData.OrganizationUsername ? formData.OrganizationUsername : "",
        correspondenceEmail: formData.OrganizationCorrespondenceEmail ? formData.OrganizationCorrespondenceEmail : "",
        clrcCapacity: formData.OrganizationClrcCapacity
          ? parseInt(formData.OrganizationClrcCapacity)
          : 0,
        clrcLocation: formData.OrganizationClrcLocation
          ? formData.OrganizationClrcLocation
          : "",
        clrcValidity: formData.OrganizationClrcValidity,
        contractValidity: formData.OrganizationContractValidity,
        sites: selectedSites,
        contractLocation: formData.OrganizationContractLocation
          ? formData.OrganizationContractLocation
          : "",
        type: types.find((type) => type.is_selected).name,
      };
      if (params.id) {
        body.vendorKamId = params.id;
        body.createdDate = formData.OrganizationCreatedDate;
        await OrganizationServices.editOrganization(body)
          .then((response) => {
            props.snackbarShowMessage(
              `${constants.CRUD_SUCCESS_ALERTS.UPDATED}`,"success",5000
            );
            window.setTimeout(() => {
              navigate(
                `/${constants.ORGANIZATIONS.ROUTE.VIEW}/${params.id}`
              );
            }, 1000);
          })
          .catch((response, error) => {
            props.snackbarShowMessage(
              `${constants.ERROR.UPDATE_ERROR} ${response.data.message}`,
              "error",
              5000,
              response.data.message
            );
          });
      } else {
        await OrganizationServices.addOrganization(body)
          .then((response) => {
            props.snackbarShowMessage(`${constants.CRUD_SUCCESS_ALERTS.CREATED}`,"success", 5000);
            queryClient.invalidateQueries([Constants.REACT_QUERY.QUERY_KEY.GET_KAMS_BY_PARENT_VENDOR]);
            window.setTimeout(() => {navigate(`/${constants.ORGANIZATIONS.ROUTE.VIEW}/${response.data.vendorKamId}`);}, 1000);
          })
          .catch((response) => {
            if (response && response.data && (response.data.message === constants.ERROR.PORTAL_ACCESS_ERROR)) {
              // setShowUnauthorizedDialog(true);
            } else {
              props.snackbarShowMessage(
                `${constants.ERROR.CREATE_ERROR} ${response.data.message}`,
                "error",
                5000
              );
            }
          });
      }
    }
  };

  const vendorNameChangeHandler = (value) => {
    setIsFormSubmitted(false);
    let valuel = value;
    let vendorNameL = [...vendorName];
    vendorNameL.forEach((vendorName) => {
      vendorName.is_selected = vendorName.id === valuel;
    });
    let formdataL = {...formData};
    let selectedVendor = vendorNameL.filter((item)=>item.is_selected)[0];
    if(parentVendors) {
      let parentVendorTypes = parentVendors.filter(parentVendor => parentVendor.vendorId === selectedVendor.vendorId)[0].vendorTypes;
      let typesL = constants.FILTERS.VENDOR_TYPES.filter(type =>parentVendorTypes.includes(type.id));
      setTypes(typesL);
    }
    formdataL['OrganizationVendorName'] = selectedVendor.id;
    setFormData(formdataL);
    setVendorName(vendorNameL);
    setVendorId(selectedVendor.vendorId);
  };

  const organizationTypeChangeHandler = (value) => {
    setIsFormSubmitted(false);
    let typesL = [...types];
    typesL.forEach((type) => {
      type.is_selected = value && type.name === value;
    });
    setTypes(typesL);
  };

  const prepopulateOrganizationType = (value) => {
    let typesL = [...types];
    typesL.forEach((type) => {
      type.is_selected = value && type.name === value;
    });
    setTypes(typesL);
  };

  const sitesChangeHandler = (value) => {
    setIsFormSubmitted(false);
    let selectedIds = value;
    let selectedSitesL = [];
    let sitesL = [...sites];
    sitesL.map((site) => {
      site.is_selected =
        selectedIds && selectedIds.length > 0 && selectedIds.includes(site.name);
      if (site.is_selected) {
        selectedSitesL.push(site.name);
      }
      return site;
    });
    setSites(sitesL);
    setSelectedSites(selectedSitesL);
    // setDataSites(dataSitesL);
  };

  // const prepopulateSites = (values) => {
  //   let selectedIds =
  //     values && values.split(",").length > 0 ? values.split(",") : [];
  //   let sitesL = [...sites];
  //   sitesL.forEach((site) => {
  //     site.is_selected =
  //       selectedIds && selectedIds.length > 0 && selectedIds.includes(site.name);
  //   });
  //   return true;
  // };

  // const prepopulateVendorName = (value) => {
  //   let vendorNameL = [...vendorName];
  //   vendorNameL.forEach((vendorName) => {
  //     vendorName.is_selected = value && vendorName.id === value;
  //   });
  //   return true;
  // }

  const cancelOrganization = () => {
    if (params.id) {
      navigate(`/${constants.ORGANIZATIONS.ROUTE.VIEW}/${params.id}`);
    } else if (!params.id && !params.relatedID && !params.relatedName) {
      navigate(`/${constants.ORGANIZATIONS.ROUTE.MAIN}`);
    } else if (params.relatedID && params.relatedName) {
      navigate(-1);
    }
  };
  const handleText = (event) => {
    setIsFormSubmitted(false);
    let formDataL = { ...formData };
    formDataL[event.target.name] = event.target.id === "OrganizationVendorKamName" ? event.target.value.toUpperCase() : event.target.value;
    setFormData(formDataL);
  };

  const handleTextCLRCCapacity = (event) => {
    setIsFormSubmitted(false);
    let formDataL = { ...formData };
    const reg = new RegExp(constants.PATTERN.NUMERIC_PATTERN);
    if (reg.test(event.target.value)) {
      formDataL[event.target.name] = event.target.value;
    }
    setFormData(formDataL);
  };

  const handleDate = (value) => {
    setIsFormSubmitted(false);
    let formDataL = { ...formData };
    formDataL['OrganizationContractValidity'] = value;
    setFormData(formDataL);
  }

  const handleDateClrc = (value) => {
    setIsFormSubmitted(false);
    let formDataL = { ...formData };
    formDataL['OrganizationClrcValidity'] = value;
    setFormData(formDataL);
  }

  // const handleValidationError = (elementId) => {
  //   switch (elementId) {
  //     case 'OrganizationVendorKamNameError': checkValidationPattern(elementId, new RegExp(constants.PATTERN.ALPHA_NUMERIC_PATTERN), formData.OrganizationVendorKamName, 'Invalid Name Format');
  //       break;
  //     case 'OrganizationUsernameError': checkValidationPattern(elementId, constants.PATTERN.EMAIL_ADDRESS_PATTERN, formData.OrganizationUsername, 'Invalid Email Address Format');
  //       break;
  //     case 'OrganizationCityError': checkValidationPattern(elementId, new RegExp(constants.PATTERN.ALPHA_PATTERN), formData.OrganizationCity, 'Invalid City Format');
  //       break;
  //     case 'OrganizationPostalCodeError': checkValidationPattern(elementId, new RegExp(constants.PATTERN.POSTAL_CODE_PATTERN), formData.OrganizationPostalcode, 'Invalid Postal Code Format');
  //       break;
  //     case 'OrganizationPhoneError': checkValidationPattern(elementId, new RegExp(constants.PATTERN.PHONE_NUMBER_PATTERN), formData.OrganizationPhone, 'Invalid Phone Number Format');
  //       break;
  //     case 'OrganizationContractLocationError': checkValidationPattern(elementId, new RegExp(constants.PATTERN.ALPHA_NUMERIC_PATTERN), formData.OrganizationContractLocation, 'Invalid Contract Location Format');
  //       break;
  //     case 'OrganizationClrcLocationError': checkValidationPattern(elementId, new RegExp(constants.PATTERN.ALPHA_NUMERIC_PATTERN), formData.OrganizationClrcLocation, 'Invalid CLRC Location Format');
  //       break;
  //     case 'OrganizationCorrespondenceEmailError': checkValidationPattern(elementId, constants.PATTERN.EMAIL_ADDRESS_PATTERN, formData.OrganizationCorrespondenceEmail, 'Invalid Correspondence Email Address Format');
  //       break;
  //     default: ;
  //   }
  // }

  // const checkValidationPattern = (elementId, regex, data, error) => {
  //   if (data && data.length !== 0 && !validatePattern(regex, data)) {
  //     document.getElementById(elementId).innerHTML = error
  //   } else {
  //     document.getElementById(elementId).innerHTML = ''
  //   }
  // }

  // const removeValidationError = (elementId) => {
  //   document.getElementById(elementId).innerHTML = ''
  // }

  const handleNumberOnlyInput = (event) => {
    setIsFormSubmitted(false);
    let formDataL = { ...formData };
    const reg = new RegExp(constants.PATTERN.NUMERIC_PATTERN);
    if (reg.test(event.target.value)) {
      formDataL[event.target.name] = event.target.value;
    }
    setFormData(formDataL);
  }

  const getSelectedVendorName = () => {
    if(params.id){
      return formData.OrganizationVendorName;
    }
    let selectedVendor = vendorName.filter(v=>v.is_selected);
    if(selectedVendor && selectedVendor.length > 0)
      return selectedVendor[0].name;
    return null;
  }

  const updateFormData = (key,value) => {
    setIsFormSubmitted(false);
    let formDataList = {...formData};
    formDataList[key] = value;
    setFormData(formDataList);
  }

  const AddressTab = () => {
    let addressProps = {
      formData : formData,
      updateFormData : updateFormData,
      inputFieldBreakPoints : inputFieldBreakPoints,
      isFormSubmitted : isFormSubmitted,
      handleText : handleText,
      handleNumberOnlyInput : handleNumberOnlyInput
    };
    if(formData.country === 'India')
      return IndiaAddressTab(addressProps);
    else if(formData.country === 'Saudi Arabia' || formData.country === 'Egypt')
      return MENAAddressTab(addressProps);
    else if(formData.country === 'United Arab Emirates')
      return UAEAddressTab({...addressProps});
    else
      return <></>;
  }

  function getSiteOptions (){
    if(formData.country)
      return sites.filter(site => site.country === COUNTRY_CODE_MAPPING[formData.country]);
    return [];
  }

  function countryChangeHandler(country){
    setSelectedSites([]);
    let formDataL = {...formData};
    formDataL.OrganizationStreet = '';
    formDataL.OrganizationCity = '';
    formDataL.OrganizationPostalcode = '';
    formDataL.OrganizationPhone = '';
    formDataL.state = '';
    formDataL.country = '';
    formDataL.district = '';
    formDataL.country = country;
    if(isMenaCountry(country)){
      formDataL.OrganizationClrcLocation = country;
      formDataL.OrganizationClrcCapacity = CLRC_PLACEHOLDERS.Capacity;
      formDataL.OrganizationClrcValidity = CLRC_PLACEHOLDERS.Validity;
    } else{
      formDataL.OrganizationClrcLocation = '';
      formDataL.OrganizationClrcCapacity = '';
      formDataL.OrganizationClrcValidity = '';
    }
    setFormData(formDataL);
  }
  return (
      <>  {isLoading ? <FullPageCenteredSpinner/> :
          <Col alignItems='center' style={{padding: "1rem"}} gridGap='S300'>
            <Row {...mainViewBreakPoints}>
              <Link style={{color: 'rgba(23, 104, 201, 1)'}}
                    onClick={() => (navigate(`/${constants.ORGANIZATIONS.ROUTE.MAIN}`))}>
                <Row style={{paddingTop: "2rem", paddingBottom: "1rem"}}>
                  <IconArrowLeft size={IconSize.Medium}/><Spacer width="0.3rem"/><Text fontSize={"1.25rem"}>Back to Kams
                  list</Text>
                </Row>
              </Link>
            </Row>
            <Row {...mainViewBreakPoints}><Text fontSize="T500"
                                                fontWeight='bold'>{(params.id) ? "Edit Vendor" : "New Vendor"}</Text></Row>
            <Col gridGap='S500' {...mainViewBreakPoints}>
              <Col gridGap='S300'>
                <Text fontSize='T300' fontWeight='bold'>{constants.FORM_DETAILS.BASIC}</Text>
                <Row width="100%" flexWrap='wrap' justifyContent='space-between' gridGap='S300'>
                  <Col gridGap='S100' {...inputFieldBreakPoints}>
                    <Label id='full-name'>Vendor Name:*</Label>
                    <Col>
                      <Select disabled={params.id}
                              options={vendorName.map(v => v.name)}
                              onChange={vendorNameChangeHandler}
                              value={getSelectedVendorName()}
                              id="vendorNameSelect"
                              dataTestId="vendorNameSelect"
                      />
                      {isFormSubmitted && !formData.OrganizationVendorName &&
                          <InputFooter error>Vendor Name is missing</InputFooter>
                      }
                    </Col>
                  </Col>
                  <Col gridGap='S100' {...inputFieldBreakPoints}>
                    <Label id='vendor-id'>Vendor ID: *</Label>
                    <Col>
                      <Text style={textStyle}>{vendorId}</Text>

                    </Col>
                  </Col>
                </Row>
                <Row justifyContent='space-between' width="100%" flexWrap='wrap' gridGap='S300'>
                  <Col gridGap='S100' {...inputFieldBreakPoints}>
                    <Label id='vendorKam'>Vendor KAM : *</Label>
                    <Col>
                      <Input id='vendorKam' dataTestId="vendorKam" type='text' placeholder='Vendor Kam Name'
                             name="OrganizationVendorKamName"
                             value={(formData && formData.OrganizationVendorKamName) ? formData.OrganizationVendorKamName : ""}
                             onChange={handleText}/>
                      {isFormSubmitted && !formData.OrganizationVendorKamName &&
                          <InputFooter error>Vendor KAM is missing</InputFooter>
                      }
                    </Col>
                  </Col>
                  <Col gridGap='S100' {...inputFieldBreakPoints}>
                    <Label id='OrganizationUsername'>Username : *</Label>
                    <Col>
                      <Input id='OrganizationUsername' dataTestId="OrganizationUsername" name="OrganizationUsername"
                             type='text' placeholder='e.g., name@email.com' value={formData?.OrganizationUsername} disabled={params.id}
                             onChange={handleText}/>
                      {isFormSubmitted && !formData.OrganizationUsername &&
                          <InputFooter error>Username is missing</InputFooter>
                      }
                    </Col>
                  </Col>
                </Row>

              </Col>
              <Col>
                <Hr size="wide"/>
              </Col>
              <Col gridGap='S300'>
                <Text fontSize='T300' fontWeight='bold'>{constants.FORM_DETAILS.LOCATION}</Text>
                <Row justifyContent='space-between' width="100%" flexWrap='wrap' gridGap='S300'>
                  <Col gridGap='S100' {...inputFieldBreakPoints}>
                    <Label id='Email'>Email : *</Label>
                    <Col>
                      <Input id='Email' type='text' dataTestId="Email" placeholder='e.g., name@email.com' disabled={params.id}
                             name="OrganizationCorrespondenceEmail" value={formData?.OrganizationCorrespondenceEmail}
                             onChange={handleText}/>
                      {isFormSubmitted && !formData?.OrganizationCorrespondenceEmail &&
                          <InputFooter error>Email is missing</InputFooter>
                      }
                      {isFormSubmitted && formData?.OrganizationCorrespondenceEmail && !validatePattern(new RegExp(constants.PATTERN.EMAIL_ADDRESS_PATTERN), formData.OrganizationCorrespondenceEmail) &&
                          <InputFooter error>Invalid email address Format</InputFooter>}
                    </Col>
                  </Col>
                  <Col gridGap='S100' {...inputFieldBreakPoints}>
                    <Label id='Country'>Country : *</Label>
                    <Col>
                      <Select
                          labelId="countrySelect"
                          id="countrySelect"
                          dataTestId="countrySelect"
                          options={params.id ? [] : Object.keys(COUNTRY_CODE_MAPPING)}
                          value={formData.country}
                          onChange={countryChangeHandler}
                      />
                      {isFormSubmitted && !formData.country &&
                          <InputFooter error>Country is missing</InputFooter>
                      }
                    </Col>                  </Col>
                </Row>
                {AddressTab()}
              </Col>
              <Col>
                <Hr size="wide"/>
              </Col>
              <Col gridGap='S300'>
                <Text fontSize='T300' fontWeight='bold'>Sites to request access to</Text>
                <Row justifyContent='space-between' flexWrap='wrap' gridGap='S300'>
                  <Col gridGap='S100' {...inputFieldBreakPoints}>
                    <Label id='sites'>Sites : *</Label>
                    <Col>
                      <Select labelId="sites"
                              id="siteSelect"
                              options={getSiteOptions().map(site => site.name)}
                              valueAccessor={site => site.name}
                              value={selectedSites}
                              onChange={sitesChangeHandler}
                              isSearchable={true}
                              isMulti={true}
                      />
                      {isFormSubmitted && !sites.find((s) => s.is_selected) &&
                          <InputFooter error> Site(s) is missing </InputFooter>
                      }
                    </Col>
                  </Col>
                  <Col gridGap='S100' {...inputFieldBreakPoints}>
                    <Label id='sites'>Types * <IconWithTooltip
                        color="primary70"
                        title="Show tooltip"
                        tooltipAlignment={TooltipAlignment.Left}
                        tooltipText="Please choose the type of the vendor (e.g., Manpower, BGV, Biometric etc.)."
                    /></Label>
                    <Col>
                      <Select labelId="types"
                              id="typeSelect"
                              options={types.map(type => type.name)}
                              value={types.find((s) => s.is_selected)?.name}
                              onChange={organizationTypeChangeHandler}
                              isSearchable={true}
                      />
                      {isFormSubmitted && !types.find((s) => s.is_selected) &&
                          <InputFooter error> type(s) is missing </InputFooter>
                      }
                    </Col>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Hr size="wide"/>
              </Col>
              <Col gridGap='S300'>
                <Text fontSize='T300' fontWeight='bold'>{constants.FORM_DETAILS.CONTRACT_DETAILS}</Text>
                <Row justifyContent='space-between' flexWrap='wrap' gridGap='S300'>
                  <Col gridGap='S100' {...inputFieldBreakPoints}>
                    <Label id='OrganizationContractLocation'>Contract Location : *</Label>
                    <Col>
                      <Input id="OrganizationContractLocation" placeholder={getPlaceholder(CREATE_ORGANIZATION_PLACEHOLDERS,formData.country,'OrganizationCity')}
                             dataTestId="OrganizationContractLocation" name="OrganizationContractLocation"
                             value={formData?.OrganizationContractLocation} onChange={handleText}/>
                      {isFormSubmitted && !formData.OrganizationContractLocation &&
                          <InputFooter error>Contract location is missing</InputFooter>
                      }
                      {isFormSubmitted && formData.OrganizationContractLocation && !validatePatterns(constants.PATTERN.ADDRESS_PATTERNS[formData.country], formData.OrganizationContractLocation) &&
                          <InputFooter error>Invalid Contract location format</InputFooter>
                      }
                    </Col>
                  </Col>
                  <Col gridGap='S100' {...inputFieldBreakPoints}>
                    <Col>
                      <PopupDatePicker
                          labelText="Date of Expiry : *"
                          onChange={handleDate}
                          dataTestId="OrganizationContractValidity"
                          value={formData.OrganizationContractValidity}
                          isDateDisabled={date => new Date(date) < new Date()}
                      />
                      {isFormSubmitted && !formData.OrganizationContractValidity &&
                          <InputFooter error>Contract expiry date is missing</InputFooter>
                      }
                      {isFormSubmitted && formData.OrganizationContractValidity && new Date(formData.OrganizationContractValidity) < new Date() &&
                          <InputFooter error>Invalid expiry date</InputFooter>
                      }
                    </Col>
                  </Col>
                </Row>
              </Col>
              {(!formData.country || !isMenaCountry(formData.country)) && <>
                <Col>
                  <Hr size="wide"/>
                </Col>
                  <Col gridGap='S300'>
                <Text fontSize='T300' fontWeight='bold'>{constants.FORM_DETAILS.CLRC_DETAILS}</Text>
                <Row justifyContent='space-between' flexWrap='wrap' gridGap='S300'>
                  <Col gridGap='S100' {...inputFieldBreakPoints}>
                    <Label id='OrganizationClrcCapacity'>CLRC Capacity : *
                      <IconWithTooltip
                          color="primary70"
                          title="Show tooltip"
                          tooltipAlignment={TooltipAlignment.Left}
                          tooltipText="A general category to describe Amazon’s contract with the organization. This should be updated regularly."
                      /></Label>
                    <Col>
                      <Input
                          id="OrganizationClrcCapacity"
                          placeholder='e.g. 3'
                          name="OrganizationClrcCapacity"
                          dataTestId="OrganizationClrcCapacity"
                          value={formData?.OrganizationClrcCapacity}
                          onChange={handleTextCLRCCapacity}
                      />
                      {isFormSubmitted && !formData.OrganizationClrcCapacity &&
                          <InputFooter error>Clrc capacity is missing</InputFooter>
                      }
                    </Col>
                  </Col>
                  <Col gridGap='S100' {...inputFieldBreakPoints}>
                    <Label id='OrganizationClrcLocation'>CLRC Location : *</Label>
                    <Col>
                      <Input id="OrganizationClrcLocation"
                             placeholder={getPlaceholder(CREATE_ORGANIZATION_PLACEHOLDERS, formData.country, 'OrganizationCity')}
                             dataTestId="OrganizationClrcLocation" name="OrganizationClrcLocation"
                             value={formData?.OrganizationClrcLocation} onChange={handleText}/>
                      {isFormSubmitted && !formData.OrganizationClrcLocation &&
                          <InputFooter error>Clrc location is missing</InputFooter>
                      }
                      {isFormSubmitted && formData.OrganizationClrcLocation && !validatePatterns(constants.PATTERN.ADDRESS_PATTERNS[formData.country], formData.OrganizationClrcLocation) &&
                          <InputFooter error>Invalid Clrc location format</InputFooter>
                      }
                    </Col>
                  </Col>
                </Row>
                <Row justifyContent='space-between' flexWrap='wrap' gridGap='S300'>
                  <Col gridGap='S100' {...inputFieldBreakPoints}>
                    <Label id="">Date of Expiry : * <IconWithTooltip
                        color="primary70"
                        title="Show tooltip"
                        tooltipAlignment={TooltipAlignment.Left}
                        tooltipText="A general category to describe Amazon’s contract duration with the organization."
                    /></Label>
                    <Col>
                      <PopupDatePicker
                          onChange={handleDateClrc}
                          dataTestId="OrganizationClrcValidity"
                          value={formData.OrganizationClrcValidity}
                          isDateDisabled={date => new Date(date) < new Date(formData.OrganizationContractValidity)}
                      />
                      {isFormSubmitted && !formData.OrganizationClrcValidity &&
                          <InputFooter error>Clrc expiry date is missing</InputFooter>
                      }
                      {isFormSubmitted && formData.OrganizationClrcValidity && new Date(formData.OrganizationClrcValidity) < new Date() &&
                          <InputFooter error>Invalid expiry date</InputFooter>
                      }
                    </Col>
                  </Col>
                </Row>
              </Col></>
              }
              <Col>
                {matches.s ?
                    <Col width='100%' gridGap='S200'>
                      <Button id="submitButton" dataTestId="submitButton" style={{width: '100%'}}
                              variant={ButtonVariant.Primary}
                              onClick={formSubmit}>{params.id ? 'Update request' : 'Submit request'}</Button>
                      <Button id="cancelButton" dataTestId="cancelButton" style={{width: '100%'}}
                              onClick={cancelOrganization}>Cancel</Button>
                    </Col>
                    :
                    <Row gridGap='S200' width='50%'>
                      <Button id="SubmitButton" dataTestId="SubmitButton" onClick={cancelOrganization}>Cancel</Button>
                      <Button id="CancelButton" dataTestId="CancelButton" variant={ButtonVariant.Primary}
                              onClick={formSubmit}>{params.id ? 'Update request' : 'Submit request'}</Button>
                    </Row>
                }
              </Col>
            </Col>
          </Col>}
      </>
  )


}

export default withAlertSnackBar(CreateOrganization);
