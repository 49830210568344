import { eventTaskAxios, orgAxios, siteAxios} from '../api/axios'

export const MasterServices = {
    getOrgStatuses,
    getOrgNodes,
    getOrgTypes,
    getOrgVenueCapacity,
    getOrgRelationshipHealth,
    getOrgContractstatus,
    getAllUsers,
    getAccountTypes,
    getContactTypes,
    getTaskTypes,
    getTaskPriorities,
    getTaskStatuses,
    getAllOrganizationsPicklist,
    getAllActivitesPicklist,
    getActivityFocus,
    getActivityFormat,
    getEventsForOrgs,
    getActivityType,
    getActivityStatus,
    postActivityForOrgs,
    postNodesForOrgs,
    postContactsForOrgs,
    getReportTypes,
    getActivityTextCodes,
    getVendorByActivity,
    getAllSites,
    getTimeZones,
    getAdminUsers
}

function getOrgStatuses() {
    return eventTaskAxios.get('/picklist?type=ORG_STATUS')

}

function getOrgNodes() {
    return eventTaskAxios.get('/picklist?type=ORG_NODE')
}

function getOrgTypes() {
    return eventTaskAxios.get('/picklist?type=ORG_TYPE')
}

function getOrgVenueCapacity() {
    return eventTaskAxios.get('/picklist?type=VENUE_CAPACITY')
}

function getOrgRelationshipHealth() {
    return eventTaskAxios.get('/picklist?type=ORG_RELATIONSHIP_HEALTH')
}

function getOrgContractstatus() {
    return eventTaskAxios.get('/picklist?type=ORG_RELATIONSHIP_HEALTH')
}

function getAllUsers() {
    return eventTaskAxios.get('/picklist?type=USER')
}

function getContactTypes() {
    return eventTaskAxios.get('/picklist?type=CONTACT_TYPE')
}

function getAccountTypes() {
    return eventTaskAxios.get('/picklist?type=ACCOUNT_TYPE')
}

function getTaskTypes() {
    return eventTaskAxios.get('/picklist?type=TASK_TYPE')
}


function getTaskPriorities() {
    return eventTaskAxios.get('/picklist?type=TASK_PRIORITY')
}

function getTaskStatuses() {
    return eventTaskAxios.get('/picklist?type=TASK_STATUS')
}

function getAllOrganizationsPicklist() {
    return orgAxios.post('/orgs/filter', {
        "sortBy": "id",
        "sortOrder": "DESC",
        "pageSize": 10,
        "pageNo": 1,
        "search": "",
        "type": "",
        "sites":""
    })
}

function getAllActivitesPicklist() {
    return eventTaskAxios.post('/events/filters', {
        "sortBy": "id",
        "sortOrder": "DESC",
        "pageSize": 10,
        "pageNo": 1,
        "search": "",
        "status": "",
        "type": "",
        "format": "",
        "startDate": "",
        "endDate": "",
        "org" : ""
    })
}

function getActivityFocus() {
    return eventTaskAxios.get('/picklist?type=EVENT_FOCUS')
}

function getActivityFormat() {
    return eventTaskAxios.get('/picklist?type=EVENT_FORMAT')
}

function getEventsForOrgs() {
    return eventTaskAxios.get('/picklist?type=TASK_STATUS')
}

function getActivityType() {
    return eventTaskAxios.get('/picklist?type=EVENT_TYPE')
}

function getActivityStatus() {
    return eventTaskAxios.get('/picklist?type=EVENT_STATUS')
}

function postActivityForOrgs(body={}) {
    return eventTaskAxios.post('/picklist?type=EVENTS_FOR_ORGS',body)
}

function postNodesForOrgs(body={}) {
    return eventTaskAxios.post('/picklist?type=NODES_FOR_ORGS', body)
}

function postContactsForOrgs(body={}) {
    return eventTaskAxios.post('/picklist?type=CONTACTS_FOR_ORGS',body)
}

function getReportTypes() {
    return eventTaskAxios.get('/picklist?type=REPORT_TYPE')
}

function getActivityTextCodes() {
    return eventTaskAxios.get('/picklist?type=ACTIVITY_TEXT_CODE')
}

function getTimeZones() {
    return eventTaskAxios.get('/picklist?type=ACTIVITY_TZ')
}

function getVendorByActivity(id) {
    return eventTaskAxios.get('/events/orgsbyevent/' + id + '?pageNo=1&pageSize=10&sortBy=id&sortOrder=DESC');
}

function getAllSites() {
    return siteAxios.get('/dashboard/sites')
}

function getAdminUsers() {
    return siteAxios.get('/dashboard/list/adminUsers');
}