import React, { useEffect, useState } from 'react';
import { Col, Flex, Hr, Row, View } from '@amzn/stencil-react-components/layout';
import { useParams } from 'react-router-dom';
import constants from '../../Utils/Constants';
import { useBreakpoints } from '@amzn/stencil-react-components/responsive';
import {IconArrowLeft, IconBin, IconPencil} from '@amzn/stencil-react-components/icons';
import { Link, LinkIconPosition } from '@amzn/stencil-react-components/link';
import { useNavigate } from 'react-router-dom';
import { Text } from '@amzn/stencil-react-components/text';
import {Button, ButtonSize, ButtonVariant} from '@amzn/stencil-react-components/button';
import { WithModal, ModalContent } from '@amzn/stencil-react-components/modal';
import { TabBar, TabPanel, TabSwitcher, useTabs } from "@amzn/stencil-react-components/tabs";
import AuditLogCards from '../common/AuditTrail/AuditLogCards';
import { Card } from '@amzn/stencil-react-components/card';
import {ParentVendorServices} from "../../Services/parentVendor";
import FullPageCenteredSpinner from "../common/FullPageCenteredSpinner";
import {withAlertSnackBar} from "../../HOComponents/AlertSnackBarHOC";
import {useGetKamsByParentVendor} from "../../ReactQuery/hooks/useGetAllKamRequest";
import { Chip } from '@amzn/stencil-react-components/chip';
import xlsx from "json-as-xlsx";
import {MasterServices} from "../../Services/master";
import {sentenceCase} from "../../Utils/Util";
import {FilePreviewIcon} from "../common/FileIcons";


const propsPaddingMap = {
    s: { padding: { top: "2rem", left: "1rem", right: "1rem", bottom: "2rem" } },
    m: { padding: { top: "2rem", left: "1.5rem", right: "1.5rem", bottom: "2rem" } },
    l: { padding: { top: "2rem", left: "3rem", right: "3rem", bottom: "2rem" } },
    xl: { padding: { top: "2rem", left: "4rem", right: "4rem", bottom: "2rem" } }
}

function siteChipGenerator(kams) {
    const sites = new Set();
    kams.forEach(kam => kam.sites.forEach(site => sites.add(site)));
    return Array.from(sites).map((site,index) => <Chip key={index}>{site}</Chip>);
}

function ViewParentVendor(props) {
    const params = useParams();
    const navigate = useNavigate();
    const { props: paddingProps, matches } = useBreakpoints({ propsMap: propsPaddingMap });
    const tabs = constants.VIEW_VENDOR_TABS;
    const { tabBarProps, tabSwitcherProps } = useTabs({ tabs })
    const [formData, setFormData] = useState({vendorName: "",vendorTypes:[],status:"",vendorId:"",brandingUrl:"",faviconUrl:"",subdomain:""});
    const [loading,setLoading] = useState(false);
    const { data: queryKams, isFetching: fetchingKams } = useGetKamsByParentVendor({
        vendorId : params.vendorId,
        withInactiveKams: "true"
    });


    useEffect(() => {
        if (params.vendorId && !formData.vendorId) {
            getVendorDetailsById(params.vendorId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params.vendorId]);

    const getVendorDetailsById = async (id) => {
        await ParentVendorServices.getParentVendor(id)
            .then((res) => {
                let formDataL = { ...formData };
                formDataL.vendorId = res.data.vendorId;
                formDataL.vendorName = res.data.vendorName;
                formDataL.status = res.data.status;
                formDataL.vendorTypes=res.data.vendorTypes;
                formDataL.brandingUrl = res.data.brandingUrl;
                formDataL.faviconUrl = res.data.faviconUrl;
                formDataL.subdomain = res.data.subdomain;
                setFormData(formDataL);
            })
    };

    const editVendor = () => {
        navigate(`/${constants.PARENTVENDORS.ROUTE.EDIT}/${params.vendorId}`);
    };


    const deleteVendor = async () => {
        setLoading(true);
        await ParentVendorServices.deleteParentVendor(params.vendorId)
            .then((res) => {
                setLoading(false);
                props.snackbarShowMessage(`${constants.CRUD_SUCCESS_ALERTS.DELETED}`,"success",3000,constants.CRUD_SUCCESS_ALERTS.DELETED);
                window.setTimeout(() => {
                    navigate(`/${constants.PARENTVENDORS.ROUTE.MAIN}`);
                }, 2000);
            })
            .catch((error) => {
                setLoading(false);
                props.snackbarShowMessage(
                    `${constants.ERROR.DELETION_ERROR} ${constants.ERROR.PARENT_VENDOR_DELETE_ERROR}`,
                    "error",
                    5000,
                    constants.ERROR.PARENT_VENDOR_DELETE_ERROR
                )
            });
    };

    const updateParentVendorStatus = async() => {
        setLoading(true);
        let body = {
            vendorId : params.vendorId,
            vendorTypes: formData.vendorTypes,
            vendorName: formData.vendorName,
            status: "ACTIVE",
            subdomain: formData.subdomain,
            brandingUrl: formData.brandingUrl,
            faviconUrl: formData.faviconUrl
        }
        await ParentVendorServices.updateParentVendor(body).then((res)=>{
            setLoading(false);
            props.snackbarShowMessage(`${constants.CRUD_SUCCESS_ALERTS.UPDATED}`,"success",3000,constants.CRUD_SUCCESS_ALERTS.UPDATED);
            window.setTimeout(() => {
                navigate(`/${constants.PARENTVENDORS.ROUTE.VIEW}/${params.vendorId}`);
            }, 1000);
        }).catch((error) => {
            setLoading(false);
            props.snackbarShowMessage(`${constants.ERROR.UPDATE_ERROR} ${constants.ERROR.PARENT_VENDOR_UPDATE_ERROR}`,"error",5000,constants.ERROR.PARENT_VENDOR_UPDATE_ERROR);
        });

    }

    const renderModal = ({ close }) => (
        <ModalContent
            titleText="Deactivate Request"
            buttons={[
                <Button onClick={() => { deleteVendor(); close() }} variant={ButtonVariant.Secondary} id="btnHandleDelete" dataTestId="btnHandleDelete">
                    Deactivate
                </Button>,
                <Button onClick={close} variant={ButtonVariant.Primary} id="btnHandleClose" dataTestId="btnHandleClose">
                    Cancel
                </Button>
            ]}>
            <Text>Deactivating the vendor will remove the references against the vendor. Are you sure you want to deactivate this?</Text>
        </ModalContent>
    );

    const kamChipGenerator = (kam, index) => {
        return <Chip key={index} onClick={()=> navigate(`/${constants.ORGANIZATIONS.ROUTE.VIEW}/${kam.vendorKamId}`)}>{kam.vendorKamId}</Chip>
    }

    const downloadKamDetails = async () => {
        let data= []
        let siteList = new Set();
        queryKams.data.kams.forEach((kam)=> {
            data.push({...kam, sites: kam.sites.join(", ")});
            kam.sites.forEach(site => siteList.add(site));
        });
        let schema = constants.VENDOR_KAM_DETAILS_EXCEL_SCHEMA;
        let siteSchema = constants.SITE_DETAILS_SCHEMA;
        let xlsxData = [
            {
                sheet: `Kam Details`,
                columns: schema,
                content: data
            }
            ]
        let settings = {
            fileName: `Kam Details Response`,
            extraLength: 3,
            writeOptions: {width: 30},
        }

        await MasterServices.getAllSites().then(res => {
            let siteData = res.data.filter(item => siteList.has(item.siteId));
            xlsxData.push({
                sheet: `Site Details`,
                columns: siteSchema,
                content: siteData
            });
        })
        await xlsx(xlsxData, settings)
    }

    return (
        (formData.vendorName === "" || loading || fetchingKams) ? <FullPageCenteredSpinner /> :
            <View>
        <Col gridGap="S300" {...paddingProps} >
                <Row display="flex">
                    <Link style={{ cursor: "pointer" }} icon={<IconArrowLeft />} iconPosition={LinkIconPosition.Leading} onClick={() => (navigate(`/${constants.PARENTVENDORS.ROUTE.MAIN}`))} fontWeight="normal" textDecoration="none">Back to vendors list</Link>
                </Row>
                <Row display="flex" justifyContent="space-between" flexWrap="wrap">

                    <Col flexWrap="wrap" gridGap="S200">

                        <Row flexWrap="wrap" gridGap="S200" alignItems="flex-end">
                            <Text fontSize="T500">{formData.vendorName + ','}</Text>
                            <Text fontSize="T300" style={{ marginBottom: "0.2rem" }}>Status: {sentenceCase(formData.status)}</Text>
                        </Row>

                        <Row flexWrap="wrap" gridGap="S200" alignItems="flex-end">
                            <Text fontSize="T200">Vendor Type : {formData.vendorTypes.join(", ")}</Text>
                        </Row>
                    </Col>
                    {(matches.xl || matches.l)
                        ?
                        <Col>
                            <Row gridGap="S200" flexWrap="wrap" style={{ marginTop: "0.8rem" }}>
                                {formData.status !== 'INACTIVE' && <View flex={0.5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <WithModal renderModal={(close) => renderModal(close)} shouldCloseOnClickOutside={false}>
                                        {({ open }) => (<>
                                            <Button id="btnEditRequest" dataTestId="btnDltVendor" icon={<IconBin />} size={ButtonSize.Small} variant={ButtonVariant.Secondary}
                                                    onClick={open} >{constants.BUTTON.DELETE}</Button>
                                            <span hidden id="sroDialog">has pop-up dialog</span>
                                        </>)}
                                    </WithModal>

                                    <Button style={{ marginLeft: '10px' }} id="btnEditRequest" dataTestId="btnEditVendor" icon={<IconPencil />} size={ButtonSize.Small} variant={ButtonVariant.Primary}
                                            onClick={editVendor} >{constants.BUTTON.EDIT}</Button>

                                </View>}
                                {formData.status === 'INACTIVE' && <View flex={0.5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Button style={{ marginLeft: '10px' }} id="btnEditRequest" dataTestId="btnActivate" icon={<IconPencil />} size={ButtonSize.Small} variant={ButtonVariant.Primary}
                                            onClick={updateParentVendorStatus} >{constants.BUTTON.ACTIVATE}</Button>
                                </View>}
                            </Row>
                        </Col>
                        : <></>
                    }
                </Row>
                {matches.m || matches.s
                    ?
                    <Col>
                        <Row gridGap="S200" flexWrap="wrap" style={{ marginTop: "0.8rem" }}>
                            {formData.status !== 'INACTIVE' && <View flex={0.5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <WithModal renderModal={(close) => renderModal(close)} shouldCloseOnClickOutside={false}>
                                    {({ open }) => (<>
                                        <Button id="btnEditRequest" dataTestId="btnEditRequest" icon={<IconBin />} size={ButtonSize.Small} variant={ButtonVariant.Secondary}
                                                onClick={open} >{constants.BUTTON.DELETE}</Button>
                                        <span hidden id="sroDialog">has pop-up dialog</span>
                                    </>)}
                                </WithModal>

                                <Button style={{ marginLeft: '10px' }} id="btnEditRequest" dataTestId="btnEditRequest" icon={<IconPencil />} size={ButtonSize.Small} variant={ButtonVariant.Primary}
                                        onClick={editVendor} >{constants.BUTTON.EDIT}</Button>

                            </View>}
                            {formData.status === 'INACTIVE' && <View flex={0.5} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button style={{ marginLeft: '10px' }} id="btnEditRequest" dataTestId="btnEditRequest" icon={<IconPencil />} size={ButtonSize.Small} variant={ButtonVariant.Primary}
                                        onClick={updateParentVendorStatus} >{constants.BUTTON.ACTIVATE}</Button>
                            </View>}
                        </Row>
                    </Col>
                    : <></>
                }
            </Col>
        <Row flexWrap="wrap" justifyContent="space-between">
                <Col width="100%" >
                    <View style={(matches.l || matches.xl) ? { margin: '0 0 0 4rem' } : { margin: '0 0 0 1rem' }}><TabBar {...tabBarProps} display="flex" justifyContent="space-between" width="100%" /></View>
                    <Hr color="neutral40" style={{ marginTop: "-1px" }} />
                    <TabSwitcher {...tabSwitcherProps} shouldUnmountHiddenPanels={true}>
                        <TabPanel value="Details">
                            <Col flexWrap="wrap" margin={{ top: "2rem", left: "1rem", right: "1rem" }} alignItems="center">
                                <Row flexWrap="wrap" justifyContent="space-between" style={{ width: '80%'}}>
                                    <Col flexWrap="wrap" border="1px solid #B9C0C8" margin={{ top: '1rem' }} style={{ width: matches.s ? '100%' : '100%' }}>
                                        <Card backgroundColor="primary05" border="0 !important" >
                                            <Col gridGap="S100" width='100%'>
                                                <Text fontSize="T300" color="neutral90" fontWeight="bold">Vendor Details</Text>
                                            </Col>
                                        </Card>
                                        <Card border="0 !important" >
                                            <Flex flexDirection= {"column"} alignItems={'baseline'} justifyContent={matches.s ? "space-between" : matches.m ? "space-between" : "none"} gridGap={(matches.s || matches.m) ? "S100" : "S200"} >
                                                <Text fontSize="T200"  color="neutral90">Vendor Name: {formData.vendorName}</Text>
                                                <Text fontSize="T200"  color="neutral90">Vendor Status: {formData.status}</Text>
                                                <Text fontSize="T200"  color="neutral90">Vendor Type: {formData.vendorTypes.join(", ")}</Text>
                                                <Text fontSize="T200"  color="neutral90">Vendor Id: {formData.vendorId}</Text>
                                                <Text fontSize="T200"  color="neutral90">Subdomain: {formData.subdomain}</Text>
                                            </Flex>
                                        </Card>
                                        <Col margin={"1rem"} gridGap={"S100"}>
                                            <Text fontSize="T200"  color="neutral90">Branding : </Text>
                                            <FilePreviewIcon src={formData.brandingUrl} fileName={"Branding"}> </FilePreviewIcon>
                                            {formData.faviconUrl && <>
                                                <Text fontSize="T200" color="neutral90">Favicon : </Text>
                                                <FilePreviewIcon src={formData.faviconUrl} fileName={"Favicon"}> </FilePreviewIcon>
                                            </>}
                                        </Col>
                                    </Col>
                                </Row>

                                <Row flexWrap="wrap" justifyContent="space-between" style={{ width: '80%'}}>
                                    <Col flexWrap="wrap" border="1px solid #B9C0C8" margin={{ top: '1rem' }} style={{ width: matches.s ? '100%' : '100%' }}>
                                        <Card backgroundColor="primary05" border="0 !important" >
                                            <Row gridGap="S100" width='100%' justifyContent={"space-between"} alignItems={"center"}>
                                                <Text fontSize="T300" color="neutral90" fontWeight="bold">Kams</Text>
                                                <Button dataTestId="download-kams" disabled={(queryKams && queryKams.data && queryKams.data.kams.length ===0)} size={ButtonSize.small} onClick={downloadKamDetails}>Download kam & site details</Button>
                                            </Row>
                                        </Card>
                                        <Card border="0 !important" >
                                            {(queryKams && queryKams.data && queryKams.data.kams.length ===0) ?
                                                <Row width={"100%"}><Text fontSize="T200" color="neutral90">No Kams available for the vendor</Text></Row>
                                                : <Flex flexDirection="column" width={"100%"} flexWrap="wrap" gridGap="1rem">
                                                    {queryKams.data.kams.map(kamChipGenerator)}
                                                </Flex>
                                            }
                                        </Card>
                                    </Col>
                                </Row>

                                <Row flexWrap="wrap" justifyContent="space-between" style={{ width: '80%'}}>
                                    <Col flexWrap="wrap" border="1px solid #B9C0C8" margin={{ top: '1rem' }} style={{ width: matches.s ? '100%' : '100%' }}>
                                        <Card backgroundColor="primary05" border="0 !important" >
                                            <Col gridGap="S100" width='100%'>
                                                <Text fontSize="T300" color="neutral90" fontWeight="bold">Sites</Text>
                                            </Col>
                                        </Card>
                                        <Card border="0 !important" >
                                            {(queryKams && queryKams.data && queryKams.data.kams.length ===0) ?
                                                <Row width={"100%"}><Text fontSize="T200" color="neutral90">No Sites available for the vendor</Text></Row>
                                                : <Flex flexDirection="column" width={"100%"} flexWrap="wrap" gridGap="1rem">
                                                    {siteChipGenerator(queryKams.data.kams)}
                                                </Flex>
                                            }

                                        </Card>
                                    </Col>
                                </Row>
                            </Col>
                        </TabPanel>
                        <TabPanel value="Audit logs">
                            <Col flexWrap="wrap" margin={{ top: "2rem", left: "1rem", right: "1rem" }} alignItems="center">
                                <AuditLogCards config={{ vendorKamId: params.vendorId, vendorId: params.vendorId, entityType: "PARENT_VENDOR" }}
                                               textForFirstLog={constants.PARENTVENDORS.TEXT_FOR_FIRST_AUDIT_LOG} />
                            </Col>
                        </TabPanel>
                    </TabSwitcher>
                </Col>
            </Row>
        </View>
    )
}

export default withAlertSnackBar(ViewParentVendor);