
export function compareVendor(sortColumn,order) {
    let sortOrder = 1;
    if(order === "desc"){
        sortOrder = -1;
    }
    return function (sortValue1, sortValue2){
        let column1 =sortValue1[sortColumn];
        let column2 = sortValue2[sortColumn];
        if(sortColumn==='name'||sortColumn==='email'){
            column1 = column1.toLowerCase();
            column2 = column2.toLowerCase();
        }
        if (column1 < column2) {
            return -1 * sortOrder;
        } else if (column1 > column2) {
            return 1 * sortOrder;
        } else {
            return 0 * sortOrder;
        }

    }
}
