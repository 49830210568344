export const COMMON_REGEX = {
    PHONE_NUMBER : {
        NINE_DIGIT : '^[0-9]{9}$',
        TEN_DIGIT : '^[1-9][0-9]{9}$',
        TEN_DIGIT_ZERO_START : '^[0-9]{10}$',
    },
    POSTAL_CODES : {
        FIVE_DIGIT : '^[0-9]{5}$',
        SIX_DIGIT : '^[1-9][0-9]{5}$'
    },
    ZEROS : '^(?!0).*$',
    MENA_ADDRESS_PATTERN : '^[A-Za-z0-9][-A-Za-z0-9., &/\']*$',
    NUMERIC_PATTERN : '^[0-9]*$',
    ALPHA_PATTERN : '^[a-zA-z ]*$'
}

let constants = {
    SITE: {
        TITLE:'Community Engagement CRM Amazon',
        BASE_URL: process.env.REACT_APP_BASE_URL,
        VERSION: process.env.REACT_APP_VERSION,
    },
    BUTTON: {
        CREATE: 'Create new',
        FILTER: 'Filter',
        DELETE: 'Deactivate',
        DECLINE: 'Decline',
        ACCEPT: 'Accept',
        APPROVE: 'Approve',
        REJECT: 'Reject',
        CANCEL: 'Cancel',
        SAVE: 'Save',
        EDIT: 'Edit',
        CREATE_RELATED: 'Create Related',
        ACTIVATE: 'Activate'
    },
    DEVICE: {MOBILE: 'mobile', DESKTOP: 'desktop'},
    ORGANIZATIONS: {
        TITLE: 'Kams',
        TITLE_DETAILS: 'Vendor info',
        TITLE_SINGULAR: 'Vendor KAM',
        SEARCH_PLACEHOLDER: 'Search Vendor Kams By Name',
        ROUTE_GRID: 'Back to Grid',
        ROUTE: {
            MAIN: 'vendorkams',
            VIEW: 'vendorkams/view-vendor',
            CREATE: '/create-vendor',
            CREATE_RELATED: 'vendorkams/create-related-vendor',
            TITLE_SINGULAR: 'vendor',
            EDIT: 'vendorkams/edit-vendor'
        },
        TEXT_FOR_FIRST_AUDIT_LOG: "Vendor Key Account Manager(KAM) is created with ID:",
        VENDOR_STATUS:{
            OPEN:'ACTIVE',
            DELETED:'INACTIVE'
        }
    },
    PARENTVENDORS:{
        TITLE: 'Vendors',
        ROUTE:{
            MAIN:'vendors',
            VIEW:'vendors/view',
            CREATE:'vendors/create',
            CREATE_PATH: 'create',
            EDIT: 'vendors/edit'
        },
        TEXT_FOR_FIRST_AUDIT_LOG: "Parent Vendor is created with ID:",
    },
    VENDORKAMREQUESTS: {
        TITLE: 'KAM Requests',
        TITLE_DETAILS: 'Vendor KAM info',
        TITLE_SINGULAR: 'KAM Request',
        SEARCH_PLACEHOLDER: 'Search Request By KAM Name',
        ROUTE_GRID: 'Back to Grid',
        ROUTE: {
            MAIN: 'requests',
            VIEW: 'request/view-request',
            TITLE_SINGULAR: 'request',
            EDIT: 'requests/edit-request'
        },
        TEXT_FOR_FIRST_AUDIT_LOG: "Request to create vendor KAM raised by"
    },
    VIEW_VENDOR_TABS : ["Details","Audit logs"],
    TABS : ["Details","Audit logs"],
    STATUS: [
      "OPEN",
      "DECLINED",
      "ACCEPTED",
      "INACTIVE"
    ],
    STATUS_PREFERENCE_CODES : {
        OPEN : 0,
        ACCEPTED : 1,
        DECLINED : 2,
        DELETED : 3
    },
    CONTACTS: {
        TITLE: 'Contacts',
        TITLE_SINGULAR: 'Contact',
        SEARCH_PLACEHOLDER: 'Search Contact name',
        ROUTE: {
            MAIN: 'contacts',
            VIEW: 'contacts/view-contact',
            CREATE: 'contacts/create-contact',
            CREATE_RELATED: 'contacts/create-related-contact',
            TITLE_SINGULAR: 'contact',
            EDIT: 'contacts/edit-contact'
        }
    },
    ACTIVITIES: {
        TITLE: 'Activities',
        TITLE_SINGULAR: 'Activity',
        SEARCH_PLACEHOLDER: 'Search Activity name',
        ROUTE: {
            MAIN: 'activities',
            VIEW: 'activities/view-activity',
            CREATE: 'activities/create-activity',
            CREATE_RELATED: 'activities/create-related-activity',
            TITLE_SINGULAR: 'activity',
            EDIT: 'activities/edit-activity'
        }
    },
    TASKS: {
        TITLE: 'Tasks',
        TITLE_SINGULAR: 'Task',
        SEARCH_PLACEHOLDER: 'Search Task subject',
        ROUTE: {
            MAIN: 'tasks',
            VIEW: 'tasks/view-task',
            CREATE: 'tasks/create-task',
            CREATE_RELATED: 'tasks/create-related-task',
            TITLE_SINGULAR: 'task',
            EDIT: 'tasks/edit-task'
        }
    },
    REPORTS: {
        TITLE: 'Reports',
        TITLE_SINGULAR: 'Report',
        ROUTE: {
            MAIN: 'reports'
        }
    },
    AUDIT_TRAIL: {
        TITLE: 'Audit logs',
        SEARCH_PLACEHOLDER: 'Search logs By Alias/Username',
        ROUTE: {
            MAIN: 'auditLogs',
        },
        ENTITY_TYPE:{
            KAM_REQUEST:'KAM Request',
            VENDOR_KAM: 'Vendor KAM'
        },
        EVENT_TYPE_INSERT:'INSERT'
    },
    CALENDAR: {
        TITLE_SINGULAR: 'Calendar',
        ROUTE: {
            MAIN: 'calendar'
        }
    },
    ADMIN: {
        TITLE_SINGULAR: 'Admin',
        ROUTE: {
            MAIN: 'admin'
        }
    },
    REACT_QUERY: {
        QUERY_KEY: {
            GET_ALL_AUDIT_LOGS: "getAllAuditLogs",
            GET_VENDORS: "getTotalOrganizations",
            GET_ALL_KAM_REQUESTS: "getTotalKamRequests",
            GET_ALL_PARENT_VENDORS: "getAllParentVendors",
            DOWNLOAD_CONSTRUCT: "downloadConstruct",
            GET_KAMS_BY_PARENT_VENDOR: "getKamsByParentVendor"
        },
        LOADING_ICON_URLS:[
            '/orgs/getAuditLogs',
            '/orgs/getKamsByParentVendor',
            '/vendors',
            '/orgs/getConfigforCreateVendor',
            '/dashboard/list/adminUsers'
        ]
    },
    FORM_DETAILS: {
        BASIC: 'Basic Information:',
        DETAILS: 'Details:',
        LOCATION: 'Location & Contact Information:',
        FOR_REFERENCE: 'For Reference:',
        CLRC_DETAILS: 'CLRC Details:',
        CONTRACT_DETAILS: 'Contract Details:',
        RELATED_ITEMS: 'Related Items:',
        COMMENTS: 'Recent Comments'
    },
    FILTERS: {
        EXPORT_SEARCH: 'Export Search Results',
        EXPORT_CSV: 'Export to CSV',
        EXPORT_EXCEL: 'Export to Excel',
        ORG_TYPES: [{ "id": "Manpower", "name": "Manpower", is_selected: false }, { "id": "BGV", "name": "BGV", is_selected: false }, { "id": "Biometric", "name": "Biometric", is_selected: false }],
        ORG_CONTRACT_STATUS: [{ "id": "Expired", "name": "Expired", is_selected: false }, { "id": "Active", "name": "Active", is_selected: false }],
        ORG_COUNTRIES: [{ "id": "India", "name": "India", is_selected: false }, { "id": "United Arab Emirates", "name": "United Arab Emirates", is_selected: false },{ "id": "Saudi Arabia", "name": "Saudi Arabia", is_selected: false },{ "id": "Egypt", "name": "Egypt", is_selected: false }],
        VENDOR_STATUS: [{"id":"ACTIVE","name":"ACTIVE",is_selected:false},{"id":"INACTIVE","name":"INACTIVE",is_selected:false}],
        VENDOR_TYPES: [{ "id": "Manpower", "name": "Manpower", is_selected: false }, { "id": "BGV", "name": "BGV", is_selected: false }, { "id": "Biometric", "name": "Biometric", is_selected: false }]
    },
    MONTHS : [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec"],
    SEARCH_PLACEHOLDER: {
        ORGANIZATIONS: 'Search Vendor Name',
        CONTACTS: 'Search Contact Name',
        TASKS: 'Search Task Subject',
        ACTIVITIES: 'Search Activity Name'
    },
    ERROR: {
        PREPOPULATE_ERROR: 'Something went wrong, unable to fetch data',
        UPDATE_ERROR: 'Updation error:',
        CREATE_ERROR: 'Creation error:',
        GENERATE_ERROR: 'Generate error:',
        FETCHING_ERROR: 'Fetching error:',
        FILTER_ERROR: 'Filter error:',
        EXPORT_ERROR: 'Export error:',
        DELETE_ERROR: 'Vendor has active requisitions. Please delete active requisitions before deleting the Vendor',
        PARENT_VENDOR_DELETE_ERROR : 'Unable to Delete vendor.',
        PARENT_VENDOR_UPDATE_ERROR : 'Unable to Update vendor.',
        DELETION_ERROR : 'Deactivation error:',
        DECLINE_ERROR: 'Vednor KAM Request Decline error:',
        PORTAL_ACCESS_ERROR : "User not authorized to access portal",
        VENDOR_NAME_FIELD_EMPTY_ERROR : "Vendor Name cannot be empty",
        VENDOR_ID_FIELD_EMPTY_ERROR: "Vendor Id cannot be empty",
        VENDOR_TYPE_FIELD_EMPTY_ERROR : "Vendor Type cannot be empty",
        VENDOR_NAME_INVALID :"Vendor name should be alpha numeric and less than 50 characters",
        VENDOR_EXISTS: "Vendor Id already exists",
        SUBDOMAIN_EXISTS: "Vendor with subdomain already exists",
        SUBDOMAIN_EMPTY: "Subdomain cannot be empty",
        S3_UPLOAD_ERROR: "Unable to upload image, please try again",
        VENDOR_NAME_EXISTS : "Vendor Name already exists",
        VENDOR_ID_INVALID: "Vendor Id should be alpha numeric and less than 10 characters",
        CONFIG_LOADING: "Error while fetching config, please refresh the page."
    },
    CRUD_SUCCESS_ALERTS: {
        APPROVED: 'KAM Created successfully',
        CREATED: 'Created successfully',
        UPDATED: 'Updated successfully',
        DELETED: 'Deactivated successfully',
        DECLINE: 'Declined successfully'
    },
    CRUD_WARNING_ALERTS: {
        CREATED: 'successfully created.'
    },
    CRUD_DANGER_ALERTS: {
        CREATED: 'successfully created.'
    },
    AUTH: {
        SIGNIN_SUCCESS: 'Sign-in Successfull',
        SIGNIN_FAILED: 'Midway Authentication Failed, You may not authroized to use this application. Please reach out to HVH CECRM group'
    },
    PATTERN : {
        POSTAL_CODE_PATTERN : '^[1-9][0-9]{5}$',
        PHONE_NUMBER_PATTERN : '^[1-9][0-9]{9}$',
        EMAIL_ADDRESS_PATTERN : /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i,
        ALPHA_NUMERIC_PATTERN : '^([0-9 ]*[a-zA-Z ]+[0-9 ]*)*$',
        NUMERIC_PATTERN : '^[0-9]*$',
        ALPHA_PATTERN : '^[a-zA-z ]*$',
        ALPHA_NUMERIC_DOT_PATTERN : '^([0-9 ]*[a-zA-Z.]+[0-9 ]*)*$',
        PHONE_NUMBERS : {
            'India' : [COMMON_REGEX.PHONE_NUMBER.TEN_DIGIT],
            'Saudi Arabia' : [COMMON_REGEX.PHONE_NUMBER.NINE_DIGIT,COMMON_REGEX.ZEROS],
            'United Arab Emirates' : [COMMON_REGEX.PHONE_NUMBER.NINE_DIGIT,COMMON_REGEX.ZEROS],
            'Egypt' : [COMMON_REGEX.PHONE_NUMBER.TEN_DIGIT_ZERO_START,COMMON_REGEX.ZEROS],
        },
        POSTAL_CODES : {
            'India' : [COMMON_REGEX.POSTAL_CODES.SIX_DIGIT],
            'Saudi Arabia' : [COMMON_REGEX.POSTAL_CODES.FIVE_DIGIT,COMMON_REGEX.ZEROS],
            'Egypt' : [COMMON_REGEX.POSTAL_CODES.FIVE_DIGIT,COMMON_REGEX.ZEROS],
            'United Arab Emirates' : []
        },
        ADDRESS_PATTERNS : {
            'India' : [COMMON_REGEX.ALPHA_PATTERN],
            'Saudi Arabia' : [COMMON_REGEX.MENA_ADDRESS_PATTERN],
            'United Arab Emirates' : [COMMON_REGEX.MENA_ADDRESS_PATTERN],
            'Egypt' : [COMMON_REGEX.MENA_ADDRESS_PATTERN]
        },
        STATE_PATTERNS : {
            'India' : [],
            'Saudi Arabia' : [COMMON_REGEX.MENA_ADDRESS_PATTERN],
            'United Arab Emirates' : [],
            'Egypt' : [COMMON_REGEX.MENA_ADDRESS_PATTERN]
        },
        DISTRICT_PATTERNS : {
            'India' : [],
            'Saudi Arabia' : [COMMON_REGEX.MENA_ADDRESS_PATTERN],
            'United Arab Emirates' : [COMMON_REGEX.MENA_ADDRESS_PATTERN],
            'Egypt' : [COMMON_REGEX.MENA_ADDRESS_PATTERN]
        }
    },
    ARRAYS: {
        BUSINESS_LINES : [{"id": "AIR", "name":"AIR", is_selected: false}, {"id": "AMZL", "name":"AMZL", is_selected: false}, {"id": "ATS", "name":"ATS", is_selected: false}, {"id": "FC", "name":"FC", is_selected: false}, {"id": "GSF", "name":"GSF", is_selected: false}],
        JOB_ROLES : [{"id": "Warehouse Associate", "name":"Warehouse Associate", is_selected: false}, {"id": "Hourly Team Member", "name":"Hourly Team Member", is_selected: false}, {"id": "Locker + Customer Service Associate", "name":"Locker + Customer Service Associate", is_selected: false}, {"id": "Fulfillment Center Warehouse Associate", "name":"Fulfillment Center Warehouse Associate", is_selected: false}, {"id": "Grocery Associate", "name":"Grocery Associate", is_selected: false}, {"id": "Delivery Station Warehouse Associate", "name":"Delivery Station Warehouse Associate", is_selected: false}, {"id": "Retail Associate", "name":"Retail Associate", is_selected: false}, {"id": "Grocery Shopper", "name":"Grocery Shopper", is_selected: false}
            , {"id": "Amazon Air Associate", "name":"Amazon Air Associate", is_selected: false}, {"id": "Sortation Center Warehouse Associate", "name":"Sortation Center Warehouse Associate", is_selected: false}, {"id": "Warehouse Team Member", "name":"Warehouse Team Member", is_selected: false}, {"id": "Grocery Team Member", "name":"Grocery Team Member", is_selected: false}, {"id": "Fresh Warehouse Associate", "name":"Fresh Warehouse Associate", is_selected: false}, {"id": "Central Operations Associate", "name":"Central Operations Associate", is_selected: false}],
        JOB_TYPES : [{"id": "Part Time", "name": "Part Time", is_selected:false},{"id": "Full Time", "name": "Full Time", is_selected:false},{"id": "Part Time ALFA", "name": "Part Time ALFA", is_selected:false}, {"id": "Full Time ALFA", "name": "Full Time ALFA", is_selected:false}],
        REASON : [
            {"id": "Vendor KAM Name is incorrect", "name": "Vendor KAM Name is incorrect", is_selected:false},
            {"id": "Vendor KAM correspondence email is incorrect", "name": "Vendor KAM correspondence email is incorrect", is_selected:false},
            {"id": "Vendor KAM phone is incorrect", "name": "Vendor KAM phone is incorrect", is_selected:false},
            {"id": "Requested sites are incorrect with this vendor KAM request", "name": "Requested sites are incorrect with this vendor KAM request", is_selected:false},
            {"id": "Vendor Kam address is incorrect", "name": "Vendor Kam address is incorrect", is_selected: false},
            {"id": "Other", "name": "Other", is_selected: false}
        ]
    },
    SPECTROMETER : {
        APP_NAME: "VendorManagementUI",
        SITE_NAME: "VendorManagementPortal",
        STAGE:"stage",
    },
    VENDOR_KAM_DETAILS_EXCEL_SCHEMA : [
        {
            label: 'Vendor Kam Id',
            value: 'vendorKamId'
        },
        {
            label: 'Vendor Kam Name',
            value: 'vendorKamName'
        },
        {
            label: 'Email',
            value: 'correspondenceEmail'
        },
        {
            label: 'Phone Number',
            value: 'phone'
        },
        {
            label: 'Kam Status',
            value: 'vendorStatus'
        },
        {
            label: 'Sites',
            value: 'sites'
        }
    ],
    SITE_DETAILS_SCHEMA : [
        {
            label: 'Site Id',
            value: 'siteId'
        },
        {
            label: 'Site Status',
            value: 'status'
        },
        {
            label: 'Region',
            value: 'region'
        },
        {
            label: 'Business Line',
            value: 'businessLine'
        },
        {
            label: 'Business Category',
            value: 'businessCategory'
        }
    ]
}

export default constants

export const AP_STATES = [
    "Andaman and Nicobar Islands",
    "Andhra Pradesh",
    "Arunachal Pradesh",
    "Assam",
    "Bihar",
    "Chandigarh",
    "Chhattisgarh",
    "Dadra & Nagar Haveli and Daman & Diu",
    "Delhi",
    "Goa",
    "Gujarat",
    "Haryana",
    "Himachal Pradesh",
    "Jammu and Kashmir",
    "Jharkhand",
    "Karnataka",
    "Kerala",
    "Ladakh",
    "Lakshadweep",
    "Madhya Pradesh",
    "Maharashtra",
    "Manipur",
    "Meghalaya",
    "Mizoram",
    "Nagaland",
    "Odisha",
    "Puducherry",
    "Punjab",
    "Rajasthan",
    "Sikkim",
    "Tamil Nadu",
    "Telangana",
    "Tripura",
    "Uttar Pradesh",
    "Uttarakhand",
    "West Bengal",
];

export const AP_VENDOR_ID ={
    "AASAANJOBS PVT LTD": "AASA",
    "ADONIS STAFF SERVICES PVT LTD": "ADON",
    "BUZZWORKS": "BUZZ",
    "Futurz Staffing Solutions Pvt Ltd": "FUTU",
    "Global Innovsource Solutions": "GISN",
    "Ikya Human Capital Solutions Pvt. Ltd.": "IKYA",
    "K L Technical Services": "KLTS",
    "Kay Cee Enterprises": "KCES",
    "Krishna Facilities Management Services": "KFMS",
    "Million Minds": "MIMS",
    "Needs Manpower Support Services Private Ltd": "NMSS",
    "NOVHO CAREERS PVT LTD": "NOVH",
    "Om Enterprises": "OME",
    "Peregrine Guarding Private Limited": "PGPL",
    "Pranav Associates": "PRNV",
    "PSN SUPPLY CHAIN SOLUTIONS PRIVATE LIMITED": "PNS",
    "QUESS CORP LIMITED": "QCL1",
    "RAKSHAK SUBIDHA PRIVATE LIMITED": "RKSP",
    "Reliable First Adcon": "REFA",
    "Reliable Labour Organisation": "RILO",
    "RR INDIA PVT LTD": "RRIN",
    "S&IB Services Private Limited": "S&IB",
    "SAAR Integrated Services Private Limited": "SAAR",
    "Simran Enterprises": "SIME",
    "SPNN BUSINESS SERVICES PVT LTD": "SPNN",
    "TDS Placements & Services Private Limited": "TDSP",
    "TeamLease Services Limited": "TEAM",
    "Watermelon Management Services Pvt Ltd": "WATE",
    "Weavings Manpower Solutions Pvt. Ltd.": "WEAV",
    "Weavings": "WEA",
    "NETAMBIT VALUE FIRST SERVICES PVT LTD": "NVFS",
    "JARI KART PVT LTD": "JARI",
};

export const AP_STATE_DISTRICT_MAPPING = {
    "Andaman and Nicobar Islands" : ["Nicobar", "North Middle Andaman", "South Andaman"],
    "Andhra Pradesh" : ["Anantapur", "Chittoor", "East Godavari", "Guntur", "Kadapa", "Krishna", "Kurnool", "Nellore", "Prakasam", "Srikakulam", "Visakhapatnam", "Vizianagaram", "West Godavari"],
    "Arunachal Pradesh" : ["Anjaw", "Siang", "Changlang", "Dibang Valley", "East Kameng", "East Siang", "Kamle", "Kra Daadi", "Kurung Kumey", "Lepa Rada", "Lohit", "Longding", "Lower Dibang Valley", "Lower Siang", "Lower Subansiri", "Namsai", "Pakke Kessang", "Papum Pare", "Shi Yomi", "Tawang", "Tirap", "Upper Siang", "Upper Subansiri", "West Kameng", "West Siang"],
    "Assam" : ["Bajali", "Baksa", "Barpeta", "Biswanath", "Bongaigaon", "Cachar", "Charaideo", "Chirang", "Darrang", "Dhemaji", "Dhubri", "Dibrugarh", "Dima Hasao", "Goalpara", "Golaghat", "Hailakandi", "Hojai", "Jorhat", "Kamrup", "Kamrup Metropolitan", "Karbi Anglong", "Karimganj", "Kokrajhar", "Lakhimpur", "Majuli", "Morigaon", "Nagaon", "Nalbari", "Sivasagar", "Sonitpur", "South Salmara - Mankachar", "Tinsukia", "Udalguri", "West Karbi Anglong"],
    "Bihar" : ["Araria", "Arwal", "Aurangabad", "Banka", "Begusarai", "Bhagalpur", "Bhojpur", "Buxar", "Darbhanga", "East Champaran", "Gaya", "Gopalganj", "Jamui", "Jehanabad", "Kaimur", "Katihar", "Khagaria", "Kishanganj", "Lakhisarai", "Madhepura", "Madhubani", "Munger", "Muzaffarpur", "Nalanda", "Nawada", "Patna", "Purnia", "Rohtas", "Saharsa", "Samastipur", "Saran", "Sheikhpura", "Sheohar", "Sitamarhi", "Siwan", "Supaul", "Vaishali"],
    "Chandigarh" : ["Chandigarh"],
    "Chhattisgarh" : ["Balod", "Baloda Bazar", "Balrampur", "Bastar", "Bemetara", "Bijapur", "Bilaspur", "Dantewada", "Dhamtari", "Durg", "Gariaband", "Gaurela Pendra Marwahi", "Janjgir Champa", "Jashpur", "Kabirdham", "Kanker", "Kondagaon", "Korba", "Koriya", "Mahasamund", "Mungeli", "Narayanpur", "Raigarh", "Raipur", "Rajnandgaon", "Sukma", "Surajpur", "Surguja"],
    "Dadra & Nagar Haveli and Daman & Diu" : ["Dadra and Nagar Haveli", "Daman", "Diu"],
    "Delhi" : ["Central Delhi", "East Delhi", "New Delhi", "North Delhi", "North East Delhi", "North West Delhi", "Shahdara", "South Delhi", "South East Delhi", "South West Delhi", "West Delhi"],
    "Goa" : ["North Goa", "South Goa"],
    "Gujarat" : ["Ahmedabad", "Amreli", "Anand", "Aravalli", "Banaskantha", "Bharuch", "Bhavnagar", "Botad", "Chhota Udaipur", "Dahod", "Dang", "Devbhoomi Dwarka", "Gandhinagar", "Gir Somnath", "Jamnagar", "Junagadh", "Kheda", "Kutch", "Mahisagar", "Mehsana", "Morbi", "Narmada", "Navsari", "Panchmahal", "Patan", "Porbandar", "Rajkot", "Sabarkantha", "Surat", "Surendranagar", "Tapi", "Vadodara", "Valsad"],
    "Haryana" : ["Ambala", "Bhiwani", "Charkhi Dadri", "Faridabad", "Fatehabad", "Gurugram", "Hisar", "Jhajjar", "Jind", "Kaithal", "Karnal", "Kurukshetra", "Mahendragarh", "Mewat", "Palwal", "Panchkula", "Panipat", "Rewari", "Rohtak", "Sirsa", "Sonipat", "Yamunanagar"],
    "Himachal Pradesh" : ["Bilaspur", "Chamba", "Hamirpur", "Kangra", "Kinnaur", "Kullu", "Lahaul Spiti", "Mandi", "Shimla", "Sirmaur", "Solan", "Una"],
    "Jammu and Kashmir" : ["Anantnag", "Bandipora", "Baramulla", "Budgam", "Doda", "Ganderbal", "Jammu", "Kathua", "Kishtwar", "Kulgam", "Kupwara", "Poonch", "Pulwama", "Rajouri", "Ramban", "Reasi", "Samba", "Shopian", "Srinagar", "Udhampur"],
    "Jharkhand" : ["Bokaro", "Chatra", "Deoghar", "Dhanbad", "Dumka", "East Singhbhum", "Garhwa", "Giridih", "Godda", "Gumla", "Hazaribagh", "Jamtara", "Khunti", "Koderma", "Latehar", "Lohardaga", "Pakur", "Palamu", "Ramgarh", "Ranchi", "Sahebganj", "Seraikela Kharsawan", "Simdega", "West Singhbhum"],
    "Karnataka" : ["Bagalkot", "Bangalore Rural", "Bangalore Urban", "Belgaum", "Bellary", "Bidar", "Chamarajanagar", "Chikkaballapur", "Chikkamagaluru", "Chitradurga", "Dakshina Kannada", "Davanagere", "Dharwad", "Gadag", "Gulbarga", "Hassan", "Haveri", "Kodagu", "Kolar", "Koppal", "Mandya", "Mysore", "Raichur", "Ramanagara", "Shimoga", "Tumkur", "Udupi", "Uttara Kannada", "Vijayanagara", "Vijayapura", "Yadgir"],
    "Kerala" : ["Alappuzha", "Ernakulam", "Idukki", "Kannur", "Kasaragod", "Kollam", "Kottayam", "Kozhikode",
        "Malappuram", "Palakkad", "Pathanamthitta", "Thiruvananthapuram", "Thrissur", "Wayanad"],
    "Ladakh" : ["Kargil", "Leh"],
    "Lakshadweep" : ["Lakshadweep"],
    "Madhya Pradesh" : ["Agar Malwa", "Alirajpur", "Anuppur", "Ashoknagar", "Balaghat", "Barwani", "Betul", "Bhind", "Bhopal", "Burhanpur", "Chachaura", "Chhatarpur", "Chhindwara", "Damoh", "Datia", "Dewas", "Dhar", "Dindori", "Guna", "Gwalior", "Harda", "Hoshangabad", "Indore", "Jabalpur", "Jhabua", "Katni", "Khandwa", "Khargone", "Maihar", "Mandla", "Mandsaur", "Morena", "Narsinghpur", "Nagda", "Neemuch", "Niwari", "Panna", "Raisen", "Rajgarh", "Ratlam", "Rewa", "Sagar", "Satna", "Sehore", "Seoni", "Shahdol", "Shajapur", "Sheopur", "Shivpuri", "Sidhi", "Singrauli", "Tikamgarh", "Ujjain", "Umaria", "Vidisha"],
    "Maharashtra" : ["Ahmednagar", "Akola", "Amravati", "Aurangabad", "Beed", "Bhandara", "Buldhana", "Chandrapur", "Dhule", "Gadchiroli", "Gondia", "Hingoli", "Jalgaon", "Jalna", "Kolhapur", "Latur", "Mumbai City", "Mumbai Suburban", "Nagpur", "Nanded", "Nandurbar", "Nashik", "Osmanabad", "Palghar", "Parbhani", "Pune", "Raigad", "Ratnagiri", "Sangli", "Satara", "Sindhudurg", "Solapur", "Thane", "Wardha", "Washim", "Yavatmal"],
    "Manipur" : ["Bishnupur", "Chandel", "Churachandpur", "Imphal East", "Imphal West", "Jiribam",
        "Kakching", "Kamjong", "Kangpokpi", "Noney", "Pherzawl", "Senapati", "Tamenglong", "Tengnoupal", "Thoubal", "Ukhrul"],
    "Meghalaya" : ["East Garo Hills", "East Jaintia Hills", "East Khasi Hills", "North Garo Hills", "Ri Bhoi", "South Garo Hills", "South West Garo Hills", "South West Khasi Hills", "West Garo Hills", "West Jaintia Hills", "West Khasi Hills"],
    "Mizoram" : ["Aizawl", "Champhai", "Hnahthial", "Kolasib", "Khawzawl", "Lawngtlai", "Lunglei", "Mamit", "Saiha", "Serchhip", "Saitual"],
    "Nagaland" : ["Mon", "Dimapur", "Kiphire", "Kohima", "Longleng", "Mokokchung", "Noklak", "Peren",
        "Phek", "Tuensang", "Wokha", "Zunheboto"],
    "Odisha" : ["Angul", "Balangir", "Balasore", "Bargarh", "Bhadrak", "Boudh", "Cuttack", "Debagarh", "Dhenkanal", "Gajapati", "Ganjam", "Jagatsinghpur", "Jajpur", "Jharsuguda", "Kalahandi", "Kandhamal", "Kendrapara", "Kendujhar", "Khordha", "Koraput", "Malkangiri", "Mayurbhanj", "Nabarangpur", "Nayagarh", "Nuapada", "Puri", "Rayagada", "Sambalpur", "Subarnapur", "Sundergarh"],
    "Puducherry" : ["Karaikal", "Mahe", "Puducherry", "Yanam"],
    "Punjab" : ["Amritsar", "Barnala", "Bathinda", "Faridkot", "Fatehgarh Sahib", "Fazilka", "Firozpur", "Gurdaspur", "Hoshiarpur", "Jalandhar", "Kapurthala", "Ludhiana", "Malerkotla", "Mansa", "Moga", "Mohali", "Muktsar", "Pathankot", "Patiala", "Rupnagar", "Sangrur", "Shaheed Bhagat Singh Nagar", "Tarn Taran"],
    "Rajasthan" : ["Ajmer", "Alwar", "Banswara", "Baran", "Barmer", "Bharatpur", "Bhilwara", "Bikaner", "Bundi", "Chittorgarh", "Churu", "Dausa", "Dholpur", "Dungarpur", "Hanumangarh", "Jaipur", "Jaisalmer", "Jalore", "Jhalawar", "Jhunjhunu", "Jodhpur", "Karauli", "Kota", "Nagaur", "Pali", "Pratapgarh", "Rajsamand", "Sawai Madhopur", "Sikar", "Sirohi", "Sri Ganganagar", "Tonk", "Udaipur"],
    "Sikkim" : ["East Sikkim", "North Sikkim", "South Sikkim", "West Sikkim"],
    "Tamil Nadu" : ["Ariyalur", "Chengalpattu", "Chennai", "Coimbatore", "Cuddalore", "Dharmapuri", "Dindigul", "Erode", "Kallakurichi", "Kanchipuram", "Kanyakumari", "Karur", "Krishnagiri", "Madurai", "Mayiladuthurai", "Nagapattinam", "Namakkal", "Nilgiris", "Perambalur", "Pudukkottai", "Ramanathapuram", "Ranipet", "Salem", "Sivaganga", "Tenkasi", "Thanjavur", "Theni", "Thoothukudi", "Tiruchirappalli", "Tirunelveli", "Tirupattur", "Tiruppur", "Tiruvallur", "Tiruvannamalai", "Tiruvarur", "Vellore", "Viluppuram", "Virudhunagar"],
    "Telangana" : ["Adilabad", "Bhadradri Kothagudem", "Hyderabad", "Jagtial", "Jangaon", "Jayashankar", "Jogulamba", "Kamareddy", "Karimnagar", "Khammam", "Komaram Bheem", "Mahabubabad", "Mahbubnagar", "Mancherial", "Medak", "Medchal", "Mulugu", "Nagarkurnool", "Nalgonda", "Narayanpet", "Nirmal", "Nizamabad", "Peddapalli", "Rajanna Sircilla", "Ranga Reddy", "Sangareddy", "Siddipet", "Suryapet", "Vikarabad", "Wanaparthy", "Warangal", "Hanamkonda", "Yadadri Bhuvanagiri"],
    "Tripura" : ["Dhalai", "Gomati", "Khowai", "North Tripura", "Sepahijala",
        "South Tripura", "Unakoti", "West Tripura"],
    "Uttar Pradesh" : ["Agra", "Aligarh", "Ambedkar Nagar", "Amethi", "Amroha", "Auraiya", "Ayodhya", "Azamgarh", "Baghpat", "Bahraich", "Ballia", "Balrampur", "Banda", "Barabanki", "Bareilly", "Basti", "Bhadohi", "Bijnor", "Budaun", "Bulandshahr", "Chandauli", "Chitrakoot", "Deoria", "Etah", "Etawah", "Farrukhabad", "Fatehpur", "Firozabad", "Gautam Buddha Nagar", "Ghaziabad", "Ghazipur", "Gonda", "Gorakhpur", "Hamirpur", "Hapur", "Hardoi", "Hathras", "Jalaun", "Jaunpur", "Jhansi", "Kannauj", "Kanpur Dehat", "Kanpur Nagar", "Kasganj", "Kaushambi", "Kheri", "Kushinagar", "Lalitpur", "Lucknow", "Maharajganj", "Mahoba", "Mainpuri", "Mathura", "Mau", "Meerut", "Mirzapur", "Moradabad", "Muzaffarnagar", "Pilibhit", "Pratapgarh", "Prayagraj", "Raebareli", "Rampur", "Saharanpur", "Sambhal", "Sant Kabir Nagar", "Shahjahanpur", "Shamli", "Shravasti", "Siddharthnagar", "Sitapur", "Sonbhadra", "Sultanpur", "Unnao", "Varanasi"],
    "Uttarakhand" : ["Almora", "Bageshwar", "Chamoli", "Champawat", "Dehradun", "Haridwar", "Nainital", "Pauri", "Pithoragarh", "Rudraprayag", "Tehri", "Udham Singh Nagar", "Uttarkashi"],
    "West Bengal" : ["Alipurduar", "Bankura", "Birbhum", "Cooch Behar", "Dakshin Dinajpur", "Darjeeling", "Hooghly", "Howrah", "Jalpaiguri", "Jhargram", "Kalimpong", "Kolkata", "Malda", "Murshidabad", "Nadia", "North 24 Parganas", "Paschim Bardhaman", "Paschim Medinipur", "Purba Bardhaman", "Purba Medinipur", "Purulia", "South 24 Parganas", "Uttar Dinajpur"],
};

export const FEATURE_FLAGS = {
    PARENT_VENDORS : "PARENT_VENDORS"
}

export const HIRING_PORTAL_URL= {
    alpha:"https://hiring-alpha.ap-south-1.hvh.a2z.com",
    beta:"https://hiring-beta.ap-south-1.hvh.a2z.com",
    gamma:"https://hiring-gamma.ap-south-1.hvh.a2z.com",
    prod:"https://hiring.ap-south-1.hvh.a2z.com",
}

export const VENDOR_STATUS = {
    ACTIVE: "ACTIVE",
    OPEN: "OPEN",
    ACCEPTED: "ACCEPTED"
}

export const MAX_LIMITS = {
    PARENT_VENDOR : {
        NAME: 50,
        ID: 10
    }
}

export const COUNTRY_CODE_MAPPING = {
    "India" : "IN",
    "United Arab Emirates" : "AE",
    "Saudi Arabia" : "SA",
    "Egypt" : "EG"
}

export const COUNTRY_PHONE_CODE_MAPPING = {
    "India" : "+91",
    "United Arab Emirates" : "+971",
    "Saudi Arabia" : "+966",
    "Egypt" : "+20"
}

export const CREATE_ORGANIZATION_PLACEHOLDERS = {
    "United Arab Emirates" : {
        state: 'Province/Governorate',
        district : 'e.g., Abu Dhabi Intl Airport',
        OrganizationCity : 'e.g., Abu Dhabi',
        OrganizationStreet : 'e.g., Oman Ibn Al Khattab Street',
        OrganizationPostalcode : 'e.g., 560100',
        OrganizationPhone : 'e.g. xxx  xxx  xxx',
    },
    "Saudi Arabia" : {
        state: 'e.g., Asir',
        district : 'e.g., Al Andalus',
        OrganizationCity : 'e.g., Riyadh',
        OrganizationStreet : 'e.g., Al Oruba Street',
        OrganizationPostalcode : 'e.g., 11564',
        OrganizationPhone : 'e.g. xxx  xxx  xxx'
    },
    "Egypt" : {
        state: 'e.g., Al-Sharqia',
        district : 'e.g., 10th District',
        OrganizationCity : 'e.g., New Cairo City',
        OrganizationStreet : 'e.g., Talaat Harb Street',
        OrganizationPostalcode : 'e.g., 11828',
        OrganizationPhone : 'e.g. xxx  xxx  xxxx'
    },
    "India" : {
        OrganizationCity : 'e.g., Bangalore',
        OrganizationStreet : 'Street',
        OrganizationPostalcode : 'e.g., 560016',
        OrganizationPhone : 'e.g. xxx  xxx  xxxx',
    }
};

export const COUNTRY = {
    United_Arab_Emirates : 'United Arab Emirates',
    Saudi_Arabia : 'Saudi Arabia',
    India : 'India',
    Egypt : 'Egypt'
}

export const LOCATION_FIELD_ATTRIBUTES = {
    'India' : {
        'State' : 'State',
        'District' : 'District',
        'Street' : 'Street'
    },
    'Egypt' : {
        'State' : 'Province/Governorate',
        'District' : 'Area/District',
        'Street' : 'Address'
    },
    'Saudi Arabia' : {
        'State' : 'Province/Governorate',
        'District' : 'Area/District',
        'Street' : 'Address'
    },
    'United Arab Emirates' : {
        'State' : 'Province/Governorate',
        'District' : 'Area/District',
        'Street' : 'Address'
    },

}

export const PHONE_NUMBER_LENGTH_MAPPING = {
    "India" : "10",
    "United Arab Emirates" : "9",
    "Saudi Arabia" : "9",
    "Egypt" : "10"
}

export const CLRC_PLACEHOLDERS = {
    "Validity" : "2099-01-01",
    "Capacity" : "1000000"
}

export const FILE_CONSTANTS = {
    FILE: 'file',
    ID_TOKEN: 'idToken',
    ACCESS_TOKEN: 'accessToken',
    BUCKET_NAME: 'bucketName',
    FILE_NAME: 'fileName',
    CONTENT_TYPE: 'contentType',
    BRANDING: '/branding',
    FAVICON: '/favicon',
    BRANDING_FILENAME: '/branding.svg',
    FAVICON_FILENAME: '/favicon.svg',
    ATTRIBUTES:{
        BRANDING:'branding',
        FAVICON:'favicon',
    }
}

export const FORM_DATA_ATTRIBUTES = {
    VENDOR_NAME : 'vendorName',
    VENDOR_ID : 'vendorId',
    VENDOR_TYPES : 'vendorTypes',
    SUBDOMAIN : 'subdomain',
    BRANDING : 'brandingFile',
    FAVICON : 'faviconFile'
}