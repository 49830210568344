import React, {useEffect, useState} from "react";
import { Routes, Route } from "react-router-dom";
import Header from './components/Header/Header.jsx'
import CreateOrganization from "./pages/Organizations/CreateOrganization/CreateOrganization.jsx";
import Organizations from "./pages/Organizations/Organizations.jsx";
import VendorKamRequests from "./pages/VendorKamRequest/VendorKamRequests.jsx";
import ViewKamRequest from "./pages/VendorKamRequest/ViewKamRequest/ViewKamRequest";
import PageNotFound from "./pages/PageNotFound/PageNotFound.jsx";
// import Private from "./Routes/Private.jsx";
import constants, {FEATURE_FLAGS} from "./Utils/Constants.js";
import ViewVendor from "./pages/Organizations/ViewOrganization/ViewVendor.jsx";
import { View } from "@amzn/stencil-react-components/layout.js";
import { useNavigate } from "react-router-dom";
import { UserServices } from "./Services/users";
import { init } from "./api/axios";
import ParentVendors from "./pages/ParentVendors/ParentVendors";
import CreateParentVendor from "./pages/ParentVendors/CreateParentVendor";
import ViewParentVendor from "./pages/ParentVendors/ViewParentVendor";
import {OrganizationServices} from "./Services/organizations";
import {parentVendorFlag} from "./Utils/Util";
import FullPageCenteredSpinner from "./pages/common/FullPageCenteredSpinner";
import {MasterServices} from "./Services/master";
import {withAlertSnackBar} from "./HOComponents/AlertSnackBarHOC";

function LandingPage() {

  const naviagte = useNavigate();

  useEffect(() => {
    if(parentVendorFlag()) {
        naviagte(`/${constants.PARENTVENDORS.ROUTE.MAIN}`);
    }    else
      naviagte(`/${constants.ORGANIZATIONS.ROUTE.MAIN}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return null;
}

function App(props) {

    const [ isAxiosReady, setIsAxiosReady] = useState(false);

  useEffect(() => {
          getUserDetails();
      // eslint-disable-next-line
  }, []);

  const getUserDetails = async () => {
    if (init) await init();
    setIsAxiosReady(true);
    await UserServices.getUser().then(async (data) => {
      const userObject = { id: data["data"]["login"], name: data["data"]["login"] };
      console.log("Getting user information from Midway");
      sessionStorage.setItem("session_user", JSON.stringify(userObject));
      window.user = userObject;
      await fetchIsAdmin();
      await setFeatureFlag();
    }).catch((ex) => {
      console.log("Error fetching user", ex);
    });

    const sessionUser = sessionStorage.getItem('session_user');
    if (sessionUser) {
      reportUsername(JSON.parse(sessionUser).name);
    }
  }

    async function fetchIsAdmin(){
      return new Promise(async (resolve)=>{
          const sessionUser = sessionStorage.getItem('session_user');
          if(sessionUser){
              try{
                  const user = JSON.parse(sessionUser).id;
                  let admins = await MasterServices.getAdminUsers({userAlias: user});
                  localStorage.setItem('isAdmin', JSON.stringify(admins.data.authorizedUsers.includes(user)));
              }catch (e) {
                  props.snackbarShowMessage(constants.ERROR.CONFIG_LOADING,"error");
              }
          }
          resolve();
      })
    }
    async function setFeatureFlag(){
      return new Promise(async (getFeatureFlags)=>{
          try{
              let config = await OrganizationServices.getConfigForVendor();
              let parentVendorFeatureFlag = config.data.FEATURE_FLAGS[FEATURE_FLAGS.PARENT_VENDORS] === "TRUE";
              localStorage.setItem(FEATURE_FLAGS.PARENT_VENDORS, parentVendorFeatureFlag);
          }catch (ex){
              localStorage.setItem(FEATURE_FLAGS.PARENT_VENDORS,'false');
              props.snackbarShowMessage(constants.ERROR.CONFIG_LOADING,"error");
          }
        getFeatureFlags();
      })
  }

    if(!isAxiosReady){
        return <><FullPageCenteredSpinner/></>
    }

  return (
      <>
        <View className="App">
          <Header />
        </View>
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path={`/${constants.ORGANIZATIONS.ROUTE.MAIN}`} exact element={<Organizations />} />
          <Route path={`/${constants.ORGANIZATIONS.ROUTE.VIEW}/:id`} exact element={<ViewVendor />} />
          <Route path={`/${constants.ORGANIZATIONS.ROUTE.CREATE}`} exact element={<CreateOrganization />} />
          <Route path={`/${constants.ORGANIZATIONS.ROUTE.CREATE_RELATED}/:relatedName/:relatedID`} exact element={<CreateOrganization />} />
          <Route path={`/${constants.ORGANIZATIONS.ROUTE.EDIT}/:id`} exact element={<CreateOrganization />} />
          <Route path={`/${constants.VENDORKAMREQUESTS.ROUTE.MAIN}`} exact element={<VendorKamRequests />} />
          <Route path={`/${constants.VENDORKAMREQUESTS.ROUTE.VIEW}/:vendorKamId/:kamRequestId`} exact element={<ViewKamRequest />} />
          {parentVendorFlag() && <Route path={`/${constants.PARENTVENDORS.ROUTE.MAIN}`} exact element={<ParentVendors/>}/> }
          {parentVendorFlag() && <Route path={`/${constants.PARENTVENDORS.ROUTE.VIEW}/:vendorId`} exact element={<ViewParentVendor/>}/>}
          {parentVendorFlag() && <Route path={`/${constants.PARENTVENDORS.ROUTE.CREATE}`} exact element={<CreateParentVendor/>}/>}
          {parentVendorFlag() && <Route path={`/${constants.PARENTVENDORS.ROUTE.EDIT}/:vendorId`} exact element={<CreateParentVendor/>}/>}
          <Route path="*" exact element={<PageNotFound />} />
        </Routes>
        </>);
}

/* <Switch>
          <Private path="/" exact component={Organizations} />
          <Private path="/vendors" exact component={Organizations} />
          <Private path="/requests" exact component={VendorKamRequests} /> */
/* <Route path="/home" exact component={Home} /> */
/* ACTIVITIES module START */
/*<Private path={`/${constants.ACTIVITIES.ROUTE.MAIN}`} exact component={Activities} />*/
/*<Private path={`/${constants.ACTIVITIES.ROUTE.VIEW}/:id`} exact component={ViewActivity} />*/
/*<Private path={`/${constants.ACTIVITIES.ROUTE.CREATE}`} exact component={CreateActivity} />*/
/*<Private path={`/${constants.ACTIVITIES.ROUTE.CREATE_RELATED}/:relatedName/:relatedID`} exact component={CreateActivity} />*/
/*<Private path={`/${constants.ACTIVITIES.ROUTE.EDIT}/:id`} exact component={CreateActivity} />*/
/*/!* TASKS module START *!/*/
/*<Private path={`/${constants.TASKS.ROUTE.MAIN}`} exact component={Tasks} />*/
/*<Private path={`/${constants.TASKS.ROUTE.VIEW}/:id`} exact component={ViewTask} />*/
/*<Private path={`/${constants.TASKS.ROUTE.CREATE}`} exact component={CreateTask} />*/
/*<Private path={`/${constants.TASKS.ROUTE.CREATE_RELATED}/:relatedName/:relatedID`} exact component={CreateTask} />*/
/*<Private path={`/${constants.TASKS.ROUTE.EDIT}/:id`} exact component={CreateTask} />*/
/* CONTACTS module START */
/* <Private path={`/${constants.CONTACTS.ROUTE.MAIN}`} exact component={Contacts} />
          <Private path={`/${constants.CONTACTS.ROUTE.VIEW}/:id`} exact component={ViewContact} />
          <Private path={`/${constants.CONTACTS.ROUTE.CREATE}`} exact component={CreateContact} />
          <Private path={`/${constants.CONTACTS.ROUTE.CREATE_RELATED}/:relatedName/:relatedID`} exact component={CreateContact} />
          <Private path={`/${constants.CONTACTS.ROUTE.EDIT}/:id`} exact component={CreateContact} /> */
/* ORGANIZATIONS module START */
/* <Private path={`/${constants.ORGANIZATIONS.ROUTE.MAIN}`} exact component={Organizations} />
          <Private path={`/${constants.ORGANIZATIONS.ROUTE.VIEW}/:id`} exact component={ViewOrganization} />
          <Private path={`/${constants.ORGANIZATIONS.ROUTE.CREATE}`} exact component={CreateOrganization} />
          <Private path={`/${constants.ORGANIZATIONS.ROUTE.CREATE_RELATED}/:relatedName/:relatedID`} exact component={CreateOrganization} />
          <Private path={`/${constants.ORGANIZATIONS.ROUTE.EDIT}/:id`} exact component={CreateOrganization} />
          <Private path={`/${constants.VENDORKAMREQUESTS.ROUTE.MAIN}`} exact component={VendorKamRequests} />
          <Private path={`/${constants.VENDORKAMREQUESTS.ROUTE.VIEW}/:vendorKamId/:kamRequestId`} exact component={ViewKamRequest} /> */
/* <Private path="/candidates" exact component={Candidates} />*/
/* <Private path="/calendar" exact component={Calendar} />
          <Private path="/reports" exact component={Reports} /> */
/* <Private path="/admin" exact component={Admin} />
          <Route path="*" exact component={PageNotFound} /> */





function reportUsername(username) {
  if (username !== '') {
    const authenticatedUserEvent = new CustomEvent('cathode-customerId', {
      detail: {
        customerId: username
      }
    });
    document.dispatchEvent(authenticatedUserEvent);
  }
};

export default withAlertSnackBar(App);
