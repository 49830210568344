import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import constants from "../../Utils/Constants";
import { customSortVendorKAM } from "../../Utils/customSort";
import UnauthorizedAccessDialog from "../PageNotFound/UnauthorizedAccessDialog";
import { Col, View } from "@amzn/stencil-react-components/layout";
import { Table } from '@amzn/stencil-react-components/table';
import { Pagination } from '@amzn/stencil-react-components/pagination';
import { Label } from '@amzn/stencil-react-components/text';
import { Select } from '@amzn/stencil-react-components/form';
import { useSort, SortDirection } from '@amzn/stencil-react-components/table';
import { defaultCardRenderer } from '@amzn/stencil-react-components/table';
import FullPageCenteredSpinner from "../common/FullPageCenteredSpinner";
import {withAlertSnackBar} from "../../HOComponents/AlertSnackBarHOC";
import {getCardColor} from "../../Utils/Util";

const tableColumns = [
    {
        header: 'Name',
        sortId: 'name',
        accessor: 'name',
        width: '40%'
    },
    {
        accessor: 'vendorId',
        header: 'VendorId',
        sortId: 'vendorId',
        width: '20%'
    },
    {
        accessor: 'type',
        header: 'Types',
        sortId: 'type',
        width: '20%'
    },
    {
        accessor: "status",
        header: "Status",
        sortId: "status",
        width: '20%'
    }
];

function ParentVendorGrid(props, ref) {
    const [rowData, setRowData] = React.useState([]);
    const [globalRows, setGlobalRows] = React.useState([]);
    const [paginationConfig, setPaginationConfig] = React.useState(props.config);
    const [showUnauthorizedDialog, setShowUnauthorizedDialog] = React.useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (!props.vendorLoading && props.queryVendors)
            getData();
        else if (!props.vendorLoading && props.errorVendors)
                setShowUnauthorizedDialog(true);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.queryVendors, props.vendorLoading]);

    useEffect(()=>{
        setPaginationConfig(props.config);
    },[props.config]);

    useEffect(() => {
        operateOnRows(props.filtersConfig.filters, props.searchConfig.searchValue)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.searchConfig.searchValue])

    useEffect(() => {
        operateOnRows(props.filtersConfig.filters, props.searchConfig.searchValue)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(props.filtersConfig)]);

    const { activeSortId, sortDirection, onSort } = useSort({
        defaultSortDirection: SortDirection.Ascending,
        defaultActiveSortId: 'name'
    });

    const getData = async () => {
        let rows = !props.vendorLoading && props.queryVendors && props.queryVendors.data && props.queryVendors.data.parentVendors;
        let rowsData = [];
        if (rows) {
            rows.forEach((row) => {
                rowsData.push({
                    name: row.vendorName,
                    type: row.vendorTypes.join(", "),
                    status: row.status,
                    vendorId: row.vendorId,
                });
            });
        }
        setRowData(rowsData);
        setGlobalRows(rowsData);
    };

    const operateSearch = (vendors, searchValue) => {
        if (searchValue) {
            if (searchValue.length > 0) {
                return vendors.filter(arr => arr.name.toLowerCase().includes(searchValue.toLowerCase()));
            }
        }
        return vendors
    }

    const operateOnRows = (filters, searchValue) => {
        let rowsData = [...customSortVendorKAM(globalRows)];
        if (filters?.types?.length) {
            rowsData = rowsData.filter((item) => filters.types.some(type => item.type.includes(type)));
        }
        if (filters?.vendorStatus?.length) {
            rowsData = rowsData.filter((item) => filters.vendorStatus.includes(item.status));
        }
        rowsData = operateSearch(rowsData, searchValue);
        setRowData(rowsData);
    }

    const handlePage = (page) => {
        let paginationConfigL = { ...paginationConfig };
        paginationConfigL.pageNo = page;
        setPaginationConfig(paginationConfigL);
    };

    const handlePageSize = (pageSize) => {
        let paginationConfigL = { ...paginationConfig };
        paginationConfigL.pageNo = 1;
        paginationConfigL.pageSize = pageSize;
        setPaginationConfig(paginationConfigL);
    };

    const getRowAttributes = ({ data, index }) => ({
        onClick: ({ data, index }) => { navigate(`/${constants.PARENTVENDORS.ROUTE.VIEW}/${data.vendorId}`); },
        css:{
            backgroundColor:getCardColor(data.status)
        },
        "data-testid": 'item' + index
    });

    const compare = (a, b) => {
        // This requires the sort IDs to be the same as the property
        // names in the data object. If they aren't, some additional
        // logic would be required to extract the data for the column.
        const dataA = a[activeSortId].toLowerCase();
        const dataB = b[activeSortId].toLowerCase();
        return dataA < dataB ? -1 : 1;
    };

    const sortedData =
        sortDirection === SortDirection.Descending
            ? rowData.sort(compare).reverse()
            : rowData.sort(compare);

    const renderCardForRow = defaultCardRenderer({ columns: tableColumns });

    return (
        <Col gridGap="S200" style={{ paddingLeft: '1em', paddingRight: '1em'}}>
            {props.vendorLoading && <FullPageCenteredSpinner />}
            {!props.vendorLoading && <Table
                aria-labelledby="table-label"
                columns={tableColumns}
                data={sortedData.slice((paginationConfig.pageNo - 1) * paginationConfig.pageSize, paginationConfig.pageNo * paginationConfig.pageSize)}
                onSort={onSort}
                activeSortId={activeSortId}
                sortDirection={sortDirection}
                renderCardForRow={renderCardForRow}
                maxHeight={450}
                hasStickyHeader={true}
                getRowAttributes={getRowAttributes}
            />}
            <Col gridGap="S200" margin={{ top: 'S200' }}>
                <View style={{ display: 'flex' }}>
                    <View flex={3}>
                        <Pagination
                            aria-labelledby="table-pagination"
                            numberOfPages={Math.ceil(rowData.length / paginationConfig.pageSize)}
                            onPageSelect={page => {
                                handlePage(page);
                            }}
                            selectedPage={paginationConfig.pageNo}
                        />
                    </View>
                    <View flex={1} display="flex" alignItems='center'
                          justifyContent="center">
                        <View flex={2} style={{ textAlign: 'right', paddingRight: '10px' }}>
                            <Label htmlFor="rows-per-page" id="rows-per-page-label">
                                Rows per page
                            </Label>
                        </View>
                        <View style={{ paddingRight: '10px' }}>
                            <Select
                                options={[10, 25, 50, 75, 100]}
                                id="rows-per-page"
                                labelId="rows-per-page-label"
                                onChange={handlePageSize}
                                value={paginationConfig.pageSize}
                            />
                        </View>
                    </View>
                </View>
            </Col>
            {showUnauthorizedDialog && (
                <UnauthorizedAccessDialog
                    handleCancel={() => {
                        setShowUnauthorizedDialog(false);
                    }}
                    snackbarShowMessage={props.snackbarShowMessage}
                />
            )}
        </Col>
    )
}

export default withAlertSnackBar(ParentVendorGrid);
