import {compareVendor} from "./Comparator";

export function paginate(array, page_size, page_number) {
    return array.slice((page_number - 1) * page_size, page_number * page_size);
}

export function searchVendors(vendors,search){
    if(search) {
        let arr = vendors.filter(arr => arr.name.toLowerCase().includes(search.toLowerCase()))
        return arr
    }
    return vendors
}


export function filterVendors(vendors,type,sites){
    if(type) {
        vendors = vendors.filter(arr => arr.type === type)
    }
    if(sites.length!==0) {
        vendors =  vendors.filter(function (vendor) {
            return vendor.sites.some(site => {
                return sites.indexOf(site) >= 0
            })
        })
    }
    return vendors
}

export function sortVendor(rows,sortColumn,order){
    return rows.sort(compareVendor(sortColumn,order))
}
