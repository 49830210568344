import React, { useEffect } from 'react'
import constants from "../../Utils/Constants"
import ParentVendorsFilterDialog from "./ParentVendorsFilterDialog";
import { useNavigate } from 'react-router-dom';
import { Col, Container, Row, Spacer, View } from "@amzn/stencil-react-components/layout";
import { useBreakpoints } from '@amzn/stencil-react-components/responsive';
import { Text } from '@amzn/stencil-react-components/dist/submodules/text';
import { FlyoutContent, FlyoutPosition, WithFlyout } from '@amzn/stencil-react-components/flyout';
import { Button, ButtonIconPosition, ButtonSize, ButtonVariant } from '@amzn/stencil-react-components/button';
import { IconFilter, IconPlus, IconSize } from '@amzn/stencil-react-components/icons';
import { InputWrapper } from '@amzn/stencil-react-components/form';
import { SearchField } from "@amzn/stencil-react-components/search";
import ParentVendorGrid from "./ParentVendorsGrid";
import { useGetAllParentVendors } from "../../ReactQuery/hooks/useGetAllParentVendors";
import { withAlertSnackBar } from "../../HOComponents/AlertSnackBarHOC";

function ParentVendors() {

    const [paginationConfig, setPaginationConfig] = React.useState({ pageNo: 1, pageSize: 25 });
    const [searchConfig, setSearchConfig] = React.useState({ searchValue: '' });
    const [filtersConfig, setFiltersConfig] = React.useState({ filtersApplied: false, filters: { resetAppliedFilter: false, types: [], sites: [], vendorStatus: [] } });
    const { data: queryVendors, error: errorVendors, isFetching: vendorLoading } = useGetAllParentVendors();


    const navigate = useNavigate();

    const { matches: deviceResolution } = useBreakpoints();

    useEffect(() => {
        setSearchConfig({ searchValue: '' });
    }, [])

    const handleSearchValueChange = (e) => {
        let searchConfigL = { ...searchConfig };
        searchConfigL.searchValue = e;
        let paginationConfigL = { ...paginationConfig };
        paginationConfigL.searchValue = e;
        paginationConfigL.searchColumn = searchConfig.searchColumn ? 'name' : '';
        paginationConfigL.searchByID = searchConfig.searchByID;
        paginationConfigL.pageNo = 1;
        if (e.keyCode === 13) {
            e.preventDefault();
        } else {
            setSearchConfig(searchConfigL);
            setPaginationConfig(paginationConfigL);
        }
    }

    const handleSearchClick = () => {
        let paginationConfigL = { ...paginationConfig };
        paginationConfigL.pageNo = 1;
        paginationConfigL.searchValue = searchConfig.searchValue;
        paginationConfigL.searchByID = searchConfig.searchByID;
        paginationConfigL.searchColumn = searchConfig.searchColumn ? 'name' : '';
        setPaginationConfig(paginationConfigL);
    }


    const createVendor = () => {
        navigate(`${constants.PARENTVENDORS.ROUTE.CREATE_PATH}`);
    }

    const updateFilters = (filters) => {
        setPaginationConfig({ pageNo: 1, pageSize: 25 });
        setFiltersConfig(filters);
    }

    const vendorFilter = (mode) => {
        return <ParentVendorsFilterDialog
            mode={mode}
            onChange={updateFilters}
        />
    }




    const vendorSearch = (mode, flyoutCloseCallBack) => {
        return (
            <InputWrapper labelText="" dataTestId="txtSearchValue" id="Vendor-Site-box">
                {inputProps => (
                    <SearchField
                        placeholder="Search"
                        width={"100%"}
                        shouldShowListOnNoResults={false}
                        query={searchConfig.searchValue ? searchConfig.searchValue : ""}
                        onChange={handleSearchValueChange}
                        id='txtSearch'
                        dataTestId="txtSearch"
                        onKeyPress={event => {
                            if (event.key === 'Enter' && mode === constants.DEVICE.MOBILE) {
                                if (flyoutCloseCallBack) flyoutCloseCallBack()
                                handleSearchClick();
                            }
                        }}
                    />)
                }
            </InputWrapper>
        )
    }

    const filtersMobile = ({ close }) => (
        <FlyoutContent
            titleText="Filter"
            maxWidth={'100vw'}
            style={{ height: '100% !important' }}
            onCloseButtonClick={() => close()}
            buttons={[
                <Button
                    variant={ButtonVariant.Secondary}
                    size={ButtonSize.Small}
                    onClick={() => { close() }}
                >
                    Reset
                </Button>,
                <Button
                    variant={ButtonVariant.Primary}
                    size={ButtonSize.Small}
                    onClick={() => { close() }}
                >
                    Apply
                </Button>
            ]}
            id="filtersModal"
        >
            <Col>
                <Spacer width={'93vw'}></Spacer>
                {vendorFilter(constants.DEVICE.MOBILE)}
            </Col>
        </FlyoutContent>
    );

    return (
        <Container height="100%" isFullWidth={true}>
            <Row>
                {(deviceResolution.l || deviceResolution.xl) &&
                    <View width="18%" style={{ position: 'fixed', left: 0, overflowY: 'auto', height: 'calc(100vh - 56px)', borderRight: '1px solid rgb(86,97,108)' }}>
                        {vendorFilter(constants.DEVICE.DESKTOP)}
                    </View>
                }
                <View width={(deviceResolution.l || deviceResolution.xl) ? '82%' : '100%'} style={(deviceResolution.l || deviceResolution.xl) ? { marginLeft: '18%' } : {}}>
                    <Col gridGap={`S300`} className={(deviceResolution.m || deviceResolution.s) ? `` : ''} padding={'1em'}>
                        <Row justifyContent="space-between">
                            <View>
                                <Text fontSize="T500" fontWeight="bold">{constants.PARENTVENDORS.TITLE}</Text>
                            </View>
                            <Row gridGap="S300">
                                {(deviceResolution.m || deviceResolution.s) &&
                                    <>
                                        <WithFlyout renderFlyout={filtersMobile} isScrollable={false} flyoutPosition={FlyoutPosition.Leading} aria-labelledby="custom-flyout-title">
                                            {({ open }) => (
                                                <>
                                                    <Button
                                                        aria-describedby="sroDialog"
                                                        onClick={open}
                                                        variant={ButtonVariant.Tertiary}
                                                        size={ButtonSize.Small}
                                                        icon={<IconFilter aria-hidden={true} color="primary70" size={IconSize.Small} />}
                                                        iconPosition={ButtonIconPosition.Trailing}
                                                        id="btnOpenMobileFilters"
                                                        dataTestId="btnOpenMobileFilters"
                                                    >
                                                    </Button>
                                                    <Button icon={<IconPlus />}
                                                        size={ButtonSize.Small}
                                                        variant={ButtonVariant.Primary}
                                                        onClick={createVendor}
                                                        id="create-request-button-mobile">Request</Button>

                                                </>
                                            )}
                                        </WithFlyout>
                                    </>
                                }
                                {(deviceResolution.l || deviceResolution.xl) &&
                                    <>{vendorSearch(constants.DEVICE.DESKTOP)}
                                        <Button icon={<IconPlus />} variant={ButtonVariant.Primary} size={ButtonSize.Default} onClick={createVendor}>{constants.BUTTON.CREATE}</Button></>
                                }
                            </Row>
                        </Row>
                        {(deviceResolution.m || deviceResolution.s) &&
                            <View>{vendorSearch(constants.DEVICE.MOBILE)}</View>
                        }
                    </Col>
                    <Col>
                        <ParentVendorGrid queryVendors={queryVendors} errorVendors={errorVendors} vendorLoading={vendorLoading} View='page' config={paginationConfig} filtersConfig={filtersConfig} searchConfig={searchConfig} />
                    </Col>
                    <Spacer height={"5%"} width={"100%"}></Spacer>
                </View>
            </Row>
        </Container>
    );
}

export default withAlertSnackBar(ParentVendors)
