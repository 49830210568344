import {Col, Row} from "@amzn/stencil-react-components/layout";
import {Label} from "@amzn/stencil-react-components/text";
import {Input, InputFooter, Select} from "@amzn/stencil-react-components/form";
import {
    AP_STATE_DISTRICT_MAPPING,
    AP_STATES, CREATE_ORGANIZATION_PLACEHOLDERS, PHONE_NUMBER_LENGTH_MAPPING,
} from "../../../../Utils/Constants";
import {validatePatterns} from "../../../../Utils/Validator";
import React from "react";
import {handleEmpty} from "../../../../Utils/Util";
import constants from "../../../../Utils/Constants";


export function IndiaAddressTab(props) {
    const placeholder = CREATE_ORGANIZATION_PLACEHOLDERS[props.formData.country];
    return(
        <Col gridGap={"S300"}>
            <Row justifyContent={'space-between'} flexWrap={'wrap'} gridGap={'S300'}>
                <Col gridGap='S100' {...props.inputFieldBreakPoints}>
                    <Label id='state'>State : *</Label>
                    <Col>
                        <Select
                            labelId="state"
                            id="stateSelect"
                            dataTestId="stateSelect"
                            options={AP_STATES}
                            value={handleEmpty(props.formData.state)}
                            onChange={(value)=>props.updateFormData('state',value)}
                        />
                        {props.isFormSubmitted && !props.formData.state &&
                            <InputFooter error>State is missing</InputFooter>
                        }
                    </Col>
                </Col>
                <Col gridGap='S100' {...props.inputFieldBreakPoints}>
                    <Label id='district'>District : *</Label>
                    <Col>
                        <Select
                            labelId='district'
                            id="districtSelect"
                            dataTestId="districtSelect"
                            options={props.formData.state ? AP_STATE_DISTRICT_MAPPING[props.formData.state] : []}
                            value={handleEmpty(props.formData.district)}
                            onChange={(value)=>props.updateFormData('district',value)}/>
                        {props.isFormSubmitted &&
                            (props.formData.state && !props.formData.district) && (
                                <InputFooter error> District is missing </InputFooter>
                            )}
                    </Col>
                </Col>
            </Row>
            <Row justifyContent='space-between' flexWrap='wrap' gridGap='S300'>
                <Col gridGap='S100' {...props.inputFieldBreakPoints}>
                    <Label id='OrganizationCity'>City : *</Label>
                    <Col>
                        <Input id="OrganizationCity" placeholder='e.g., Bangalore' dataTestId="OrganizationCity"
                               name="OrganizationCity" value={handleEmpty(props.formData.OrganizationCity)} onChange={props.handleText}/>
                        {props.isFormSubmitted && !props.formData.OrganizationCity &&
                            <InputFooter error>City is missing</InputFooter>
                        }
                        {props.isFormSubmitted && props.formData.OrganizationCity && !validatePatterns(constants.PATTERN.ADDRESS_PATTERNS[props.formData.country],props.formData.OrganizationCity) &&
                            <InputFooter error>Invalid city format</InputFooter>
                        }
                    </Col>
                </Col>
                <Col gridGap='S100' {...props.inputFieldBreakPoints}>
                    <Label id='OrganizationStreet'>Street: *</Label>
                    <Col>
                        <Input id="OrganizationStreet" dataTestId="OrganizationStreet" placeholder={placeholder['OrganizationStreet']}
                               name="OrganizationStreet" value={handleEmpty(props.formData.OrganizationStreet)} onChange={props.handleText}/>
                        {props.isFormSubmitted && !props.formData.OrganizationStreet &&
                            <InputFooter error>Street is missing</InputFooter>
                        }
                    </Col>
                </Col>
            </Row>
            <Row justifyContent='space-between' flexWrap='wrap' gridGap='S300'>
                <Col gridGap='S100' {...props.inputFieldBreakPoints}>
                    <Label id='pin-code'>PIN code : *</Label>
                    <Col>
                        <Input id="pin-code-input" placeholder={placeholder['OrganizationPostalcode']}
                                     dataTestId="OrganizationPostalcode" name="OrganizationPostalcode" maxLength={6}
                                     value={handleEmpty(props.formData.OrganizationPostalcode)}
                                     onChange={props.handleNumberOnlyInput} />
                        {props.isFormSubmitted && !props.formData.OrganizationPostalcode &&
                            <InputFooter error>PIN code is missing</InputFooter>
                        }
                        {props.isFormSubmitted && props.formData.OrganizationPostalcode && !validatePatterns(constants.PATTERN.POSTAL_CODES[props.formData.country],props.formData.OrganizationPostalcode) &&
                            <InputFooter error>Invalid PIN code format</InputFooter>
                        }
                    </Col>
                </Col>
                <Col gridGap='S100' {...props.inputFieldBreakPoints}>
                    <Label id='OrganizationPhone'>Phone : *</Label>
                    <Col>
                        <Row gridGap={"S300"}>
                            <Input value = {"+91"} readOnly={true} width ={"5rem"} />
                            <Input id="OrganizationPhone" placeholder={placeholder['OrganizationPhone']} dataTestId="OrganizationPhone"
                                         maxLength={PHONE_NUMBER_LENGTH_MAPPING[props.formData.country]}
                                         name="OrganizationPhone" value={handleEmpty(props.formData.OrganizationPhone)} width={"100%"}
                                         onChange={props.handleNumberOnlyInput} />
                        </Row>
                        {props.isFormSubmitted && !props.formData.OrganizationPhone &&
                            <InputFooter error>Phone number is missing</InputFooter>
                        }
                        {props.isFormSubmitted && props.formData.OrganizationPhone && !validatePatterns(constants.PATTERN.PHONE_NUMBERS[props.formData.country],props.formData.OrganizationPhone) &&
                            <InputFooter error>Invalid Phone number format</InputFooter>
                        }
                    </Col>
                </Col>
            </Row>
        </Col>
    );
}